.our-sectors {
  padding: ac(96px, var(--mmt)) 0 ac(59px, var(--mmt));
  background-color: var(--Lime-III);
  overflow: hidden;

  &__title {
    color: var(--Gray-I);
    font-size: ac(28px, 24px);
    font-weight: 700;
    line-height: ac(36px, 30px);
    letter-spacing: -0.07px;
  }

  &__wrapper {
    gap: ac(43px, 20px);
  }

  &__thumb {
    margin-top: ac(46px, 20px);

    .swiper-slide {
      cursor: pointer;

      &-thumb-active {
        cursor: default;

        .our-sectors__tab {
          color: var(--black);

          transform: translateX(0);
        }

        .our-sectors__icon {
          margin-left: 0px;
          opacity: 1;
        }
      }
    }
  }

  &__tab {
    height: 100%;
    transform: translateX(-32px);

    color: var(--Gray-I);
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    transition: 0.3s ease;

    &:hover {
      color: var(--black);
    }
  }

  &__icon {
    opacity: 0;
    flex-shrink: 0;
    margin-right: 8px;
    width: 24px;
    height: 24px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23C3D600' d='m6.86 8.423 10.28 6.659V8.658L6.86 2v6.423ZM6.86 15.63V22l10.075-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E")
      center / contain no-repeat;
    transition: 0.3s ease;
  }

  &__slider-box {
    display: flex;
    padding-top: ac(25px, 0px);
  }

  &__slider {
    display: grid;

    .swiper-slide {
      opacity: 0 !important;

      &-active {
        opacity: 1 !important;
      }
    }
  }

  &__card {
    gap: ac(40px, 20px);
    overflow: hidden;
  }

  &__name {
    color: var(--forest);
    font-size: ac(36px, 24px);
    font-weight: 700;
    line-height: ac(48px, 30px);
    letter-spacing: -0.09px;
  }

  &__scroll {
    margin-top: ac(31px, 15px);
    width: calc(100% + 10px);
    padding-right: 10px;
    max-height: calc(ac(28px, 20px) * 8);

    .simplebar-scrollbar::before {
      background-color: var(--forest);
    }

    .simplebar-track {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__descr {
    font-size: ac(18px, 16px);
    font-weight: 400;
    line-height: ac(28px, 20px);
    padding-bottom: 2px;
  }

  &__link {
    max-width: 134px;
    padding-bottom: ac(12px, 7px);
    margin-top: ac(40px, 20px);
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
    padding-right: 35px;
    color: var(--black);
    transition: 0.3s ease;
    cursor: pointer;

    &::after,
    &::before {
      content: '';
      position: absolute;
      transition: 0.3s ease;
    }

    &::after {
      right: ac(9px, 11px);
      width: 10px;
      height: 14px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='14' fill='none'%3E%3Cpath fill='%23000' d='m2.317 14 7-7-7-7L.684 1.633 6.05 7 .684 12.367 2.317 14Z'/%3E%3C/svg%3E")
        center / contain no-repeat;
      transition: 0.3s ease;
    }

    &::before {
      right: 0;

      bottom: 0;
      width: 100%;
      max-width: 32px;
      height: 1px;
      background-color: var(--forest);
    }

    &:hover {
      max-width: 100%;
      padding-left: 12px;
      color: var(--forest);

      &::before {
        max-width: 100%;
      }
    }
  }

  &__img {
    width: 100%;
  }

  @mixin lg {
    &__slider-box {
      max-width: ac(831px, 646px, 1024);
    }

    &__col {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -2px;
        width: 5px;
        height: 136px;
        background-color: #2b7051;
        z-index: 2;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 1px;
        height: calc(100% + 200px);
        background-color: var(--Gray-III);
        z-index: 2;
      }
    }

    &__scrollbar {
      display: none;
    }

    &__col {
      max-width: ac(350px, 300px, 1024);
      flex-shrink: 0;
    }

    &__thumb {
      .swiper-wrapper {
        align-items: flex-start;
      }

      .swiper-slide {
        width: auto;
        &:not(:last-child) {
          .our-sectors__tab {
            border-bottom: 0;
          }
        }
      }
    }

    &__text-box {
      max-width: ac(451px, 300px);
    }
  }

  &__accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @mixin lg {
      display: none;
    }

    .our-sectors__slide {
      width: 100%;
      max-width: 100%;
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      transition: all 0.4s ease;
      padding-bottom: 0;

      &.active {
        opacity: 1;
        max-height: 800px;
        padding-bottom: 20px !important;
      }
    }

    .our-sectors__card {
      max-width: 100%;
    }
  }

  &__accordion-toggle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--forest);
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    /*&:not(:first-child) {
      margin-top: 10px;
    }*/

    .our-sectors__name {
      max-width: calc(100% - 40px);
      font-size: 22px;
      line-height: normal;
    }

    .our-sectors__icon {
      opacity: 1 !important;
      margin: 0;
      transform: rotate(90deg);
      transition: transform 0.3s ease;
    }

    &.active {
      .our-sectors__icon {
        transform: rotate(-90deg);
      }
    }
  }

  @mixin max-lg {
    &__wrapper {
      flex-direction: column;
    }

    &__text-box {
      width: 100%;
      max-width: ac(600px, 300px, 640, 1024);
    }

    &__scrollbar {
      margin-top: 15px;
      width: 100% !important;
      height: 8px !important;

      background: var(--Lime-II);
      border-radius: 0 !important;
      opacity: 1;

      .swiper-scrollbar-drag {
        background: var(--forest);
        border-radius: 0 !important;
        opacity: 1;
        cursor: grab;
      }
    }
  }

  @mixin max-sm {
    &__col {
      display: none;
    }

    &__slider-box {
      display: none;
    }

    &__card {
      flex-direction: column-reverse;
    }

    &__img {
      display: none;
    }

    &__text-box {
      max-width: unset;
    }
  }
}
