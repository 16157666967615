.footer {
  position: relative;
  padding-top: ac(80px, var(--mmt));
  background-color: var(--Lime-III);
  z-index: 1;

  &--article {
    overflow: hidden;
  }

  &--location {
    margin-top: ac(98px, 48px);

    .footer__decor {
      display: none;
    }
  }

  &__decor-box {
    top: ac(-308px, -105px, 640, 1440);
    width: 100%;
    height: calc(100% + ac(308px, 105px, 640, 1440));
    overflow: hidden;
    pointer-events: none;
  }

  &__decor {
    width: ac(964px, 330px, 640, 1440);
    height: ac(846px, 290px, 640, 1440);
    top: 0;
    left: 50%;
    transform: translateX(calc(ac(557px, 265px, 640, 1440) - 50%));

    svg {
      object-fit: contain;
    }
  }

  .cont {
    max-width: 1144px;
  }

  &__logo {
    @mixin aspect-ratio 205, 95;
    width: ac(220px, 150px);
    flex-shrink: 0;
    z-index: 3;

    path {
      transition: 0.3s ease;
    }

    &:hover {
      .logo-text-first {
        path {
          fill: var(--maroon);
        }
      }

      .logo-text-second {
        path {
          fill: var(--maroon);
        }
      }
    }
  }

  &__nav {
    display: grid;
    grid-template-columns: repeat(6, max-content);
    gap: 12px ac(37px, 15px);
  }

  &__link {
    display: inline-flex;
    align-items: center;
    font-size: ac(16px, 14px);
    line-height: ac(22px, 19px);
    font-weight: 700;
    transition: 0.3s ease;

    &:hover {
      color: var(--maroon);
    }
  }

  &__slider-box {
    margin-top: ac(40px, 38px);
    width: 100%;
  }

  &__slide {
    padding-top: ac(22px, 12px);
    padding-bottom: ac(46px, 12px);
    padding: ac(22px, 12px) ac(46px, 24px, 568, 1440) ac(46px, 12px) 0;

    &:not(:last-child) {
      border-right: 1px solid #dfe0e1;
    }

    & > span {
      margin-bottom: ac(18px, 10px);
      font-size: 22px;
      font-weight: 700;
      line-height: 32px;
      color: var(--Gray-I);
    }
  }

  &__contact {
    margin-top: ac(12px, 4px);
    font-weight: 400;
    font-size: ac(18px, 14px);
    line-height: ac(28px, 24px);

    &:nth-of-type(1) {
      margin-bottom: auto;
      margin-top: auto;
    }

    &::before {
      content: '';
      margin-right: 6px;
      flex-shrink: 0;
      width: 36px;
      height: 36px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--tel {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='37' fill='none'%3E%3Cpath fill='%23255F47' d='M25.022 26.59c-1.837 0-3.655-.437-5.455-1.311a17.726 17.726 0 0 1-4.822-3.434 17.726 17.726 0 0 1-3.434-4.822c-.874-1.8-1.311-3.619-1.311-5.456a.948.948 0 0 1 .978-.977h3.266c.208 0 .393.07.556.21.163.142.26.323.29.545l.6 2.911c.029.208.025.396-.012.567a.854.854 0 0 1-.234.433l-2.2 2.223a16.927 16.927 0 0 0 2.634 3.4 15.5 15.5 0 0 0 3.366 2.533l2.112-2.133c.133-.163.3-.278.5-.345.2-.067.403-.078.61-.034l2.778.6a.92.92 0 0 1 .545.323.9.9 0 0 1 .211.589v3.2a.948.948 0 0 1-.978.978Z'/%3E%3C/svg%3E");
      }
    }
    &--mail {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='37' fill='none'%3E%3Cpath fill='%23255F47' d='M9.056 13.339a2.25 2.25 0 0 1 2.194-1.75h13.5a2.25 2.25 0 0 1 2.194 1.75L18 18.805 9.056 13.34ZM9 14.624v7.992l6.528-4.003L9 14.623Zm7.606 4.65-7.391 4.53a2.25 2.25 0 0 0 2.035 1.286h13.5a2.25 2.25 0 0 0 2.034-1.287l-7.391-4.53-1.393.85-1.394-.85Zm3.866-.66L27 22.616v-7.992l-6.528 3.989v.001Z'/%3E%3C/svg%3E");
      }
    }
    &--address {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='37' fill='none'%3E%3Cpath fill='%23255F47' d='M18 18.59c.55 0 1.02-.196 1.413-.588.391-.392.587-.862.587-1.412 0-.55-.196-1.021-.587-1.413A1.926 1.926 0 0 0 18 14.59c-.55 0-1.02.195-1.413.587A1.926 1.926 0 0 0 16 16.59c0 .55.196 1.02.587 1.412.392.392.863.588 1.413.588Zm0 10c-2.683-2.284-4.688-4.405-6.012-6.363C10.663 20.27 10 18.457 10 16.79c0-2.5.804-4.492 2.412-5.975C14.021 9.33 15.883 8.59 18 8.59s3.98.741 5.587 2.225C25.197 12.298 26 14.29 26 16.79c0 1.666-.663 3.479-1.988 5.437-1.325 1.958-3.329 4.08-6.012 6.363Z'/%3E%3C/svg%3E");
      }
    }

    &:hover {
      color: var(--maroon);
    }
  }

  &__privacy-box {
    gap: 8px ac(16px, 12px);
  }

  &__bottom {
    padding: ac(26px, 25px) 0;
    gap: ac(8px, 16px) ac(16px, 12px);
    align-items: center;

    & > * {
      z-index: 1;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      height: 100%;
      background-color: var(--white);
      z-index: 0;
    }
  }

  &__privacy {
    color: var(--Gray-I);
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }

  a.footer__privacy {
    display: inline-flex;
    align-items: center;
    transition: 0.3s ease;

    &::after {
      content: '';
      margin-left: ac(16px, 12px);
      width: 7px;
      height: 9px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='9' fill='none'%3E%3Cpath fill='%23DFE0E1' d='M6.889 4.59 0 8.59v-8l6.889 4Z'/%3E%3C/svg%3E");
    }

    &:hover {
      color: var(--maroon);
    }
  }

  &__soc-box {
    gap: ac(16px, 8px);
  }

  &__soc {
    width: 44px;
    height: 45px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: 0.3s ease;

    &--x {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='45' fill='none'%3E%3Cpath fill='%23255F47' d='M0 .59h44v44H0z'/%3E%3Cpath fill='%23fff' d='m19.377 23.724-9.085-12.135h7.002l5.956 7.955 7.371-7.955h2.058l-8.51 9.184 9.596 12.816h-7.002l-6.467-8.637-8.004 8.637h-2.058l9.143-9.865Z'/%3E%3C/svg%3E");

      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='45' fill='none'%3E%3Cpath fill='%23E3E7BA' d='M0 .59h44v44H0z'/%3E%3Cpath fill='%23255F47' d='m19.377 23.724-9.085-12.135h7.002l5.956 7.955 7.371-7.955h2.058l-8.51 9.184 9.596 12.816h-7.002l-6.467-8.637-8.004 8.637h-2.058l9.143-9.865Z'/%3E%3C/svg%3E");
      }
    }

    &--in {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='45' fill='none'%3E%3Cpath fill='%23255F47' d='M0 .59h44v44H0z'/%3E%3Cpath fill='%23fff' d='M15.857 12.98c0 1.322-1.05 2.392-2.347 2.392-1.296 0-2.346-1.07-2.346-2.391 0-1.32 1.05-2.392 2.346-2.392s2.347 1.072 2.347 2.392Zm.019 4.305h-4.731v15.304h4.73V17.285Zm7.553 0h-4.701v15.304h4.701v-8.034c0-4.466 5.705-4.832 5.705 0v8.034h4.72V22.9c0-7.538-8.442-7.263-10.425-3.553v-2.061Z'/%3E%3C/svg%3E");

      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='45' fill='none'%3E%3Cpath fill='%23E3E7BA' d='M0 .59h44v44H0z'/%3E%3Cpath fill='%23255F47' d='M15.857 12.98c0 1.322-1.05 2.392-2.347 2.392-1.296 0-2.346-1.07-2.346-2.391 0-1.32 1.05-2.392 2.346-2.392s2.347 1.072 2.347 2.392Zm.019 4.305h-4.731v15.304h4.73V17.285Zm7.553 0h-4.701v15.304h4.701v-8.034c0-4.466 5.705-4.832 5.705 0v8.034h4.72V22.9c0-7.538-8.442-7.263-10.425-3.553v-2.061Z'/%3E%3C/svg%3E");
      }
    }

    &--inst {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='45' fill='none'%3E%3Cpath fill='%23255F47' d='M0 .59h44v44H0z'/%3E%3Cpath fill='%23fff' d='M22 26.923a3.833 3.833 0 1 1-.001-7.666A3.833 3.833 0 0 1 22 26.923Z'/%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M22 11.59c-3.123 0-3.514.013-4.74.068-4.177.192-6.498 2.51-6.69 6.69-.057 1.227-.07 1.618-.07 4.741 0 3.124.013 3.516.069 4.742.192 4.177 2.509 6.498 6.69 6.69 1.227.055 1.618.068 4.741.068 3.123 0 3.515-.013 4.742-.069 4.172-.191 6.5-2.509 6.688-6.689.057-1.226.07-1.618.07-4.742 0-3.123-.013-3.514-.069-4.74-.188-4.173-2.508-6.498-6.688-6.69-1.228-.056-1.62-.07-4.743-.07Zm0 5.594a5.906 5.906 0 1 0 0 11.811 5.906 5.906 0 0 0 0-11.81Zm-3.833 5.905a3.833 3.833 0 1 0 7.665.001 3.833 3.833 0 0 0-7.665 0Zm8.591-6.138a1.38 1.38 0 1 1 2.761.002 1.38 1.38 0 0 1-2.76-.002Z' clip-rule='evenodd'/%3E%3C/svg%3E");

      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='45' fill='none'%3E%3Cpath fill='%23E3E7BA' d='M0 .59h44v44H0z'/%3E%3Cpath fill='%23255F47' d='M22 26.923a3.833 3.833 0 1 1-.001-7.666A3.833 3.833 0 0 1 22 26.923Z'/%3E%3Cpath fill='%23255F47' fill-rule='evenodd' d='M22 11.59c-3.123 0-3.514.013-4.74.068-4.177.192-6.498 2.51-6.69 6.69-.057 1.227-.07 1.618-.07 4.741 0 3.124.013 3.516.069 4.742.192 4.177 2.509 6.498 6.69 6.69 1.227.055 1.618.068 4.741.068 3.123 0 3.515-.013 4.742-.069 4.172-.191 6.5-2.509 6.688-6.689.057-1.226.07-1.618.07-4.742 0-3.123-.013-3.514-.069-4.74-.188-4.173-2.508-6.498-6.688-6.69-1.228-.056-1.62-.07-4.743-.07Zm0 5.594a5.906 5.906 0 1 0 0 11.811 5.906 5.906 0 0 0 0-11.81Zm-3.833 5.905a3.833 3.833 0 1 0 7.665.001 3.833 3.833 0 0 0-7.665 0Zm8.591-6.138a1.38 1.38 0 1 1 2.761.002 1.38 1.38 0 0 1-2.76-.002Z' clip-rule='evenodd'/%3E%3C/svg%3E");
      }
    }
  }

  &__sep {
    width: 100%;
  }

  @mixin lg {
    .swiper-wrapper {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__slide {
      padding-left: ac(58px, 53px, 1024, 1440);
      padding-right: ac(58px, 53px, 1024, 1440);

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }

    &__nav {
      margin-right: ac(20px, 40px, 1024);
    }
  }

  @mixin max-lg {
    &__nav {
      width: 100%;
      gap: 12px ac(50px, 20px, 768, 1024);
    }

    &__line {
      width: 100%;
      height: 1px;
      margin-left: 24px;
      background: #d0d1d3;
    }

    &__privacy-box {
      width: 100%;
    }
  }

  @mixin max-md {
    span.footer__privacy {
      max-width: ac(340px, 176px, 375, 768);
    }

    &__soc {
      width: 36px;
      height: 36px;
    }

    &__soc--in {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none'%3E%3Cpath fill='%23255F47' d='M0 0h36v36H0z'/%3E%3Cpath fill='%23fff' d='M13.368 10.348c0 1.02-.812 1.848-1.813 1.848-1.002 0-1.814-.827-1.814-1.848 0-1.02.812-1.848 1.814-1.848 1.001 0 1.813.828 1.813 1.848Zm.014 3.326H9.728V25.5h3.655V13.674Zm5.837 0h-3.633V25.5h3.633v-6.208c0-3.452 4.409-3.734 4.409 0V25.5h3.647v-7.488c0-5.825-6.524-5.612-8.056-2.745v-1.593Z'/%3E%3C/svg%3E");

      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none'%3E%3Cpath fill='%23E3E7BA' d='M0 0h36v36H0z'/%3E%3Cpath fill='%23255F47' d='M13.368 10.348c0 1.02-.812 1.848-1.813 1.848-1.002 0-1.814-.827-1.814-1.848 0-1.02.812-1.848 1.814-1.848 1.001 0 1.813.828 1.813 1.848Zm.014 3.326H9.728V25.5h3.655V13.674Zm5.837 0h-3.633V25.5h3.633v-6.208c0-3.452 4.409-3.734 4.409 0V25.5h3.647v-7.488c0-5.825-6.524-5.612-8.056-2.745v-1.593Z'/%3E%3C/svg%3E");
      }
    }

    &__soc--inst {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none'%3E%3Cpath fill='%23255F47' d='M0 0h36v36H0z'/%3E%3Cpath fill='%23fff' d='M18 21.5a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z'/%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M18 9.5c-2.444 0-2.75.01-3.71.054-3.269.15-5.085 1.963-5.235 5.235C9.01 15.749 9 16.056 9 18.5s.01 2.751.054 3.711c.15 3.269 1.963 5.085 5.235 5.235.96.043 1.267.054 3.711.054s2.751-.01 3.711-.054c3.266-.15 5.087-1.963 5.234-5.235.044-.96.055-1.267.055-3.711s-.01-2.75-.054-3.71c-.147-3.266-1.963-5.085-5.234-5.235C20.75 9.51 20.444 9.5 18 9.5Zm0 4.379a4.622 4.622 0 1 0 0 9.243 4.622 4.622 0 0 0 0-9.243ZM15 18.5a3 3 0 1 0 6 0 3 3 0 0 0-6 0Zm6.724-4.804a1.08 1.08 0 1 1 2.16.001 1.08 1.08 0 0 1-2.16 0Z' clip-rule='evenodd'/%3E%3C/svg%3E");

      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none'%3E%3Cpath fill='%23E3E7BA' d='M0 0h36v36H0z'/%3E%3Cpath fill='%23255F47' d='M18 21.5a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z'/%3E%3Cpath fill='%23255F47' fill-rule='evenodd' d='M18 9.5c-2.444 0-2.75.01-3.71.054-3.269.15-5.085 1.963-5.235 5.235C9.01 15.749 9 16.056 9 18.5s.01 2.751.054 3.711c.15 3.269 1.963 5.085 5.235 5.235.96.043 1.267.054 3.711.054s2.751-.01 3.711-.054c3.266-.15 5.087-1.963 5.234-5.235.044-.96.055-1.267.055-3.711s-.01-2.75-.054-3.71c-.147-3.266-1.963-5.085-5.234-5.235C20.75 9.51 20.444 9.5 18 9.5Zm0 4.379a4.622 4.622 0 1 0 0 9.243 4.622 4.622 0 0 0 0-9.243ZM15 18.5a3 3 0 1 0 6 0 3 3 0 0 0-6 0Zm6.724-4.804a1.08 1.08 0 1 1 2.16.001 1.08 1.08 0 0 1-2.16 0Z' clip-rule='evenodd'/%3E%3C/svg%3E");
      }
    }

    &__soc--x {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none'%3E%3Cpath fill='%23255F47' d='M0 0h36v36H0z'/%3E%3Cpath fill='%23fff' d='M15.973 18.877 8.953 9.5h5.41l4.602 6.147L24.662 9.5h1.59l-6.576 7.096L27.09 26.5h-5.41l-4.998-6.675-6.185 6.675h-1.59l7.065-7.623Z'/%3E%3C/svg%3E");

      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none'%3E%3Cpath fill='%23E3E7BA' d='M0 0h36v36H0z'/%3E%3Cpath fill='%23255F47' d='M15.973 18.877 8.953 9.5h5.41l4.602 6.147L24.662 9.5h1.59l-6.576 7.096L27.09 26.5h-5.41l-4.998-6.675-6.185 6.675h-1.59l7.065-7.623Z'/%3E%3C/svg%3E");
      }
    }

    &__nav {
      grid-template-columns: repeat(4, max-content);
      gap: 12px ac(70px, 50px, 640, 768);
    }
  }

  @mixin max-sm {
    &__decor-box {
      height: calc(100% + 142px);
      top: -142px;
    }

    &__decor {
      width: 440px;
      height: 390px;
      left: 50%;
      transform: translateX(calc(ac(300px, 110px, 375, 639) - 50%));
    }

    &__nav {
      grid-template-columns: repeat(2, max-content);
      gap: 13px ac(170px, 50px, 320, 640);
      span {
        width: 100%;
      }

      li {
        min-width: 95px;
      }
    }

    &__slider-box {
      width: calc(100% - 50px);
    }

    &__contact {
      &::before {
        margin-left: -6px;
        margin-right: 0;
      }
    }

    .slider-btn,
    &__line {
      margin-top: 16px;
    }
  }

  @mixin max-xs {
    &__bottom {
      flex-direction: column;

      .bold-logo {
        a {
          margin: 0 auto;
        }
      }

      & > * {
        width: 100%;
        margin: 0 !important;
        justify-content: center;
      }
    }
    span.footer__privacy {
      max-width: 220px;
      margin-right: auto;
      text-align: center;
    }

    &__slider-box {
      width: 100%;
    }
  }
}
