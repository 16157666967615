.popup-studies {
    position: relative;
    max-width: 1024px;
    max-height: 85vh;
    max-height: 85dvh;
    padding: 0;

    .popup__content {
        display: contents;
        max-height: unset;
    }

    .simplebar-horizontal {
        display: none !important;
    }

    & > .simplebar-track.simplebar-vertical {
        top: 55px;
        .simplebar-scrollbar::before {
            opacity: 0.3 !important;
            background-color: var(--black);
        }
    }

    &__wrapper {
        display: flex;
    }

    &__title {
        max-width: calc(100% - ac(0px, 25px));
        margin-bottom: ac(25px, 15px);

        color: var(--Gray-I);
        font-size: ac(48px, 24px);
        line-height: ac(64px, 32px);
        font-weight: 300;
        letter-spacing: -0.24px;

        span {
            color: var(--forest);
            font-weight: 700;
        }
    }

    .studies__tag-box {
        margin-top: 0;
        margin-bottom: ac(40px, 20px);

        & + * {
            margin-top: 0 !important;
        }
    }

    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -1px;
        right: -1px;
        width: 56px;
        height: 56px;
        border: 1px solid var(--forest);
        background-color: var(--forest);
        transition: 0.3s ease;
        cursor: pointer;
        z-index: 5;

        &::after {
            content: '';
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23C3D600' d='M1.439 16.547 2.302 20l7.77-7.77 6.475 6.475L20 17.842l-7.77-7.77 6.62-6.619L17.985 0l-7.914 7.914-6.619-6.619L0 2.158l7.914 7.914-6.475 6.475Z'/%3E%3C/svg%3E");
            cursor: pointer;
            transition: 0.3s ease;
        }

        &:hover {
            border-color: var(--forest);
            background-color: var(--Lime-III);

            &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23255f47' d='M1.439 16.547 2.302 20l7.77-7.77 6.475 6.475L20 17.842l-7.77-7.77 6.62-6.619L17.985 0l-7.914 7.914-6.619-6.619L0 2.158l7.914 7.914-6.475 6.475Z'/%3E%3C/svg%3E");
            }
        }
    }

    &__text-box {
        width: 100%;
        padding: 48px 0 0;
        overflow: hidden;

        .details__text {
            padding-right: ac(75px, 40px);
            padding-left: ac(46px, 20px);

            p:first-of-type {
                margin-bottom: 8px;
                font-size: ac(18px, 16px);
                line-height: ac(28px, 20px);
            }

            p {
                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            }
            h3 {
                &:not(:first-child) {
                    margin-top: ac(26px, 20px);
                }
            }
            h6 {
                &:not(:first-child) {
                    margin-top: ac(24px, 20px);
                }
                &:not(:last-child) {
                    margin-bottom: ac(16px, 15px);
                }
            }
        }
    }

    &__scroll {
        position: relative;
        height: 100%;
        max-height: calc(85vh - 185px);
        max-height: calc(85dvh - 185px);
        width: 100%;

        &.is-bottom {
            &::before {
                opacity: 0;
            }
        }

        &::before {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 40px;
            background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0.8) 43.02%,
                #fff 72.71%
            );
            z-index: 1;
            pointer-events: none;
            transition: 0.3s ease;
        }
        .simplebar-horizontal {
            display: none;
        }

        .simplebar-track.simplebar-vertical {
            width: ac(18px, 10px);
            right: ac(19px, 10px);
            top: 30px;
            border: 1px solid var(--Lime-II);
            background: var(--Lime-III);

            &:nth-child(2 of .simplebar-vertical) {
                display: none;
            }
        }

        .simplebar-scrollbar::before, .simplebar-scrollbar {
            border: ac(4px, 1px) solid var(--Lime-II);
            opacity: 0.3 !important;
            background-color: var(--black);
        }
    }

    &__btn-box {
        margin-top: ac(32px, 20px);
        padding: ac(20px, 10px) ac(50px, 15px);
        border-top: 1px solid var(--Gray-III);
        background: var(--Lime-III);
    }

    @mixin max-md {
        max-width: 500px;

        &__text-box {
            padding-top: 20px;

            .simplebar-track.simplebar-vertical {
                top: 50px;
            }
        }
    }

    @mixin max-md {
        &__text-box {
            .btn {
                width: 100%;
            }
        }
    }
}
