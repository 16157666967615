.team {
  margin-top: ac(148px, var(--mmt));

  & + .opportunities {
    margin-top: ac(96px, var(--mmt));
  }

  &--roles {
    margin-top: ac(159px, var(--mmt));
  }

  &--banking {
    margin-top: ac(175px, var(--mmt));

    .team__title {
      span {
        display: inline-block;
        margin-top: 8px;
      }
    }
  }

  &__wrapper {
    gap: ac(64px, 32px);
  }

  &__title {
    margin-top: ac(20px, 8px);
  }

  &__col {
    margin-left: auto;
    align-items: center;

    .hero__link {
      margin-right: 24px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      padding: 0 19px 0 25px;
      border: 1px solid var(--forest);
      color: var(--forest);
      background-color: var(--white);

      font-weight: 700;
      font-size: 18px;
      line-height: 46px;
      white-space: nowrap;
      cursor: pointer;
      transition: 0.3s ease;

      &::before {
        display: none;
      }

      &::after {
        margin-left: 4px;
        width: 24px;
        height: 24px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23255f47' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
        transition: 0.3s ease;

        position: relative;
        background-color: unset;
        right: unset;
        transform: unset;
      }

      &:hover {
        background-color: var(--forest);
        color: var(--white);

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23FFFFFF' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
        }
      }
    }
  }

  .slider-btn.prev {
    margin-left: 0;
  }

  &__btn {
    margin-right: 24px;
  }

  &__slider-box {
    width: 100%;
  }

  &__card {
    height: 100%;
    border: 1px solid var(--Gray-III);
    background: var(--Lime-III);
    transition: 0.3s ease;
    cursor: pointer;

    &:hover {
      border-color: var(--forest);
    }
    &--red {
      background: var(--Salmon-III);

      .team__kind {
        color: var(--maroon);
      }
      .team__line {
        background-color: var(--Salmon-I);
      }

      .team__soc {
        rect {
          fill: var(--maroon);
        }

        path {
          fill: var(--white);
        }

        &:hover {
          rect {
            fill: var(--white);
          }

          path {
            fill: var(--maroon);
          }
        }
      }

      &:hover {
        border-color: var(--maroon);
      }
    }
    &--blue {
      background: var(--Blue-III);

      .team__kind {
        color: var(--navy);
      }
      .team__line {
        background-color: var(--Blue-I);
      }

      .team__soc {
        rect {
          fill: var(--navy);
        }

        path {
          fill: var(--white);
        }

        &:hover {
          rect {
            fill: var(--white);
          }

          path {
            fill: var(--navy);
          }
        }
      }

      &:hover {
        border-color: var(--navy);
      }
    }
  }

  &__img {
    flex-shrink: 0;
    width: 100%;
    height: ac(354px, 250px, 320);
    background-color: var(--Gray-III);
    clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%);

    img {
      clip-path: polygon(0 0, 100% 0, 100% calc(75% - 1px), 0 calc(100% - 1px));

      @mixin max-sm {
        object-position: top;
      }
    }
  }

  &__bottom {
    height: 100%;
    margin-top: ac(-16px, -10px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 ac(40px, 15px) ac(39px, 20px);
  }

  &__kind {
    color: var(--forest);
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
  }

  &__name {
    margin-top: 11px;
    font-size: ac(28px, 22px);
    line-height: ac(36px, 28px);
    font-weight: 700;
    letter-spacing: -0.07px;
  }

  &__position {
    margin-top: 13px;
    margin-bottom: 19px;
    font-size: ac(22px, 16px);
    font-weight: 300;
    line-height: ac(32px, 20px);
  }

  &__line {
    margin-top: auto;
    width: 56px;
    height: 3px;
    background-color: var(--forest);
    opacity: 0.5;
  }

  &__contact-box {
    margin-top: ac(32px, 20px);
    gap: 16px;
  }

  &__soc {
    width: 44px;
    height: 44px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: 0.3s ease;

    rect,
    path {
      transition: 0.3s ease;
    }
    rect {
      fill: var(--forest);
    }

    path {
      fill: var(--white);
    }

    &:hover {
      box-shadow: 0 0 1px 1px var(--forest);
      cursor: pointer;

      rect {
        fill: var(--white);
      }

      path {
        fill: var(--forest);
      }
    }
  }

  @mixin md {
    &--banking {
      .team__title-box {
        max-width: ac(800px, 350px);
      }
    }
    &__title-box {
      flex-shrink: 0;
      width: 100%;
      max-width: ac(702px, 350px);
    }

    &__col {
      margin-top: auto;
    }
  }

  @mixin max-lg {
    &__title {
      span {
        display: block;
      }
    }
  }

  @mixin max-md {
    &__col {
      order: 1;
      margin-right: auto;
    }
  }

  @mixin max-sm {
    &__col {
      margin-right: 0;
      margin-left: 0;
      width: 100%;

      .slider-btn.prev {
        margin-left: auto;
      }

      .slider-btn.next {
        margin-right: 0;
      }

      .hero__link {
        margin-right: 16px;
      }
    }

    &__btn {
      margin-right: 16px;
    }

    &__slider-box {
      max-width: 400px;
    }
  }

  @mixin max-xs {
    &__col {
      margin-left: ac(60px, 40px, 320, 374);
      margin-right: ac(60px, 40px, 320, 374);
      justify-content: center;

      .hero__link {
        margin-bottom: 20px;
        margin-right: 0;
      }
    }

    .prev.slider-btn {
      margin-left: auto;
    }

    &__btn {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}

.team__soc-wrap {
  position: relative;
}

.no-touch-phone {
  position: absolute;
  bottom: calc(100% + 8px);
  left: 50%;
  translate: -50% 0;
  white-space: nowrap;
  min-width: 150px;

  padding: 10px;

  background: var(--white);
  border: 1px solid var(--Gray-III);

  text-align: center;

  opacity: 0;
  visibility: hidden;
  scale: 0.5;

  transition: all ease 250ms;

  &.active {
    opacity: 1;
    visibility: visible;
    scale: 1;
  }
}
