.types {
  margin-top: ac(96px, var(--mmt));
  padding: ac(96px, var(--mmt)) 0 ac(105px, var(--mmt));
  background-color: var(--Salmon-III);

  &__title {
    span {
      color: var(--maroon);
    }
  }

  &__slider {
    margin-top: ac(74px, 20px);
  }

  &__icon {
    width: ac(140px, 100px);
    height: ac(140px, 100px);
    padding: ac(26px, 15px);
  }

  &__name {
    margin-top: 8px;
    color: var(--maroon);
    font-size: ac(28px, 20px);
    font-weight: 700;
    line-height: ac(36px, 26px);
    letter-spacing: -0.07px;
    max-height: calc(ac(36px, 26px) * 2);
    @mixin max-line-length 2;
  }

  .slider-btn {
    margin-top: 20px;
  }

  @mixin lg {
    .swiper-wrapper {
      gap: 60px 15px;
    }
    .swiper-slide {
      width: calc(100% / 3 - 10px);

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -8px;
        width: 1px;
        height: calc(100% + 20px);
        background-color: var(--Gray-III);
      }

      &:nth-child(3n + 3) {
        &:before {
          display: none;
        }
      }
    }
  }
}
