.benefits {
  margin-top: ac(48px, var(--mmt));

  &__title {
    margin-top: ac(21px, 9px);
  }

  &__subtitle {
    margin-top: ac(24px, 15px);
    color: var(--Gray-I);
    font-size: ac(22px, 18px);
    font-weight: 300;
    line-height: ac(32px, 24px);

    max-height: calc(ac(32px, 24px) * 6);
    @mixin max-line-length 6;
  }

  &__slider {
    margin-top: ac(47px, 20px);
    padding-top: ac(48px, 20px);
    border-top: 1px solid var(--Gray-III);

    .swiper-slide {
      &:nth-child(5n + 2) {
        .benefits__name::after {
          background-color: var(--navy);
        }
      }
      &:nth-child(5n + 3) {
        .benefits__name::after {
          background-color: var(--maroon);
        }
      }
      &:nth-child(5n + 4) {
        .benefits__name::after {
          background-color: var(--Blue-I);
        }
      }
      &:nth-child(5n + 5) {
        .benefits__name::after {
          background-color: var(--Salmon-I);
        }
      }
    }
  }

  .slider-btn {
    margin-top: 20px;
  }

  &__name {
    position: relative;
    padding-bottom: ac(20px, 10px);
    color: var(--Gray-I);
    font-weight: 700;
    font-size: ac(28px, 22px);
    line-height: ac(36px, 28px);
    letter-spacing: -0.07px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: ac(64px, 40px);
      height: ac(4px, 2px);
      background-color: var(--forest);
    }
  }

  &__scroll {
    margin: ac(23px, 15px) 0;
    width: 100%;
    max-height: calc(24px * 6);

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      right: -10px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__descr {
    color: var(--black-sec);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  @mixin md {
    &__title-box {
      flex-shrink: 0;
      max-width: ac(615px, 400px);
    }
  }

  @mixin lg {
    .slider-btn {
      display: none;
    }

    .swiper-wrapper {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 18px ac(40px, 20px);
    }
  }
}
