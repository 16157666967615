.locations {
  margin-top: ac(100px, var(--mmt));

  &--sectors {
    margin-top: 0;
  }

  &--location {
    margin-top: ac(96px, var(--mmt));
    .locations {
      &__nav {
        margin-top: ac(32px, 20px);

        .slider-btn.prev {
          margin-left: 0;
        }
      }
    }
  }

  &__title-box {
    width: 100%;
  }

  &__title {
    margin-top: ac(27px, 10px);
  }

  &__suptitle {
    color: var(--forest);
    font-size: ac(16px, 14px);
    line-height: ac(22px, 19px);
    font-weight: 700;
  }

  &__subtitle {
    max-height: calc(ac(28px, 24px) * 6);
    @mixin max-line-length 6;
  }

  &__thumb {
    margin-top: ac(40px, 20px);

    &::before {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--Gray-III);
    }

    .swiper-slide {
      width: auto;
      cursor: default;

      &:last-child {
        .locations__tab {
          &::before {
            display: none;
          }
        }
      }

      &:not(.swiper-slide-thumb-active) {
        cursor: pointer;
      }

      &-thumb-active {
        .locations__tab {
          padding: ac(11px, 8px) ac(25px, 15px) ac(11px, 8px) ac(42px, 30px);

          span {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }

      &-thumb-active,
      &:hover {
        .locations__tab {
          background-color: var(--forest);
          color: var(--Lime-II);

          &::before {
            height: 100%;
            background-color: var(--forest);
          }
        }
      }
    }
  }

  &__tab {
    position: relative;
    padding: ac(11px, 8px) ac(21px, 15px);

    color: var(--Gray-I);
    font-size: 16px;
    line-height: 22px;
    font-weight: 800;
    text-transform: capitalize;

    transition: 0.3s ease;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      width: 1px;
      height: ac(20px, 14px);
      background-color: var(--Gray-III);
      transition: 0.3s ease;
    }

    span {
      position: absolute;
      left: ac(21px, 15px);
      width: ac(16px, 12px);
      height: ac(16px, 12px);
      border-radius: 100%;
      border: 1px solid var(--Lime-I);
      transform: translateX(-100%);
      opacity: 0;
      transition: 0.3s ease;

      &::before {
        content: '';
        width: ac(8px, 6px);
        height: ac(8px, 6px);
        background-color: var(--Lime-I);
        border-radius: 100%;
      }
    }
  }

  &__main-slider {
    margin-top: ac(48px, 20px);

    & > .swiper-wrapper > .swiper-slide {
      display: flex;
      align-items: center;
      opacity: 0 !important;

      &-active {
        opacity: 1 !important;

        .locations__img,
        .locations__time-box {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
  }

  &__inner-slider {
    position: relative;
    z-index: 2;
    padding: ac(48px, 20px) ac(56px, 24px) ac(48px, 25px);
    background: var(--Lime-III);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12),
      0px 0px 4px 0px rgba(0, 0, 0, 0.08);
    & > .swiper-wrapper > .swiper-slide {
      & > * {
        z-index: 2;
      }
      opacity: 0 !important;

      &-active {
        opacity: 1 !important;
      }
    }

    .slider-btn.next {
      margin-right: 0;
    }
  }

  &__name {
    margin-top: ac(17px, 16px);
    font-size: ac(36px, 24px);
    line-height: ac(48px, 36px);
    font-weight: 700;
    letter-spacing: -0.09px;
  }

  &__img {
    width: 100%;
    aspect-ratio: 1.95 / 1;
    transform: translateX(50%);
    opacity: 0;
    transition: 0.5s linear;
  }

  &__nav {
    margin-top: ac(64px, 31px);
    user-select: none;
  }

  &__sep {
    width: 100%;
    height: 1px;
    background-color: var(--Gray-III);
  }

  &__time-box {
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    align-items: center;
    gap: ac(16px, 8px);
    padding: ac(32px, 17px);

    font-size: ac(72px, 40px);
    line-height: ac(80px, 40px);
    font-weight: 300;
    leading-trim: both;
    text-edge: cap;
    color: var(--forest);

    border: 1px solid var(--Gray-III);
    background: var(--white);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12),
      0px 0px 2px 0px rgba(0, 0, 0, 0.16);

    transform: translateX(-50%);
    opacity: 0;
    transition: 0.5s linear;

    .colon {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: ac(8px, 4px);

      width: ac(24px, 12px);
      height: ac(64px, 30px);
      animation: blink 1s linear infinite;

      &::before,
      &::after {
        content: '';
        width: ac(8px, 4px);
        height: ac(8px, 4px);
        background-color: #255f4780;
      }

      @keyframes blink {
        from {
          opacity: 0.5;
        }

        to {
          opacity: 1;
        }
      }
    }
  }

  &__scroll {
    margin-top: ac(22px, 15px);
    width: 100%;
    max-height: 240px;

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      right: -10px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__descr {
    padding-bottom: 2px;
    p {
      color: var(--black);
      font-size: ac(18px, 16px);
      font-weight: 400;
      line-height: ac(28px, 20px);

      &:not(:first-child) {
        margin-top: ac(15px, 10px);
      }

      &:first-child {
        color: var(--Gray-I);
        font-size: ac(22px, 18px);
        font-weight: 700;
        line-height: ac(32px, 24px);
      }
    }
  }

  @mixin sm {
    &--location {
      .locations__sep {
        margin: 0 0 0 ac(32px, 15px);
      }
    }
    &__sep {
      margin: 0 ac(32px, 15px);
    }
  }

  @mixin md {
    &--location {
      .locations__inner-slider {
        max-width: ac(617px, 400px);
      }

      .locations__img {
        max-width: ac(819px, 600px);
        aspect-ratio: 1.18 / 1;
      }
    }
    &__title-box {
      flex-shrink: 0;
      max-width: ac(600px, 300px);
    }

    &__subtitle {
      /* margin-top: ac(36px, 15px); */
      max-width: ac(600px, 300px);
    }

    &__inner-slider {
      max-width: ac(602px, 400px);
      margin-left: 0;
    }

    &__img {
      margin-left: ac(-104px, -150px);
      flex-shrink: 0;
      max-width: ac(958px, 600px);

      position: relative;

      .img {
        position: absolute;
        opacity: 0;

        &.active {
          opacity: 1;
        }
      }
    }
  }

  @mixin max-md {
    &__main-slider {
      width: calc(100% + 48px);
      margin-left: -24px;
      & > .swiper-wrapper > .swiper-slide {
        flex-direction: column;
      }
    }

    &__img {
      margin-top: -68px;
      min-height: 320px;
      position: relative;

      .img {
        position: absolute;
        opacity: 0;
        height: 100%;

        &.active {
          opacity: 1;
        }
      }
    }

    &__inner-slider {
      width: calc(100% - 48px);
    }

    &__time-box {
      top: auto;
      right: 24px;
      bottom: 0;
    }
  }

  @mixin max-sm {
    &--location {
      .locations__sep {
        margin-left: auto;
      }
    }

    &__nav {
      flex-wrap: wrap;
    }

    &__btn {
      width: 100%;
      margin-bottom: 24px;
    }

    &__sep {
      width: calc(100% - 110px);
    }
  }

  @mixin max-xs {
    &__main-slider {
      width: calc(100% + 30px);
      margin-left: -15px;
    }

    &__inner-slider {
      width: calc(100% - 30px);
    }

    &__time-box {
      right: 15px;
    }
  }
}
