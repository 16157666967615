.transformation {
  margin-top: ac(108px, var(--mmt));
  position: relative;
  z-index: 1;

  &:not(.transformation--dynamic) {
    .transformation__head {
      span {
        color: var(--white);
        font-size: ac(28px, 18px, 320);
        line-height: ac(36px, 24px, 320);
        font-weight: 700;
        letter-spacing: -0.07px;
        max-height: calc(ac(36px, 24px, 320) * 5);
        @mixin max-line-length 5;
      }
    }
  }

  &--dynamic {
    margin-top: ac(96px, var(--mmt));

    .transformation {
      &__head {
        padding: ac(32px, 15px) ac(40px, 15px);
      }

      &__name {
        color: var(--white);
        font-size: ac(28px, 18px, 320);
        line-height: ac(36px, 24px, 320);
        font-weight: 700;
        letter-spacing: -0.07px;
      }

      &__bottom {
        padding: ac(30px, 15px) ac(40px, 15px) ac(38px, 15px);
      }

      &__scroll {
        margin-bottom: 0;
        margin-top: ac(15px, 10px);
        width: calc(100% + 10px);
        padding-right: 10px;
        max-height: calc(ac(28px, 24px) * 4);
      }

      &__descr {
        font-size: ac(18px, 16px);
        font-weight: 400;
        line-height: ac(28px, 24px);
      }

      &__thumb {
        margin-top: ac(25px, 15px);
      }

      &__label {
        color: var(--white);
        font-size: ac(22px, 18px);
        font-weight: 700;
        line-height: ac(32px, 24px);
      }

      &__table {
        margin-top: ac(16px, 10px);
      }

      &__cell {
        flex: 1 0 ac(260px, 180px);
        padding: 7px ac(7px, 0px) 7px ac(19px, 12px);
        gap: 10px;
        display: inline-flex;
        align-items: center;
        border: 1px solid #537a5d;
        background: #1f503b;

        color: var(--Lime-III);
        font-size: ac(18px, 16px);
        font-weight: 700;
        line-height: ac(25px, 22px);

        &::after {
          content: '';
          width: 40px;
          height: 40px;
          margin-left: auto;
          flex-shrink: 0;
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='none'%3E%3Cpath fill='%23E3E7BA' d='m21.928 23.33-2.207-.027V18.61l-8.252 8.252-1.559-1.559 8.253-8.252h-4.694l-.026-2.207 8.49-.004-.005 8.49Z'/%3E%3C/svg%3E")
            center / contain no-repeat;
        }
      }
    }
  }

  &--change {
    margin-top: ac(130px, 68px);

    .transformation {
      &__wrapper {
        margin-top: 0;
        gap: ac(65px, 25px) ac(40px, 15px);
      }
      &__tab {
        font-weight: 700;
        color: var(--black);
        background-color: var(--Lime-III);

        &:not(:last-child) {
          border-bottom: 0;
        }
      }
      &__subtitle {
        margin-top: 0;
      }

      &__thumb {
        margin-top: 0;
      }
      &__bottom {
        padding: ac(32px, 15px) ac(40px, 15px);
      }

      &__scroll {
        margin-bottom: 0px;
        max-height: calc(ac(36px, 24px) * 9);
      }
    }
  }

  &__wrapper {
    gap: ac(40px, 20px);
  }

  &__col {
    width: 100%;
  }

  &__subtitle {
    margin-top: ac(24px, 12px);
    color: var(--Gray-I);
    font-size: ac(22px, 18px);
    line-height: ac(32px, 24px);
    font-weight: 300;
  }
  &__text {
    margin-top: ac(30px, 15px);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    & > p {
      &:not(:last-child) {
        margin-bottom: ac(16px, 10px);
      }

      &:first-child {
        font-size: 18px;
        line-height: 25px;
        font-weight: 700;
      }
    }
  }
  &__thumb {
    margin-top: ac(32px, 15px);

    .swiper-slide {
      cursor: pointer;

      &:hover {
        .transformation__tab {
          background-color: var(--Lime-III);
        }
      }

      &-thumb-active {
        cursor: default;

        .transformation__tab {
          color: var(--black);
          background-color: var(--Lime-III);
        }

        .transformation__icon {
          border-color: var(--Gray-III);
          background-color: transparent;

          &::before,
          &::after {
            background-color: var(--Gray-II);
          }

          &::after {
            height: 0;
          }
        }
      }
    }
  }

  &__tab {
    padding: ac(15px, 10px) ac(15px, 10px) ac(15px, 10px) ac(31px, 10px);
    height: 100%;
    border: 1px solid var(--Gray-III);

    span {
      color: var(--Gray-I);
      font-size: 18px;
      line-height: 25px;
      font-weight: 700;
      transition: 0.3s ease;
      max-height: calc(25px * 3);
      @mixin max-line-length 3;
    }
  }

  &__icon {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border: 1px solid var(--forest);
    background-color: var(--forest);
    transition: 0.3s ease;

    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: var(--Lime-III);
      transition: 0.3s ease;
    }

    &::before {
      width: 16px;
      height: 2px;
    }
    &::after {
      width: 2px;
      height: 16px;
    }
  }

  &__slider-box {
    display: flex;
  }

  &__slider {
    display: grid;
    overflow: hidden;

    .swiper-slide {
      opacity: 0 !important;

      &-active {
        opacity: 1 !important;
      }
    }
  }

  &__logo {
    margin-right: 8px;
    width: ac(108px, 50px, 320);
    height: ac(108px, 50px, 320);
    flex-shrink: 0;
  }

  &__head {
    padding: ac(24px, 15px);
    border-bottom: 1px solid rgba(227, 231, 186, 0.3);
    background: rgba(43, 112, 81, 0.5);
  }

  &__bottom {
    padding: ac(32px, 15px) ac(40px, 15px) ac(60px, 20px);
  }

  &__scroll {
    margin-bottom: 20px;
    width: calc(100% + 10px);
    padding-right: 10px;
    max-height: calc(ac(36px, 24px) * 8);
  }

  &__descr {
    color: var(--white);
    font-size: ac(24px, 18px);
    font-weight: 400;
    line-height: ac(36px, 24px);
  }

  &__link {
    max-width: 134px;
    margin-top: auto;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
    padding-right: 35px;
    color: var(--white);
    transition: 0.3s ease;
    cursor: pointer;

    &::after,
    &::before {
      content: '';
      position: absolute;
      transition: 0.3s ease;
    }

    &::after {
      right: ac(9px, 11px);
      width: 10px;
      height: 14px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='14' fill='none'%3E%3Cpath fill='%23E3E7BA' d='m2.317 14 7-7-7-7L.684 1.633 6.05 7 .684 12.367 2.317 14Z'/%3E%3C/svg%3E")
        center / contain no-repeat;
      transition: 0.3s ease;
    }

    &::before {
      right: 0;
      bottom: ac(-12px, -7px);
      width: 100%;
      max-width: 32px;
      height: 1px;
      background-color: var(--Lime-II);
    }

    &:hover {
      max-width: 100%;
      padding-left: 12px;
      color: var(--Lime-II);

      &::before {
        max-width: 100%;
      }
    }
  }

  &__accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @mixin lg {
      display: none;
    }
  }

  &__accordion-content {
    width: 100%;
    max-width: 100%;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.4s ease;
    padding-bottom: 0;

    &.active {
      opacity: 1;
      max-height: 800px;
      padding-bottom: 10px !important;
    }
  }

  &__accordion-toggle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .transformation__tab {
      width: 100%;
    }

    &.active {
      .transformation__tab {
        color: var(--black);
        background-color: var(--Lime-III);
      }

      .transformation__icon {
        border-color: var(--Gray-III);
        background-color: transparent;

        &::before,
        &::after {
          background-color: var(--Gray-II);
        }

        &::after {
          height: 0;
        }
      }
    }
  }

  @mixin md {
    &--change {
      .transformation__title {
        max-width: ac(392px, 300px);
        flex-shrink: 0;
      }
      .transformation__subtitle {
        width: 100%;
        max-width: ac(584px, 350px);
        flex-shrink: 0;
      }

      .transformation__col {
        max-width: unset;
        flex-shrink: unset;
      }
    }
  }

  @mixin lg {
    &__scrollbar {
      display: none;
    }

    &__col {
      max-width: ac(584px, 455px, 1024);
      flex-shrink: 0;
    }

    &__slider-box {
      max-width: ac(600px, 490px, 1024);
    }

    &__thumb {
      .swiper-wrapper {
        display: grid;
        grid-auto-rows: 1fr;
        grid-template-columns: 1fr;
      }

      .swiper-slide {
        &:not(:last-child) {
          .transformation__tab {
            border-bottom: 0;
          }
        }
      }
    }

    &--change {
      .transformation__col {
        max-width: unset;
      }
    }
  }

  @mixin max-lg {
    &--dynamic {
      .transformation__wrapper {
        flex-direction: column-reverse;
      }
    }
    &__wrapper {
      flex-direction: column;
    }

    &__slider-box {
      max-width: 600px;
      margin: 0 auto;
      display: none;
    }

    &__thumb {
      display: none;
    }

    &__scrollbar {
      margin-top: 15px;
      width: 100% !important;
      height: 8px !important;

      background: var(--Lime-II);
      border-radius: 0 !important;
      opacity: 1;

      .swiper-scrollbar-drag {
        background: var(--forest);
        border-radius: 0 !important;
        opacity: 1;
        cursor: grab;
      }
    }
  }
}
