.talent-solutions {
  margin-top: ac(129px, var(--mmt));

  &__title {
    margin-bottom: ac(62px, 25px);
  }

  &__wrapper {
    display: flex;
    align-items: center;
    margin: 0 auto;

    &:not(:last-child) {
      margin-bottom: ac(40px, 20px);
    }

    &.is-reverse {
      flex-direction: row-reverse;

      .talent-solutions {
        &__img {
          aspect-ratio: 0.96/1;
        }

        /*&__text-box {
          padding-right: ac(46px, 5px);
        }

        &__scroll {
          padding-right: 15px;

          .simplebar-track {
            right: 0px;
          }
        }*/
      }
    }

    &.is-blue {
      .talent-solutions {
        &__text-box {
          background: var(--Blue-III);
        }

        &__head {
          color: var(--navy);
        }
      }
    }
    &.is-red {
      .talent-solutions {
        &__text-box {
          background: var(--Salmon-III);
        }

        &__head {
          color: var(--maroon);
        }

        &__icon {
          margin-right: ac(12px, 10px);
        }
      }
    }
  }

  &__img {
    width: 100%;
    aspect-ratio: 0.94/1;
    z-index: 1;
  }

  &__text-box {
    width: 100%;
    overflow: hidden;

    padding: ac(48px, 20px) ac(56px, 15px) ac(56px, 20px);
    background: var(--Lime-III);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12),
      0px 0px 4px 0px rgba(0, 0, 0, 0.08);

    .btn {
      @media (max-width: 640px) {
        width: 100%;
      }
    }
  }

  &__head {
    color: var(--forest);
    font-size: ac(36px, 22px);
    font-weight: 700;
    line-height: ac(48px, 28px);
    letter-spacing: -0.09px;
  }

  &__icon {
    width: ac(56px, 40px);
    height: ac(56px, 40px);
    margin-right: ac(20px, 10px);
  }

  &__scroll {
    margin-top: ac(24px, 15px);
    padding-right: 10px;
    max-height: calc(24px * 12 + ac(12px, 6px) * 2);
    width: 100%;

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      background-color: rgba(0, 0, 0, 0.2);
      right: -10px;
    }
  }

  &__descr {
    color: var(--black);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    & > * {
      &:not(:first-child) {
        margin-top: ac(12px, 6px);
      }
    }
  }

  @mixin md {
    &__img {
      flex-shrink: 0;
      max-width: ac(536px, 300px);
    }
  }

  @mixin max-md {
    &__wrapper {
      flex-direction: column;
      max-width: 500px;

      &.is-reverse {
        flex-direction: column;
      }
    }
  }
}
