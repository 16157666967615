.values {
  /* margin-top: ac(74px, 48px); */
  padding: ac(108px, var(--mmt)) 0 ac(96px, var(--mmt));
  background-color: var(--Blue-III);

  &__title {
    font-size: ac(56px, 28px);
    line-height: ac(68px, 37px);
    letter-spacing: -0.28px;

    span {
      letter-spacing: -0.448px;
      color: var(--navy);
    }
  }

  &__subtitle {
    margin-top: ac(24px, 15px);
    color: var(--black);
    font-size: ac(22px, 18px);
    font-weight: 300;
    line-height: ac(32px, 24px);

    max-height: calc(ac(32px, 24px) * 6);
    @mixin max-line-length 6;
  }

  &__slider {
    margin-top: ac(64px, 20px);
    overflow: hidden;
  }

  .slider-btn {
    margin-top: 20px;

    &.prev {
      margin-left: auto !important;
    }
  }

  &__icon {
    width: ac(156px, 100px);
    height: ac(156px, 100px);
  }

  &__name {
    color: var(--navy);
    font-weight: 700;
    font-size: ac(28px, 22px);
    line-height: ac(36px, 28px);
    letter-spacing: -0.07px;
  }

  &__text-box {
    overflow: hidden;
  }

  &__scroll {
    width: 100%;
    margin-top: 12px;
    max-height: calc(24px * 6);

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__descr {
    padding-right: 10px;
    color: var(--Gray-I);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  @mixin md {
    &__title-box {
      flex-shrink: 0;
      max-width: ac(712px, 400px);
    }
  }

  @mixin lg {
    .slider-btn {
      display: none;
    }

    .swiper-wrapper {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: 1fr 1fr;
      gap: 24px 23px;
    }
  }

  @mixin max-lg {
    &__card {
      flex-direction: column;
    }

    &__text-box {
      text-align: center;
    }
  }
}
