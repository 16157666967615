.opportunities {
  position: relative;
  margin-top: ac(95px, var(--mmt));
  padding: ac(96px, var(--mmt)) 0 0;
  background-color: var(--Blue-III);
  z-index: 2;

  &--alt,
  &--diver,
  &--dynamic,
  &--candid,
  &--tech {
    margin-top: ac(108px, var(--mmt));
    padding: ac(96px, var(--mmt)) 0;
    background-color: #28684c;

    .slider-btn {
      border: 1px solid var(--Lime-II);
      background-color: var(--Lime-II);

      &:hover {
        background-color: transparent;
      }
    }

    .opportunities {
      &__slider-box {
        max-width: 100%;
      }

      &__slider {
        max-width: ac(912px, 510px, 375, 1440);
      }

      &__col {
        margin-top: ac(48px, 30px);
        margin-right: auto;
      }
      &__subtitle {
        color: var(--white);
        font-size: ac(22px, 18px);
        font-weight: 300;
        line-height: ac(32px, 22px);
        opacity: 0.8 !important;
      }
      &__title {
        color: var(--white);

        span {
          color: var(--Lime-II);
        }
      }
    }
  }

  &--dynamic {
    margin-top: 0;
  }

  &--details {
    margin-top: ac(108px, var(--mmt));
    padding: ac(96px, var(--mmt)) 0;
    background-color: var(--Blue-III);
    overflow: hidden;

    .opportunities {
      &__slider-box {
        max-width: 100%;
      }

      &__slider {
        max-width: ac(912px, 510px, 375, 1440);
      }

      &__col {
        margin-top: ac(48px, 20px);
        margin-right: auto;
      }
      &__subtitle {
        font-size: ac(22px, 18px);
        font-weight: 300;
        line-height: ac(32px, 22px);
        opacity: 0.8 !important;
      }
    }
  }

  &--candid,
  &--tech {
    margin-top: 0;
  }

  &--candid {
    .opportunities__col {
      .hero__link {
        margin-right: ac(24px, 10px);
        display: inline-flex;
        align-items: center;
        justify-content: center;

        padding: 0 19px 0 25px;
        border: 1px solid var(--Lime-II);
        color: var(--white);
        background-color: transparent;

        font-weight: 700;
        font-size: 18px;
        line-height: 46px;
        white-space: nowrap;
        cursor: pointer;
        transition: 0.3s ease;

        &::before {
          display: none;
        }

        &::after {
          margin-left: 4px;
          width: 24px;
          height: 24px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23FFFFFF' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
          transition: 0.3s ease;

          position: relative;
          background-color: unset;
          right: unset;
          transform: unset;
        }

        &:hover {
          color: var(--forest);
          background-color: var(--Lime-II);

          &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23255F47' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }

  &--join {
    padding: 0;
    padding-bottom: var(--mmt);
    background-color: var(--white);

    .opportunities {
      &__title {
        margin-top: ac(25px, 8px);

        span {
          color: var(--forest);
        }
      }

      &__col {
        margin-top: ac(48px, 32px);
        width: 100%;
        max-width: unset;
        order: 1;
      }

      &__btn {
        margin-left: auto;
      }
    }

    @media (max-width: 640px) {
      padding-bottom: 0;
    }
  }

  &--tech {
    margin-top: ac(64px, var(--mmt));
  }

  &__title {
    margin-top: ac(20px, 5px);

    span {
      color: var(--navy);
    }
  }

  &__col {
    margin-top: ac(48px, 39px);
    max-width: 321px;
    margin-left: auto;
    align-items: center;

    @media (max-width: 640px) {
      max-width: 100%;
      width: 100%;
      justify-content: flex-start;

      .opportunities__btn,
      .hero__link {
        margin-right: auto !important;
      }

      .slider-btn.prev {
        margin-left: 0 !important;
      }

      .slider-btn.next {
        margin-right: 0;
      }
    }
  }

  .slider-btn.prev {
    margin-left: 0;
  }

  &__slider-box {
    width: 100%;
    max-width: ac(912px, 490px, 375, 1440);
    margin: ac(64px, 32px) auto 0;
  }

  &__card {
    height: 100%;
    padding: ac(40px, 27px) ac(32px, 21px) ac(44px, 32px);
    background: var(--Lime-III);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12),
      0px 0px 2px 0px rgba(0, 0, 0, 0.16);
    transition: 0.3s ease;

    &:hover {
      background-color: var(--white);

      .opportunities__link {
        max-width: 100%;
        padding-left: 12px;

        &::before {
          max-width: 100%;
        }

        &::after {
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='14' fill='none'%3E%3Cpath fill='%23255F47' d='m2.317 14 7-7-7-7L.684 1.633 6.05 7 .684 12.367 2.317 14Z'/%3E%3C/svg%3E")
            center / contain no-repeat;
        }
      }
    }
  }

  &__name {
    color: var(--Gray-I);
    text-transform: capitalize;
    font-weight: 700;
    font-size: ac(16px, 14px);
    line-height: ac(24px, 20px);
  }

  &__pos {
    margin-top: ac(19px, 12px);
    font-size: ac(28px, 22px);
    line-height: ac(36px, 28px);
    font-weight: 700;
    letter-spacing: -0.07px;

    max-height: calc(ac(36px, 28px) * 4);
    @mixin max-line-length 4;
  }

  &__info-box {
    display: flex;
    flex-wrap: wrap;
    gap: ac(18px, 15px) ac(25px, 17px);
    margin-top: ac(29px, 20px);
    margin-bottom: ac(50px, 35px);
  }

  &__info {
    font-size: ac(16px, 14px);
    line-height: ac(24px, 20px);
  }

  &__icon {
    margin-right: 10px;
    width: 21px;
    height: 21px;
    flex-shrink: 0;
  }

  &__btn {
    margin-right: 24px;
  }

  &__link {
    max-width: 134px;
    margin-top: auto;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
    padding-right: 35px;
    transition: 0.3s ease;

    &::after,
    &::before {
      content: '';
      position: absolute;
      transition: 0.3s ease;
    }

    &::after {
      right: ac(9px, 11px);
      width: 10px;
      height: 14px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='14' fill='none'%3E%3Cpath fill='%23000' d='m2.317 14 7-7-7-7L.684 1.633 6.05 7 .684 12.367 2.317 14Z'/%3E%3C/svg%3E")
        center / contain no-repeat;
      transition: 0.3s ease;
    }

    &::before {
      right: 0;
      bottom: ac(-12px, -7px);
      width: 100%;
      max-width: 32px;
      height: 1px;
      background-color: var(--forest);
    }
  }

  @mixin md {
    &--details,
    &--dynamic,
    &--alt,
    &--diver,
    &--candid,
    &--tech {
      .opportunities__title-box {
        max-width: ac(470px, 340px);
      }

      .opportunities__subtitle {
        max-width: ac(560px, 340px);
      }
    }

    &--join {
      .opportunities__title-box {
        max-width: ac(620px, 400px);
      }
    }

    &__title-box {
      flex-shrink: 0;
      width: 100%;
      max-width: ac(500px, 300px);
    }

    &__col {
      margin-top: auto;
    }
  }

  @mixin max-md {
    &--details,
    &--dynamic,
    &--alt,
    &--candid,
    &--diver,
    &--tech {
      .opportunities__subtitle {
        margin-top: 15px;
      }
    }
    &__pos {
      letter-spacing: -0.055px;
    }

    &__col {
      order: 1;
    }

    &__info-box {
      br {
        display: none;
      }
    }
  }

  @mixin max-sm {
    &__card {
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12),
        0px 0px 2px 0px rgba(0, 0, 0, 0.16);
    }

    &__link {
      max-width: 100%;

      &::before {
        max-width: 100%;
      }
    }

    &__col {
      margin-right: auto;
    }

    &__btn {
      padding: 0 14px 0 22px;
    }
  }

  @mixin max-xs {
    &--details,
    &--join,
    &--dynamic,
    &--alt,
    &--candid,
    &--diver,
    &--tech {
      .opportunities__col {
        max-width: 200px;
        margin: 20px auto 0;

        .hero__link {
          margin-right: 20px;
          margin-left: 20px;
          margin-bottom: 15px;
        }
      }
    }
    .slider-btn.prev {
      margin-left: auto;
    }

    &__col {
      margin-left: ac(60px, 40px, 320, 374);
      margin-right: ac(60px, 40px, 320, 374);
      justify-content: center;
    }

    &__btn {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}
