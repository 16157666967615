@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;500;600;700;800&display=swap');

:root {
  --white: #ffffff;
  --black: #000000;
  --black-sec: #1f2529;

  --Lime-I: #c3d600;
  --Lime-II: #e3e7ba;
  --Lime-III: #f6fde9;

  --Blue-I: #007dc4;
  --Blue-II: #d1def3;
  --Blue-III: #eff6ff;

  --Salmon-I: #ed6b85;
  --Salmon-II: #f2dbe0;
  --Salmon-III: #fff1f2;

  --Gray-I: #4c4e50;
  --Gray-II: #75767a;
  --Gray-III: #d0d1d3;

  --forest: #255f47;
  --navy: #143366;
  --maroon: #780f30;
  --mid-gray: #818286;
  --light-gray: #dfe0e1;

  --font-main: 'Nunito Sans', sans-serif;

  --mmt: 50px;
}
