.team-list {
  margin-top: ac(108px, var(--mmt));

  &__title {
    span {
      display: inline-flex;
      align-items: center;

      &::after {
        content: '';
        margin: 0 -3px 0 ac(7px, 2px);
        width: ac(32px, 20px);
        height: 2px;
        background-color: var(--Gray-III);
      }
    }
  }

  &__filter {
    margin-top: ac(32px, 0px);

    &.is-active {
      display: block;
    }
  }

  &__row {
    position: relative;
    margin-bottom: ac(32px, 15px);

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: var(--Gray-III);
    }
  }

  &__check-box {
    cursor: pointer;

    &:last-child {
      .team-list__check {
        &::before {
          display: none;
        }
      }
    }

    input:checked ~ .team-list__check {
      background-color: var(--forest);
      color: var(--Lime-II);

      &::before {
        height: 0%;
        background-color: var(--forest);
      }

      padding: ac(11px, 8px) ac(25px, 15px) ac(11px, 8px) ac(42px, 30px);

      span {
        opacity: 1;
        transform: translateX(ac(0px, -5px));
      }
    }
  }

  &__check {
    width: 100%;
    position: relative;
    padding: ac(11px, 8px) ac(21px, 15px);

    color: var(--Gray-I);
    font-size: 16px;
    line-height: 22px;
    font-weight: 800;
    text-transform: capitalize;
    height: 100%;
    transition: 0.3s ease;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      width: 1px;
      height: ac(20px, 14px);
      background-color: var(--Gray-III);
      transition: 0.3s ease;
    }

    span {
      position: absolute;
      left: ac(21px, 15px);
      width: ac(16px, 12px, 768, 769);
      height: ac(16px, 12px, 768, 769);
      border-radius: 100%;
      border: 1px solid var(--Lime-I);
      transform: translateX(-100%);
      opacity: 0;
      transition: 0.3s ease;
      flex-shrink: 0;
      &::before {
        content: '';
        width: ac(8px, 6px, 768, 769);
        height: ac(8px, 6px, 768, 769);
        background-color: var(--Lime-I);
        border-radius: 100%;
      }
    }

    &:hover {
      background-color: var(--forest);
      color: var(--Lime-II);

      &::before {
        height: 100%;
        background-color: var(--forest);
      }
    }
  }

  &__select-box {
    gap: 10px;
  }

  &__select {
    width: 200px;

    .choices {
      .choices__list--dropdown {
        .choices__item {
          font-size: ac(18px, 16px);
          line-height: ac(22px, 20px);
        }
      }
    }
  }

  &__results {
    margin-top: ac(64px, 30px);
    gap: ac(24px, 15px);
  }

  @media (min-width: 451px) {
    &__select {
      .choices {
        .choices__list--dropdown {
          width: auto !important;
          min-width: 100%;

          .choices__item {
            white-space: nowrap;
          }
        }
      }
    }
  }

  @mixin max-sm {
    &__results {
      max-width: 410px;
      width: 100%;
    }
    &__check-box input:checked ~ .team-list__check,
    &__check {
      padding: 10px 15px;

      span {
        margin-left: 0;
      }
    }

    &__check-box {
      border: 1px solid var(--Gray-III);

      &:not(:nth-child(1), :nth-child(2)) {
        border-top: 0;
      }

      &:nth-child(even) {
        border-left: 0;
      }
    }

    &__check {
      span {
        position: relative;
        left: 0;
        margin-right: 5px;
        margin-left: -17px;
      }

      &::before {
        display: none;
      }
    }

    &__select {
      width: 100%;
    }

    &__row {
      &::before {
        display: none;
      }
    }
  }

  @media (max-width: 450px) {
    &__select-box {
      grid-template-columns: 1fr;
    }
  }
}
