.gallery {
  padding: 12px 0;
  margin-top: ac(84px, var(--mmt));

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
  }

  &__slider {
  }

  .swiper-slide {
    width: auto;
    height: ac(314px, 200px);

    @mixin max-sm {
      max-width: 100%;
    }

    @mixin media 490 {
      max-width: 200px;
    }
  }

  .js-marquee {
    height: ac(314px, 200px);
    display: flex;
    gap: 12px;
  }

  &__img {
    height: 100%;
    border: 1px solid var(--Gray-III);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
  }

  @mixin xxxl {
    &__wrapper {
      max-width: 2000px;

      mask: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 1) 15%,
        rgba(255, 255, 255, 1) 75%,
        transparent
      );
    }

    .js-marquee {
      height: 400px;
    }
  }
}
