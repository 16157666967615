.popup-upload-cv {
  position: relative;
  max-width: 962px;
  max-height: 85dvh;
  padding: 0;
  border: 1px solid var(--Gray-III);

  .simplebar-horizontal {
    display: none !important;
  }

  .touch__thumb .swiper-slide-thumb-active .touch__tab {
    padding: ac(11px, 8px) ac(25px, 10px);

    span {
      position: relative;
      left: unset;
      margin-right: 10px;
    }
  }

  .popup__content {
    display: contents;
    max-height: unset;
  }

  .touch__slider {
    .swiper-slide {
      opacity: 0 !important;

      &-active {
        opacity: 1 !important;
      }
    }
  }

  & > .simplebar-track.simplebar-vertical {
    top: 55px;
    .simplebar-scrollbar::before {
      opacity: 0.3 !important;
      background-color: var(--black);
    }
  }

  &
    > .simplebar-wrapper
    > .simplebar-mask
    > .simplebar-offset
    > .simplebar-content-wrapper {
    height: 100% !important;
  }

  &__wrapper {
    display: flex;
    padding: ac(45px, 55px) ac(35px, 15px) ac(30px, 20px);
    border: 1px solid var(--Gray-III);
    background-color: var(--white);

    & > h3 {
      margin: 0 auto;
    }
  }

  &__title {
    padding-right: ac(40px, 0px);
    font-size: ac(36px, 24px);
    line-height: ac(42px, 30px);
    font-weight: 600;
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -1px;
    right: -1px;
    width: 56px;
    height: 56px;
    border: 1px solid var(--forest);
    background-color: var(--forest);
    transition: 0.3s ease;
    cursor: pointer;
    z-index: 5;

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23C3D600' d='M1.439 16.547 2.302 20l7.77-7.77 6.475 6.475L20 17.842l-7.77-7.77 6.62-6.619L17.985 0l-7.914 7.914-6.619-6.619L0 2.158l7.914 7.914-6.475 6.475Z'/%3E%3C/svg%3E");
      cursor: pointer;
      transition: 0.3s ease;
    }

    &:hover {
      border-color: var(--forest);
      background-color: var(--Lime-III);

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23255f47' d='M1.439 16.547 2.302 20l7.77-7.77 6.475 6.475L20 17.842l-7.77-7.77 6.62-6.619L17.985 0l-7.914 7.914-6.619-6.619L0 2.158l7.914 7.914-6.475 6.475Z'/%3E%3C/svg%3E");
      }
    }
  }

  .touch__input-box,
  .agreement,
  .meta-upload-file {
    flex: 1 0 ac(400px, 200px, 320);
  }

  .agreement {
    margin-top: ac(9px, 0px);
    max-width: 391px;

    .checkBox {
      input:checked + span:before {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='10' fill='none'%3E%3Cpath opacity='1' fill='%23C3D600' d='M10.084.834a1.031 1.031 0 0 1 1.472 1.444L6.067 9.139a1.031 1.031 0 0 1-1.485.028L.945 5.528a1.031 1.031 0 1 1 1.458-1.457l2.88 2.878L10.056.864a.334.334 0 0 1 .028-.03h-.001Z'/%3E%3C/svg%3E")
          center / 12px 10px no-repeat;
      }

      span {
        padding-left: 38px;
        font-weight: 700;

        &::before {
          left: 0;
          width: 22px;
          height: 22px;
          border: 1px solid #92afa3;
          border-radius: 3px;
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='10' fill='none'%3E%3Cpath opacity='0' fill='%23C3D600' d='M10.084.834a1.031 1.031 0 0 1 1.472 1.444L6.067 9.139a1.031 1.031 0 0 1-1.485.028L.945 5.528a1.031 1.031 0 1 1 1.458-1.457l2.88 2.878L10.056.864a.334.334 0 0 1 .028-.03h-.001Z'/%3E%3C/svg%3E")
            center / 12px 10px no-repeat;
        }
      }
    }
  }
  @mixin md {
    &--alt {
      height: 85vh;
      max-height: ac(635px, 495px);

      .team__bottom {
        padding: 0 ac(20px, 15px) ac(39px, 20px);
      }

      .team__name {
        font-size: ac(26px, 22px);
      }
    }
  }
  @mixin max-md {
    max-width: 500px;
  }
}
