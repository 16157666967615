.job-list {
  margin-top: ac(96px, var(--mmt));

  &__wrapper {
    width: 100%;
    margin-top: ac(48px, 30px);
    gap: ac(56px, 20px, 1024);
  }

  &__col {
    width: 100%;
  }

  &__filter-box {
    margin-top: ac(64px, 0px);
    width: 100%;
  }

  &__title {
    margin-bottom: ac(40px, 20px);
    color: var(--Gray-I);
    font-size: ac(28px, 22px);
    font-weight: 700;
    line-height: ac(36px, 24px);
    letter-spacing: -0.07px;
  }

  &__form {
    flex-direction: column;
    align-items: flex-start;
    gap: ac(40px, 20px);

    .choices__list {
      max-height: 126px;
    }
  }

  &__accordion-box {
    width: 100%;

    &.is-active {
      .job-list__head {
        span {
          background-color: var(--white);

          &::after {
            height: 0;
          }

          &::before,
          &::after {
            background-color: var(--Gray-II);
          }
        }
      }
      .job-list__descr {
        max-height: 400px;
        visibility: visible;
      }
    }
  }

  &__item {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__name {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: ac(24px, 10px);
  }

  &__name,
  &__head {
    position: relative;
    width: 100%;

    color: var(--Gray-I);
    font-size: 18px;
    line-height: 40px;
    font-weight: 700;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--Gray-III);
    }
  }

  &__name-switch {
    display: flex;
    align-items: center;
    gap: 5px;

    cursor: pointer;

    &-indicator {
      position: relative;

      display: inline-block;

      width: 34px;
      height: 17px;

      border: 1px solid var(--Gray-I);
      border-radius: 999px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        translate: 0 -50%;

        width: 17px;
        height: 17px;

        border-radius: 999px;

        background: var(--Gray-I);

        transition: translate ease 250ms;
      }
    }

    &-text {
      font-size: 12px;
    }

    input:checked + .job-list__name-switch-indicator {
      &::before {
        translate: 100% -50%;
      }
    }
  }

  &__item-variant {
    display: flex;
    justify-content: space-between;

    width: 100%;

    &[data-checked] {
      display: none;
    }
  }

  &__select {
    width: calc(50% - 6px);
  }

  &__head {
    cursor: pointer;
    transition: 0.3s ease;
    user-select: none;
    justify-content: space-between;

    span {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: 1px solid var(--Gray-III);
      margin-left: 9px;
      flex-shrink: 0;

      &::before,
      &::after {
        content: '';
        position: absolute;
        background-color: var(--Gray-II);
        transition: 0.3s ease;
      }

      &::before {
        width: 16px;
        height: 2px;
      }

      &::after {
        height: 16px;
        width: 2px;
      }
    }

    &:hover {
      color: var(--forest);
    }
  }

  &__descr {
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: 0.3s ease;
  }

  &__checkbox {
    cursor: pointer;

    input:checked + {
      .job-list__check {
        &::after {
          opacity: 1;
        }
      }
    }

    &:hover {
      .job-list__check {
        border-color: var(--forest);
      }
    }
  }

  &__checkbox-box {
    padding-top: ac(24px, 10px);
    gap: 12px;
  }

  &__check {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 3px;
    border: 2px solid #92afa3;
    transition: 0.3s ease;
    flex-shrink: 0;

    &::after {
      content: '';
      width: 12px;
      height: 10px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='10' fill='none'%3E%3Cpath fill='%23C3D600' d='M10.084.834a1.031 1.031 0 0 1 1.472 1.444L6.067 9.139a1.031 1.031 0 0 1-1.485.028L.945 5.528a1.031 1.031 0 1 1 1.458-1.457l2.88 2.878L10.056.864a.334.334 0 0 1 .028-.03h-.001Z'/%3E%3C/svg%3E")
        center / contain no-repeat;
      opacity: 0;
    }
  }

  &__label {
    font-size: 16px;
    line-height: 24px;
    transition: 0.3s ease;
  }

  &__layout {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: var(--white);
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease;
    z-index: 11;

    &.active {
      opacity: 0.3;
      visibility: visible;
      cursor: pointer;
    }
  }

  &__burger-box {
    position: relative;

    margin-left: auto;
    align-items: center;
    font-size: 20px;
    gap: 10px;
    cursor: pointer;
    transition: 0.3s ease;
    z-index: 1;

    height: 35px;

    margin-top: 60px;

    &:hover {
      color: var(--forest);

      .job-list__burger {
        border-color: var(--forest);

        span {
          background-color: var(--forest);
        }
      }
    }
  }

  &__burger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    width: 34px;
    height: 34px;
    background: transparent;
    border-radius: 100%;
    border: 1px solid var(--black);
    transition: 0.3s ease;

    span {
      pointer-events: none;
      width: 17px;
      height: 2px;
      background-color: var(--black);
      transition: 0.3s ease;

      &:nth-child(2) {
        width: 13px;
        margin: 3px 0;
      }
      &:nth-child(3) {
        width: 7px;
      }
    }
  }
  .pagination {
    margin: ac(49px, 30px) auto 0;
  }

  @mixin lg {
    &__col {
      flex-shrink: 0;
      max-width: ac(912px, 700px, 1024);
    }
    &__burger-box {
      display: none;
    }

    .simplebar-content-wrapper,
    .simplebar-mask {
      overflow: visible !important;
    }
  }

  @mixin max-lg {
    &__filter-box {
      position: fixed;
      top: 0px;
      left: 0;

      margin-top: 0;
      padding: 20px;
      width: 100%;
      max-width: 400px;

      background: var(--Blue-III);
      transform: translateX(-100%);
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease;
      z-index: 12;

      height: calc((var(--vh) * 100) - 0px);

      .simplebar-track {
        background-color: rgb(8, 2, 35, 0.2) !important;
      }

      .simplebar-scrollbar::before {
        background-color: var(--forest) !important;
      }

      &.active {
        transform: translateX(0%);
        opacity: 1;
        visibility: visible;

        .job-list__burger {
          position: absolute;
          top: 0px;
          right: 0px;
          width: 35px;
          height: 35px;
          span {
            &:nth-child(2) {
              width: 0;
              opacity: 0;
            }

            &:nth-child(1) {
              width: 19px;
              transform: rotate(45deg) translate(3px, 3px);
            }

            &:nth-child(3) {
              width: 19px;
              transform: rotate(-45deg) translate(4px, -4px);
            }
          }
        }
      }
    }
  }
}
