.details {
  margin-top: ac(97px, var(--mmt));

  & + .start {
    margin-top: ac(97px, var(--mmt));
    padding-top: ac(97px, var(--mmt));
  }

  &__wrapper {
    gap: ac(56px, 30px);
  }

  &--article {
    margin-top: ac(108px, var(--mmt));

    .details {
      &__wrapper {
        gap: ac(96px, 30px);
      }

      &__row {
        gap: ac(24px, 15px);
      }

      &__name {
        font-size: ac(22px, 18px);
        font-weight: 700;
        line-height: ac(32px, 24px);
      }

      &__position {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }

      &__sidebar {
        padding-top: ac(96px, 0px);
      }

      &__bottom {
        margin-top: ac(39px, 25px);
      }

      &__sticky {
        padding-left: ac(47px, 10px);
        gap: ac(38px, 20px);

        &::before {
          content: '';
          position: absolute;
          top: -96px;
          left: -1px;
          width: 1px;
          height: calc(100% + 396px);
          background-color: var(--Gray-III);
        }
      }

      &__col-title {
        margin-bottom: ac(30px, 15px);
      }

      &__link {
        &:not(:last-child) {
          margin-bottom: ac(20px, 10px);
        }
      }

      &__author-box {
        width: 100%;
        padding-top: ac(32px, 15px);
        border-top: 1px solid var(--Gray-III);

        .details__col-title {
          margin-bottom: ac(40px, 20px);
          color: var(--Gray-I);
        }
      }

      &__name-box {
        gap: 12px;
      }

      &__btn {
        margin-top: ac(23px, 15px);
        max-width: 100%;
        padding-left: 12px;
        width: 100%;
        display: flex;
        align-items: center;
        font-weight: 700;
        padding-right: 35px;
        transition: 0.3s ease;
        cursor: pointer;

        &::after,
        &::before {
          content: '';
          position: absolute;
          transition: 0.3s ease;
        }

        &::after {
          right: ac(9px, 11px);
          width: 10px;
          height: 14px;
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='14' fill='none'%3E%3Cpath fill='%23255F47' d='m2.317 14 7-7-7-7L.684 1.633 6.05 7 .684 12.367 2.317 14Z'/%3E%3C/svg%3E")
            center / contain no-repeat;
          transition: 0.3s ease;
        }

        &::before {
          right: 0;
          bottom: ac(-12px, -7px);
          width: 100%;
          max-width: 100%;
          height: 1px;
          background-color: var(--forest);
        }
        &:hover {
          color: var(--forest);
        }
      }

      &__share-box,
      &__btn-box,
      &__video {
        margin-top: ac(40px, 20px);
      }
      &__video {
        width: 100%;
        .plyr__control.plyr__control--overlaid {
          &::before {
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='96' height='96' fill='none'%3E%3Cpath fill='%23F2DBE0' d='M0 0h96v96H0V0Z'/%3E%3Cpath fill='%23780F30' d='M67.444 48 33 68V28l34.444 20Z'/%3E%3C/svg%3E")
              center / contain no-repeat;
          }
          &::after {
            background-color: var(--Salmon-I);
          }

          &:hover {
            &::before {
              background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='96' height='96' fill='none'%3E%3Cpath fill='%23780F30' d='M0 0h96v96H0V0Z'/%3E%3Cpath fill='%23F6FDE9' d='M67.444 48 33 68V28l34.444 20Z'/%3E%3C/svg%3E")
                center / contain no-repeat !important;
            }
          }
        }
      }
      &__text {
        h2 {
          font-size: ac(48px, 32px);
          line-height: ac(64px, 36px);
          letter-spacing: -0.24px;
        }

        h3 {
          &:not(:first-child) {
            margin-top: ac(47px, 20px);
          }
          &:not(:last-child) {
            margin-bottom: ac(24px, 10px);
          }
        }

        p {
          &:not(:last-child) {
            margin-bottom: ac(16px, 10px);
          }

          &:first-of-type,
          &:first-of-type + p {
            margin-bottom: ac(19px, 15px);

            color: var(--black);
            font-size: ac(24px, 18px);
            font-weight: 400;
            line-height: ac(36px, 24px);
          }
        }

        ul {
          & > li {
            &::before {
              background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23C3D600' d='m7.888 9.139 8.224 5.327v-5.14L7.888 4V9.14ZM7.888 14.903V20l8.06-5.18-3.965-2.569-4.095 2.652Z'/%3E%3C/svg%3E")
                center / contain no-repeat;
            }
          }
        }
      }
    }
  }

  &__title {
    width: 100%;
    text-align: center;
    color: var(--Gray-I);
    font-size: ac(36px, 22px);
    font-weight: 700;
    line-height: ac(48px, 28px);
    letter-spacing: -0.09px;
  }

  &__subtitle {
    margin-bottom: ac(24px, 15px);
    font-size: ac(20px, 18px);
    line-height: ac(32px, 26px);
  }

  &__text {
    font-family: var(--font-main) !important;
    color: var(--black);
    font-size: ac(18px, 16px);
    font-weight: 400;
    line-height: ac(28px, 20px);

    span {
      font: var(--font-main) 400 ac(18px, 16px) / ac(28px, 20px) var(--black) !important;

      span {
        font: var(--font-main) 400 ac(18px, 16px) / ac(28px, 20px) var(--black) !important;
        span {
          font: var(--font-main) 400 ac(18px, 16px) / ac(28px, 20px)
            var(--black) !important;
          span {
            font: var(--font-main) 400 ac(18px, 16px) / ac(28px, 20px)
              var(--black) !important;
            span {
              font: var(--font-main) 400 ac(18px, 16px) / ac(28px, 20px)
                var(--black) !important;
              span {
                font: var(--font-main) 400 ac(18px, 16px) / ac(28px, 20px)
                  var(--black) !important;
                span {
                  font: var(--font-main) 400 ac(18px, 16px) / ac(28px, 20px)
                    var(--black) !important;
                  span {
                    font: var(--font-main) 400 ac(18px, 16px) / ac(28px, 20px)
                      var(--black) !important;
                    span {
                      font: var(--font-main) 400 ac(18px, 16px) / ac(28px, 20px)
                        var(--black) !important;
                      span {
                        font: var(--font-main) 400 ac(18px, 16px) /
                          ac(28px, 20px) var(--black) !important;
                        span {
                          font: var(--font-main) 400 ac(18px, 16px) /
                            ac(28px, 20px) var(--black) !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    iframe {
      width: 100%;
      max-width: 100%;
    }

    img {
      margin-top: ac(39px, 20px);
      position: relative;
      border-radius: 0;
      overflow: hidden;
      /*filter: brightness(0.8);*/
      height: auto;
      width: auto;
    }

    h1 {
      font-size: ac(66px, 36px);
      line-height: ac(78px, 42px);
      margin-top: 0.8em;
      margin-bottom: 0.4em;
      font-weight: 700;
      color: var(--forest);
    }

    h2 {
      &:not(:first-child) {
        margin-top: ac(40px, 25px);
      }
      &:not(:last-child) {
        margin-bottom: ac(24px, 20px);
      }

      color: var(--forest);
      font-size: ac(56px, 32px);
      font-weight: 700;
      line-height: ac(68px, 36px);
      letter-spacing: -0.448px;
    }

    h3 {
      &:not(:first-child) {
        margin-top: ac(40px, 20px);
      }
      &:not(:last-child) {
        margin-bottom: ac(15px, 10px);
      }
      color: var(--forest);
      font-size: ac(28px, 22px);
      line-height: ac(36px, 26px);
      font-weight: 700;
      letter-spacing: -0.07px;
    }

    h4,
    h5,
    h6 {
      font-weight: 700;
      color: var(--forest);
      &:not(:first-child) {
        margin-top: ac(30px, 20px);
      }
      &:not(:last-child) {
        margin-bottom: ac(20px, 15px);
      }
    }

    h4 {
      font-size: ac(26px, 21px);
      line-height: ac(34px, 32px);
    }

    h5 {
      font-size: ac(24px, 20px);
      line-height: ac(32px, 30px);
    }

    h6 {
      color: var(--forest);
      font-size: ac(22px, 19px);
      font-weight: 700;
      line-height: ac(32px, 24px);
    }

    & > ul,
    & > ol {
      &:not(:last-child) {
        margin-bottom: ac(40px, 20px);
      }
    }

    p {
      &:not(:last-child) {
        margin-bottom: ac(16px, 10px);
      }

      &:first-of-type {
        margin-bottom: ac(24px, 15px);
        color: var(--black);
        font-size: ac(24px, 18px);
        font-weight: 400;
        line-height: ac(36px, 24px);
      }
    }

    p,
    li {
      color: var(--black);
      font-size: ac(18px, 16px);
      font-weight: 400;
      line-height: ac(28px, 20px);

      p {
        &:first-of-type,
        &:first-of-type + p {
          margin-bottom: 0;
          font-size: ac(18px, 16px);
          font-weight: 400;
          line-height: ac(28px, 20px);
        }
      }

      a {
        display: inline-block;
        color: var(--forest);
        word-break: break-word;
        font-weight: 400;
        transition: 0.3s ease;

        &:hover {
          color: var(--maroon);
          text-decoration: none;
        }
      }
    }

    ul,
    ol {
      &:not(:first-child) {
        margin-top: ac(24px, 15px);
      }
    }

    ul {
      & > li {
        position: relative;
        padding-left: ac(36px, 20px);

        &:not(:last-child) {
          margin-bottom: ac(12px, 10px);
        }

        &::before {
          content: '';
          position: absolute;
          top: ac(2px, -2px);
          left: 0;
          width: 24px;
          height: 24px;
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%2375767A' d='m7.888 9.139 8.224 5.327v-5.14L7.888 4V9.14ZM7.888 14.903V20l8.06-5.18-3.965-2.569-4.095 2.652Z'/%3E%3C/svg%3E")
            center / contain no-repeat;
        }
      }
    }

    ol {
      list-style-type: auto;
      list-style-position: inside;
    }
  }

  &__btn-box {
    justify-content: center;
    margin-top: ac(64px, 30px);

    .btn.hero__btn.btn--no-bg {
      border: 1px solid var(--forest);
      color: var(--forest);
      background-color: var(--white);

      .btn__icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23255f47' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
      }

      &:hover {
        background-color: var(--forest);
        color: var(--white);

        .btn__icon {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23FFFFFF' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
        }
      }
    }

    @mixin max-sm {
      justify-content: flex-start;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
    }
  }

  &__bottom {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: ac(56px, 25px);
    gap: 20px;
  }

  &__share {
    color: var(--Gray-I);
    font-size: ac(22px, 18px);
    font-weight: 700;
    line-height: ac(32px, 24px);
  }

  &__sticky {
    gap: ac(32px, 15px);
  }

  &__col-title {
    color: var(--forest);
    font-size: ac(28px, 22px);
    font-weight: 700;
    line-height: ac(36px, 26px);
    letter-spacing: -0.07px;
  }

  &__nav {
    overflow: hidden;
  }

  &__link {
    display: inline-flex;
    align-items: center;
    position: relative;
    color: var(--Gray-I);
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    transition: 0.3s ease;

    &::before {
      content: '';
      margin-left: -32px;
      margin-right: 8px;
      width: 24px;
      height: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%2375767A' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
      opacity: 0;
      flex-shrink: 0;
      transition: 0.3s ease;
    }

    &:hover {
      color: var(--black);
    }

    &.is-active {
      color: var(--black);
      &::before {
        margin-left: 0;
        opacity: 1;
      }
    }
  }

  @mixin lg {
    &--article {
      .details__text-box {
        max-width: ac(796px, 450px);
      }
    }
    &__text-box {
      width: 100%;
      max-width: ac(776px, 450px);
      flex-shrink: 0;
    }
  }

  @mixin max-lg {
    &__sticky {
      max-width: 300px;
      margin: 0 auto;
      flex-direction: column;
    }

    &--article {
      .details__sticky {
        padding: 0;
        max-width: unset;
        flex-wrap: wrap;
        flex-direction: row;
      }

      .details__nav {
        flex: 1 0 ac(400px, 200px, 320, 1024);
      }

      .details__author-box {
        padding: 0;
        border: 0;
        flex: 1 0 ac(400px, 200px, 320, 1024);
      }
    }
  }

  @mixin max-md {
    &__text {
      ul {
        & > li {
          padding-left: 15px;

          &::before {
            top: 0px;
            left: -4px;
            transform: translateY(0%);
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }

  @mixin max-sm {
    &--article {
      .details {
        &__video {
          .plyr__control.plyr__control--overlaid {
            &::before {
              background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='46' height='46' fill='none'%3E%3Cpath fill='%23F2DBE0' d='M0 0h46v46H0V0Z'/%3E%3Cpath fill='%23780F30' d='M32 23 16 33V13l16 10Z'/%3E%3C/svg%3E")
                center / contain no-repeat;
            }
            &::after {
              background-color: var(--Salmon-I);
            }

            &:hover {
              &::before {
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='46' height='46' fill='none'%3E%3Cpath fill='%23780F30' d='M0 0h46v46H0V0Z'/%3E%3Cpath fill='%23F6FDE9' d='M32 23 16 33V13l16 10Z'/%3E%3C/svg%3E")
                  center / contain no-repeat !important;
              }
            }
          }
        }
      }
    }

    &__sticky {
      width: 100%;
      max-width: 450px;
    }
  }
}
