.competition {
  margin-top: ac(96px, var(--mmt));
  padding: ac(107px, var(--mmt)) 0 ac(106px, var(--mmt));
  background-color: var(--Salmon-III);

  &__wrapper {
    gap: ac(44px, 20px);
  }

  &__img-box {
    gap: ac(24px, 15px);
  }

  &__img {
    aspect-ratio: 0.69 / 1;

    border: 1px solid var(--Gray-III);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12),
      0px 0px 2px 0px rgba(0, 0, 0, 0.16);
  }

  &__title {
    margin-bottom: ac(23px, 15px);

    span {
      color: var(--maroon);
    }
  }

  &__scroll {
    max-height: calc((24px * 12) + ac(12px, 8px));

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__descr {
    padding-right: 10px;

    &,
    & > * {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      &:not(:last-child) {
        margin-bottom: ac(12px, 8px);
      }
    }
  }
  @mixin md {
    &__img-box {
      max-width: ac(624px, 300px);
    }
  }

  @mixin max-md {
    &__img-box {
      margin: 0 auto;
      max-width: 450px;
    }
  }

  @mixin max-sm {
    &__img {
      height: ac(313px, 198px, 320, 640);
    }
  }
}
