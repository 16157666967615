.popup-team {
  position: relative;
  max-width: 962px;
  max-height: 85dvh;
  padding: 0;
  border: 1px solid var(--Gray-III);

  & > .simplebar-track.simplebar-vertical {
    top: 55px;
    .simplebar-scrollbar::before {
      opacity: 0.3 !important;
      background-color: var(--black);
    }
  }

  &__wrapper {
    display: flex;
  }

  &__title {
    max-width: 380px;
    line-height: ac(42px, 30px);
    font-size: ac(30px, 22px);
    font-weight: 600;
    margin-bottom: ac(20px, 15px);
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -1px;
    right: -1px;
    width: 56px;
    height: 56px;
    border: 1px solid var(--forest);
    background-color: var(--forest);
    transition: 0.3s ease;
    cursor: pointer;
    z-index: 5;

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23C3D600' d='M1.439 16.547 2.302 20l7.77-7.77 6.475 6.475L20 17.842l-7.77-7.77 6.62-6.619L17.985 0l-7.914 7.914-6.619-6.619L0 2.158l7.914 7.914-6.475 6.475Z'/%3E%3C/svg%3E");
      cursor: pointer;
      transition: 0.3s ease;
    }

    &:hover {
      border-color: var(--forest);
      background-color: var(--Lime-III);

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23255f47' d='M1.439 16.547 2.302 20l7.77-7.77 6.475 6.475L20 17.842l-7.77-7.77 6.62-6.619L17.985 0l-7.914 7.914-6.619-6.619L0 2.158l7.914 7.914-6.475 6.475Z'/%3E%3C/svg%3E");
      }
    }
  }

  &__card {
    max-width: 280px;
    width: 100%;

    @media (max-width: 491px) {
      max-width: 100%;
    }
  }

  .team__card {
    width: 100%;
    max-width: ac(392px, 240px);
    flex-shrink: 0;
    border: 0;
  }

  &__text-box {
    border-left: 1px solid var(--Gray-III);
    width: 100%;
    padding: 38px 0 0;
    overflow: hidden;

    .details__text {
      padding-right: ac(58px, 40px);
      padding-left: ac(38px, 20px);

      p:first-of-type {
        margin-bottom: ac(16px, 10px);

        font-size: 16px;
        line-height: 24px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
      }

      h6 {
        &:not(:first-child) {
          margin-top: ac(24px, 20px);
        }
        &:not(:last-child) {
          margin-bottom: ac(16px, 15px);
        }
      }
    }
  }

  &__scroll {
    position: relative;
    height: 100%;
    width: 100%;

    &.is-bottom {
      &::before {
        opacity: 0;
      }
    }

    .simplebar-horizontal {
      display: none;
    }

    .simplebar-track.simplebar-vertical {
      width: ac(18px, 10px);
      right: ac(19px, 10px);
      top: 30px;
      border: 1px solid var(--Lime-II);
      background: var(--Lime-III);
      border-radius: 0;

      &:nth-child(2 of .simplebar-vertical) {
        display: none;
      }
    }

    .simplebar-scrollbar::before,
    .simplebar-scrollbar {
      border: ac(4px, 1px) solid var(--Lime-III);
      opacity: 0.3 !important;
      background-color: var(--black);
      border-radius: 0 !important;
    }
  }

  &__btn-box {
    margin-top: 32px;
    padding: ac(20px, 10px) ac(40px, 15px);
    border-top: 1px solid var(--Gray-III);
    background: var(--Lime-III);
  }

  @mixin md {
    &__scroll {
      max-height: ac(432px, 300px);
      //max-height: calc(100vh - 100px);

      &::before {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 75px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.8) 43.02%,
          #fff 72.71%
        );
        z-index: 1;
        pointer-events: none;
        transition: 0.3s ease;
      }
    }
  }

  @mixin max-md {
    max-width: 500px;

    &__wrapper {
      flex-direction: column;
    }

    &__text-box {
      padding-top: 20px;

      .simplebar-track.simplebar-vertical {
        top: 0;
      }
    }

    .team__card {
      max-width: 350px;
      margin: ac(10px, 0px, 385, 414) auto 0;
      border: 1px solid var(--Gray-III);
    }
  }

  @mixin max-sm {
    &__text-box {
      .btn {
        width: 100%;
      }
    }
  }
}
