.about {
  margin-top: ac(160px, var(--mmt));

  &--sectors {
    margin-top: ac(171px, var(--mmt));
  }

  &__content {
    display: flex;
    gap: ac(46px, 40px);

    &:not(:first-child) {
      border-top: 1px solid rgba(227, 231, 186, 0.3);
      padding-top: ac(64px, 32px);
      margin-top: ac(64px, 32px);
    }

    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }

  &__title {
    gap: ac(26px, 16px);
    display: flex;
    align-items: center;
    font-size: ac(36px, 24px);
    line-height: ac(48px, 32px);
    font-weight: 700;
    color: var(--white);
  }

  &__simplebar {
    margin-top: ac(32px, 12px);
    max-height: calc(ac(32px, 24px) * 10);
  }

  &__text-box {
    max-width: 100%;
  }

  &__descr {
    padding-right: 10px;

    &,
    & > * {
      font-size: ac(22px, 16px);
      line-height: ac(32px, 24px);
      color: var(--white);
    }
  }

  &__btn {
    margin-top: ac(48px, 24px);

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  &__img {
    width: 100%;
    height: ac(336px, 180px);
  }

  @mixin md {
    &__text-box {
      flex-shrink: 0;
      max-width: ac(591px, 400px);
      width: 100%;
    }

    &__descr {
      &,
      & > * {
        font-weight: 300;
      }
    }
  }

  @mixin max-md {
    &__title {
      letter-spacing: -0.06px;
    }

    &__img {
      width: 100%;
      height: ac(336px, 196px, 375, 768);
    }

    &__content {
      &,
      &:nth-child(even) {
        flex-direction: column;
      }
    }

    /*&__text-box {
      width: calc(100% + 10px);
    }*/
  }

  @mixin max-xs {
    &__title {
      gap: 5px;
    }
  }
}
