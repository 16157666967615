.workplace {
  margin-top: ac(108px, var(--mmt));
  & + .news {
    margin-top: ac(96px, var(--mmt));
  }
  &__title-box {
    width: 100%;
    gap: ac(56px, 15px);
  }

  &__title {
    width: 100%;
  }

  &__line {
    background-color: var(--Gray-III);
  }

  &__descr {
    margin-top: ac(64px, 20px);
    column-gap: ac(48px, 20px);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    & > * {
      &:not(:first-child) {
        margin-top: ac(12px, 8px);
      }
    }
  }

  @mixin md {
    &__title {
      max-width: ac(584px, 360px);
    }
  }
}
