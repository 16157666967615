.director {
  padding: ac(96px, var(--mmt)) 0;
  background-color: var(--Blue-III);

  &--new-york {
    background-color: var(--Lime-III);

    .plyr__control.plyr__control--overlaid {
      &::before {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='96' height='96' fill='none'%3E%3Cpath fill='%23F6FDE9' d='M0 0h96v96H0V0Z'/%3E%3Cpath fill='%23255F47' d='M67.444 48 33 68V28l34.444 20Z'/%3E%3C/svg%3E")
          center / contain no-repeat;
      }
      &::after {
        background-color: var(--Lime-I);
      }

      &:hover {
        &::before {
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='96' height='96' fill='none'%3E%3Cpath fill='%23255F47' d='M0 0h96v96H0V0Z'/%3E%3Cpath fill='%23F6FDE9' d='M67.444 48 33 68V28l34.444 20Z'/%3E%3C/svg%3E")
            center / contain no-repeat !important;
        }
      }
    }

    .director {
      &__scroll {
        &::before {
          background: linear-gradient(
            180deg,
            rgba(246, 253, 233, 0) 0%,
            #f6fde9 100%
          );
        }

        .simplebar-scrollbar::before {
          background-color: rgba(195, 214, 0, 0.3);
        }
      }
      &__title {
        svg {
          path {
            fill: var(--Lime-I);
          }
        }
      }

      &__img {
        border-color: var(--forest);
      }

      &__name {
        color: var(--forest);
      }
    }
  }

  &--galway {
    background-color: var(--Salmon-III);

    .plyr__control.plyr__control--overlaid {
      &::before {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='96' height='96' fill='none'%3E%3Cpath fill='%23FFF1F2' d='M0 0h96v96H0V0Z'/%3E%3Cpath fill='%23780F30' d='M67.444 48 33 68V28l34.444 20Z'/%3E%3C/svg%3E")
          center / contain no-repeat;
      }
      &::after {
        background-color: var(--Salmon-I);
      }

      &:hover {
        &::before {
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='96' height='96' fill='none'%3E%3Cpath fill='%23780F30' d='M0 0h96v96H0V0Z'/%3E%3Cpath fill='%23F6FDE9' d='M67.444 48 33 68V28l34.444 20Z'/%3E%3C/svg%3E")
            center / contain no-repeat !important;
        }
      }
    }

    .director {
      &__scroll {
        &::before {
          background: linear-gradient(
            180deg,
            rgba(255, 241, 242, 0) 0%,
            #fff1f2 100%
          );
        }

        .simplebar-scrollbar::before {
          background-color: rgba(237, 107, 133, 0.3);
        }
      }
      &__title {
        svg {
          path {
            fill: var(--Salmon-I);
          }
        }
      }

      &__img {
        border-color: var(--Salmon-I);
      }

      &__name {
        color: var(--maroon);
      }
    }
  }

  &__wrapper {
    gap: ac(56px, 25px);
  }

  &__video {
    max-width: 280px;
  }

  &__col {
    padding-right: ac(16px, 0px);
    overflow: hidden;
  }

  &__head {
    margin-top: 12px;
    gap: ac(20px, 10px);
  }

  &__name-box {
    gap: ac(9px, 5px);
  }

  &__scroll {
    position: relative;
    margin-top: ac(28px, 15px);
    width: 100%;
    max-height: 380px;

    &.is-bottom {
      &::before {
        opacity: 0;
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 75px;
      background: linear-gradient(
        180deg,
        rgba(239, 246, 255, 0) 0%,
        #eff6ff 100%
      );
      z-index: 1;
      pointer-events: none;
      transition: 0.3s ease;
    }

    .simplebar-track.simplebar-vertical {
      width: ac(16px, 6px);
      right: ac(-16px, 0px);
      border: ac(4px, 1px) solid var(--white);
    }

    .simplebar-scrollbar::before {
      background-color: rgba(0, 125, 196, 0.3);
    }

    .simplebar-track {
      background-color: var(--white);
    }
  }

  &__img {
    flex-shrink: 0;
    border: 4px solid var(--Blue-I);
    background-color: var(--white);
  }

  &__name {
    color: var(--navy);

    font-weight: 700;
    font-size: ac(28px, 22px);
    line-height: ac(36px, 28px);
    letter-spacing: -0.07px;
  }

  &__position {
    color: var(--Gray-I);
    font-size: ac(22px, 18px);
    line-height: ac(32px, 24px);
    font-weight: 700;
  }

  &__descr {
    padding-right: ac(32px, 10px);

    &,
    & > * {
      font-size: 16px;
      line-height: 24px;

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  @mixin md {
    &__wrapper {
      align-items: flex-start;
    }
    &__video {
      flex-shrink: 0;
      max-width: ac(350px, 250px);
    }
  }

  @mixin max-md {
    &__wrapper {
      align-items: center;
    }
  }

  @mixin max-sm {
    &__head {
      flex-direction: column;
    }

    &__name,
    &__position {
      text-align: center;
    }

    &__title {
      justify-content: center;
    }
  }
}
