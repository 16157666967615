.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  z-index: 300;
  transition: 0.3s ease;

  &--wh {
    background-color: var(--white);

    .header__btn--desk {
      color: var(--forest);
      border: 1px solid var(--forest);

      .btn__icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23255F47' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
      }

      &:hover {
        background-color: var(--forest);
        color: var(--white);

        .btn__icon {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23FFFFFF' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
        }
      }
    }

    &.sticky {
      .header__logo {
        path#path108,
        path#path74,
        path#path96 {
          fill: var(--white);
        }

        path {
          fill: var(--white) !important;
        }

        &:hover {
          path#path108,
          path#path74,
          path#path96 {
            fill: var(--Lime-II);
          }

          path {
            fill: var(--Lime-II) !important;
          }
        }
      }
    }

    &:not(.sticky, .active) {
      .header__lang-box .choices .choices__list--single .choices__item {
        color: var(--black);
      }

      .header__lang-box .choices {
        &::before {
          border-color: var(--black);
        }
      }

      .choices .choices__list--single {
        .choices__item {
          &::before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath fill='%23000' d='M9 18a8.713 8.713 0 0 1-3.493-.71 9.128 9.128 0 0 1-2.865-1.932A9.128 9.128 0 0 1 .71 12.493 8.713 8.713 0 0 1 0 9c0-1.244.237-2.41.71-3.5a9.15 9.15 0 0 1 1.932-2.858A9.128 9.128 0 0 1 5.507.71 8.713 8.713 0 0 1 9 0c1.244 0 2.41.237 3.5.71a9.152 9.152 0 0 1 2.858 1.932 9.15 9.15 0 0 1 1.932 2.857C17.763 6.59 18 7.756 18 9a8.713 8.713 0 0 1-.71 3.493 9.128 9.128 0 0 1-1.932 2.865 9.152 9.152 0 0 1-2.857 1.932A8.697 8.697 0 0 1 9 18Zm0-1.441c.483-.641.89-1.288 1.22-1.94.331-.653.6-1.366.808-2.14H6.972c.22.799.492 1.524.816 2.176A11.11 11.11 0 0 0 9 16.56Zm-1.835-.26a10.277 10.277 0 0 1-.978-1.778 11.633 11.633 0 0 1-.674-2.041H2.299c.5.984 1.172 1.81 2.013 2.48a6.763 6.763 0 0 0 2.853 1.338Zm3.67 0a6.762 6.762 0 0 0 2.853-1.34 7.426 7.426 0 0 0 2.013-2.48h-3.214c-.19.704-.43 1.388-.72 2.051-.289.664-.6 1.253-.932 1.768Zm-9.132-5.24h3.522c-.06-.352-.103-.698-.13-1.036a12.992 12.992 0 0 1 0-2.046c.027-.338.07-.684.13-1.036H1.703A7.494 7.494 0 0 0 1.421 9a7.606 7.606 0 0 0 .282 2.059Zm4.943 0h4.708A11.882 11.882 0 0 0 11.524 9a11.884 11.884 0 0 0-.17-2.059H6.646A11.892 11.892 0 0 0 6.476 9a11.89 11.89 0 0 0 .17 2.059Zm6.129 0h3.522a7.605 7.605 0 0 0 .21-3.114 7.48 7.48 0 0 0-.21-1.004h-3.522c.06.353.102.698.13 1.036a12.996 12.996 0 0 1 0 2.046c-.027.338-.07.684-.13 1.036Zm-.288-5.539h3.214a7.408 7.408 0 0 0-2-2.48 6.977 6.977 0 0 0-2.866-1.348c.363.552.686 1.155.969 1.81.283.654.51 1.327.683 2.018Zm-5.515 0h4.056a12.171 12.171 0 0 0-.83-2.189A11.064 11.064 0 0 0 9 1.441a11.061 11.061 0 0 0-1.198 1.89c-.333.668-.61 1.397-.83 2.19Zm-4.673 0h3.214c.172-.69.4-1.364.683-2.018.283-.655.606-1.258.97-1.81-1.091.242-2.048.693-2.872 1.353A7.508 7.508 0 0 0 2.3 5.52Z'/%3E%3C/svg%3E");
          }
        }
      }

      .choices {
        &:hover {
          &::before {
            border-color: var(--Lime-II) !important;
          }
          .choices__list--single .choices__item {
            color: var(--Lime-II) !important;
          }
        }
      }

      &.header .js-dropdown > .header__link > span {
        border-color: var(--black);
      }

      .js-dropdown {
        &:hover {
          & > .header__link > span {
            border-color: var(--Lime-II);
          }
        }
      }

      .header__navbar {
        .header__link {
          color: var(--black);

          &:hover {
            color: var(--Lime-II);
          }
        }
      }

      .js-dropdown {
        & > .header__link {
          color: var(--black);

          &:hover {
            color: var(--Lime-II);
          }
        }
      }
    }
  }

  &--green {
    background-color: var(--forest);

    &:not(.sticky, .active) {
      .header__logo {
        path#path108,
        path#path74,
        path#path96 {
          fill: var(--white);
        }

        path {
          fill: var(--white);
        }

        &:hover {
          path#path108,
          path#path74,
          path#path96 {
            fill: var(--Lime-II);
          }

          path {
            fill: var(--Lime-II) !important;
          }
        }
      }

      .header__lang-box .choices .choices__list--single .choices__item {
        color: var(--white);
      }

      .header__lang-box .choices {
        &::before {
          border-color: var(--white);
        }
      }

      .choices .choices__list--single {
        .choices__item {
          &::before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath fill='%23fff' d='M9 18a8.713 8.713 0 0 1-3.493-.71 9.128 9.128 0 0 1-2.865-1.932A9.128 9.128 0 0 1 .71 12.493 8.713 8.713 0 0 1 0 9c0-1.244.237-2.41.71-3.5a9.15 9.15 0 0 1 1.932-2.858A9.128 9.128 0 0 1 5.507.71 8.713 8.713 0 0 1 9 0c1.244 0 2.41.237 3.5.71a9.152 9.152 0 0 1 2.858 1.932 9.15 9.15 0 0 1 1.932 2.857C17.763 6.59 18 7.756 18 9a8.713 8.713 0 0 1-.71 3.493 9.128 9.128 0 0 1-1.932 2.865 9.152 9.152 0 0 1-2.857 1.932A8.697 8.697 0 0 1 9 18Zm0-1.441c.483-.641.89-1.288 1.22-1.94.331-.653.6-1.366.808-2.14H6.972c.22.799.492 1.524.816 2.176A11.11 11.11 0 0 0 9 16.56Zm-1.835-.26a10.277 10.277 0 0 1-.978-1.778 11.633 11.633 0 0 1-.674-2.041H2.299c.5.984 1.172 1.81 2.013 2.48a6.763 6.763 0 0 0 2.853 1.338Zm3.67 0a6.762 6.762 0 0 0 2.853-1.34 7.426 7.426 0 0 0 2.013-2.48h-3.214c-.19.704-.43 1.388-.72 2.051-.289.664-.6 1.253-.932 1.768Zm-9.132-5.24h3.522c-.06-.352-.103-.698-.13-1.036a12.992 12.992 0 0 1 0-2.046c.027-.338.07-.684.13-1.036H1.703A7.494 7.494 0 0 0 1.421 9a7.606 7.606 0 0 0 .282 2.059Zm4.943 0h4.708A11.882 11.882 0 0 0 11.524 9a11.884 11.884 0 0 0-.17-2.059H6.646A11.892 11.892 0 0 0 6.476 9a11.89 11.89 0 0 0 .17 2.059Zm6.129 0h3.522a7.605 7.605 0 0 0 .21-3.114 7.48 7.48 0 0 0-.21-1.004h-3.522c.06.353.102.698.13 1.036a12.996 12.996 0 0 1 0 2.046c-.027.338-.07.684-.13 1.036Zm-.288-5.539h3.214a7.408 7.408 0 0 0-2-2.48 6.977 6.977 0 0 0-2.866-1.348c.363.552.686 1.155.969 1.81.283.654.51 1.327.683 2.018Zm-5.515 0h4.056a12.171 12.171 0 0 0-.83-2.189A11.064 11.064 0 0 0 9 1.441a11.061 11.061 0 0 0-1.198 1.89c-.333.668-.61 1.397-.83 2.19Zm-4.673 0h3.214c.172-.69.4-1.364.683-2.018.283-.655.606-1.258.97-1.81-1.091.242-2.048.693-2.872 1.353A7.508 7.508 0 0 0 2.3 5.52Z'/%3E%3C/svg%3E");
          }
        }
      }

      .choices {
        &:hover {
          &::before {
            border-color: var(--Lime-II) !important;
          }
          .choices__list--single .choices__item {
            color: var(--Lime-II) !important;
          }
        }
      }

      &.header .js-dropdown > .header__link > span {
        border-color: var(--white);
      }

      .js-dropdown {
        &:hover {
          & > .header__link > span {
            border-color: var(--Lime-II);
          }
        }
      }

      .header__navbar {
        & > .header__link {
          color: var(--white);

          &:hover {
            color: var(--Lime-II);
          }
        }
      }

      .js-dropdown {
        & > .header__link {
          color: var(--white);

          &:hover {
            color: var(--Lime-II);
          }
        }
      }

      .btn.btn--no-bg {
        color: var(--white);
        border: 1px solid var(--Lime-II);

        .btn__icon {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23fff' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
        }

        &:hover {
          color: var(--forest);
          background-color: var(--Lime-II);

          .btn__icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23255F47' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }

  &.sticky {
    background-color: var(--forest);

    .header {
      &__wrapper {
        padding: 5px 0;
      }

      &__logo {
        path {
          fill: var(--white) !important;
        }

        &:hover {
          path {
            fill: var(--Lime-II) !important;
          }
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    padding: ac(32px, 12px) 0;
    transition: 0.3s ease;
  }

  &__logo {
    @mixin aspect-ratio 205, 95;
    width: ac(157px, 112px);
    margin-right: auto;
    flex-shrink: 0;
    z-index: 3;

    path {
      transition: 0.3s ease;
    }

    &:hover {
      path#path108 {
        fill: var(--Lime-II);
      }

      .logo-text-first {
        path {
          fill: var(--Lime-II);
        }
      }

      .logo-text-second {
        path {
          fill: var(--Lime-II);
        }
      }
    }
  }

  &__link {
    position: relative;
    color: var(--white);
    transition: 0.3s ease;

    &:hover {
      color: var(--Lime-II);
    }
  }

  &__search {
    position: relative;
    display: inline-flex;
    align-items: center;
    top: 2px;
    width: 48px;
    height: 48px;
    z-index: 50;
    transition: 0.3s ease;

    input {
      position: absolute;
      top: -2px;
      right: 12px;
      width: 0;
      opacity: 0;
      padding: 0 40px 0 15px;
      @mixin transparent-bg-input var(--white);
      border: 1px solid var(--Lime-II);
      outline: none;
      transition: 0.3s ease;
      background-color: var(--forest);
      pointer-events: none;

      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      &,
      &::placeholder {
        font-family: var(--font-main);
        font-weight: 600;
        font-size: 16px;
        line-height: 46px;
        color: var(--white);
      }

      &::placeholder {
        opacity: 0.5;
      }
      &::-webkit-search-decoration {
        -webkit-appearance: none;
      }

      &:focus,
      &:valid {
        pointer-events: all;
        opacity: 1;
        width: ac(330px, 200px, 320, 568);
      }
    }

    button {
      position: absolute;
      top: 2px;
      right: 15px;
      width: 40px;
      height: 40px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none'%3E%3Cpath fill='%23fff' d='M30.94 29.27 36 34.33 34.33 36l-5.06-5.06a10.587 10.587 0 0 1-6.637 2.326C16.763 33.266 12 28.503 12 22.633 12 16.763 16.764 12 22.633 12c5.87 0 10.633 4.764 10.633 10.633 0 2.51-.87 4.817-2.326 6.636Zm-2.37-.877a8.243 8.243 0 0 0 2.334-5.76c0-4.57-3.701-8.27-8.27-8.27a8.268 8.268 0 0 0-8.271 8.27c0 4.57 3.7 8.27 8.27 8.27 2.24 0 4.27-.889 5.76-2.333l.177-.177Z'/%3E%3C/svg%3E");
      transition: 0.3s ease;

      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none'%3E%3Cpath fill='%23E3E7BA' d='M30.94 29.27 36 34.33 34.33 36l-5.06-5.06a10.587 10.587 0 0 1-6.637 2.326C16.763 33.266 12 28.503 12 22.633 12 16.763 16.764 12 22.633 12c5.87 0 10.633 4.764 10.633 10.633 0 2.51-.87 4.817-2.326 6.636Zm-2.37-.877a8.243 8.243 0 0 0 2.334-5.76c0-4.57-3.701-8.27-8.27-8.27a8.268 8.268 0 0 0-8.271 8.27c0 4.57 3.7 8.27 8.27 8.27 2.24 0 4.27-.889 5.76-2.333l.177-.177Z'/%3E%3C/svg%3E");
      }
    }
  }

  &__btn {
    &.btn--no-bg {
      line-height: 46px;

      border-width: 1px;

      &.btn--white-bg {
        background-color: var(--white);

        &:hover {
          background-color: var(--forest);
          border-color: var(--white);
        }
      }
    }
  }

  &__lang-box {
    width: 84px;
    margin-right: ac(20px, 17px);
    flex-shrink: 0;

    .choices .choices__list--single .choices__item {
      display: flex;
      align-items: center;
      color: var(--white);
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
    }

    .choices .choices__list--dropdown .choices__item {
      padding: 8px !important;
      font-size: 14px;
      font-weight: 700;
      color: var(--black) !important;

      &:first-child {
        display: flex;

        &.is-selected {
          display: none;
        }
      }
    }

    .choices {
      margin-bottom: 0;
      margin-right: 0;
      transition: 0.3s ease;

      &:hover {
        &::before {
          border-color: var(--Lime-II);
          background: none;
        }

        .choices__inner {
          border: 0 !important;
        }

        .choices__list--single {
          .choices__item {
            &::before {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath fill='%23e3e7ba' d='M9 18a8.713 8.713 0 0 1-3.493-.71 9.128 9.128 0 0 1-2.865-1.932A9.128 9.128 0 0 1 .71 12.493 8.713 8.713 0 0 1 0 9c0-1.244.237-2.41.71-3.5a9.15 9.15 0 0 1 1.932-2.858A9.128 9.128 0 0 1 5.507.71 8.713 8.713 0 0 1 9 0c1.244 0 2.41.237 3.5.71a9.152 9.152 0 0 1 2.858 1.932 9.15 9.15 0 0 1 1.932 2.857C17.763 6.59 18 7.756 18 9a8.713 8.713 0 0 1-.71 3.493 9.128 9.128 0 0 1-1.932 2.865 9.152 9.152 0 0 1-2.857 1.932A8.697 8.697 0 0 1 9 18Zm0-1.441c.483-.641.89-1.288 1.22-1.94.331-.653.6-1.366.808-2.14H6.972c.22.799.492 1.524.816 2.176A11.11 11.11 0 0 0 9 16.56Zm-1.835-.26a10.277 10.277 0 0 1-.978-1.778 11.633 11.633 0 0 1-.674-2.041H2.299c.5.984 1.172 1.81 2.013 2.48a6.763 6.763 0 0 0 2.853 1.338Zm3.67 0a6.762 6.762 0 0 0 2.853-1.34 7.426 7.426 0 0 0 2.013-2.48h-3.214c-.19.704-.43 1.388-.72 2.051-.289.664-.6 1.253-.932 1.768Zm-9.132-5.24h3.522c-.06-.352-.103-.698-.13-1.036a12.992 12.992 0 0 1 0-2.046c.027-.338.07-.684.13-1.036H1.703A7.494 7.494 0 0 0 1.421 9a7.606 7.606 0 0 0 .282 2.059Zm4.943 0h4.708A11.882 11.882 0 0 0 11.524 9a11.884 11.884 0 0 0-.17-2.059H6.646A11.892 11.892 0 0 0 6.476 9a11.89 11.89 0 0 0 .17 2.059Zm6.129 0h3.522a7.605 7.605 0 0 0 .21-3.114 7.48 7.48 0 0 0-.21-1.004h-3.522c.06.353.102.698.13 1.036a12.996 12.996 0 0 1 0 2.046c-.027.338-.07.684-.13 1.036Zm-.288-5.539h3.214a7.408 7.408 0 0 0-2-2.48 6.977 6.977 0 0 0-2.866-1.348c.363.552.686 1.155.969 1.81.283.654.51 1.327.683 2.018Zm-5.515 0h4.056a12.171 12.171 0 0 0-.83-2.189A11.064 11.064 0 0 0 9 1.441a11.061 11.061 0 0 0-1.198 1.89c-.333.668-.61 1.397-.83 2.19Zm-4.673 0h3.214c.172-.69.4-1.364.683-2.018.283-.655.606-1.258.97-1.81-1.091.242-2.048.693-2.872 1.353A7.508 7.508 0 0 0 2.3 5.52Z'/%3E%3C/svg%3E");
            }
          }
        }

        .choices__list--single .choices__item {
          color: var(--Lime-II);
        }
      }

      &::after {
        display: none;
      }

      &::before {
        content: '';
        position: absolute;
        right: 4px;
        top: 11px;
        width: 8px;
        height: 8px;
        background: none;
        border-bottom: 2px solid var(--white);
        border-right: 2px solid var(--white);
        transform: rotate(45deg);
        transition: 0.3s ease;
      }

      &.is-open {
        &::before {
          top: 14px;
          transform: rotate(-135deg);
        }
      }

      &__list--single {
        padding: 0 19px 0 0 !important;

        .choices__item {
          &::before {
            content: '';
            width: 18px;
            height: 18px;
            margin-right: 8px;
            flex-shrink: 0;
            margin-bottom: 2px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath fill='%23fff' d='M9 18a8.713 8.713 0 0 1-3.493-.71 9.128 9.128 0 0 1-2.865-1.932A9.128 9.128 0 0 1 .71 12.493 8.713 8.713 0 0 1 0 9c0-1.244.237-2.41.71-3.5a9.15 9.15 0 0 1 1.932-2.858A9.128 9.128 0 0 1 5.507.71 8.713 8.713 0 0 1 9 0c1.244 0 2.41.237 3.5.71a9.152 9.152 0 0 1 2.858 1.932 9.15 9.15 0 0 1 1.932 2.857C17.763 6.59 18 7.756 18 9a8.713 8.713 0 0 1-.71 3.493 9.128 9.128 0 0 1-1.932 2.865 9.152 9.152 0 0 1-2.857 1.932A8.697 8.697 0 0 1 9 18Zm0-1.441c.483-.641.89-1.288 1.22-1.94.331-.653.6-1.366.808-2.14H6.972c.22.799.492 1.524.816 2.176A11.11 11.11 0 0 0 9 16.56Zm-1.835-.26a10.277 10.277 0 0 1-.978-1.778 11.633 11.633 0 0 1-.674-2.041H2.299c.5.984 1.172 1.81 2.013 2.48a6.763 6.763 0 0 0 2.853 1.338Zm3.67 0a6.762 6.762 0 0 0 2.853-1.34 7.426 7.426 0 0 0 2.013-2.48h-3.214c-.19.704-.43 1.388-.72 2.051-.289.664-.6 1.253-.932 1.768Zm-9.132-5.24h3.522c-.06-.352-.103-.698-.13-1.036a12.992 12.992 0 0 1 0-2.046c.027-.338.07-.684.13-1.036H1.703A7.494 7.494 0 0 0 1.421 9a7.606 7.606 0 0 0 .282 2.059Zm4.943 0h4.708A11.882 11.882 0 0 0 11.524 9a11.884 11.884 0 0 0-.17-2.059H6.646A11.892 11.892 0 0 0 6.476 9a11.89 11.89 0 0 0 .17 2.059Zm6.129 0h3.522a7.605 7.605 0 0 0 .21-3.114 7.48 7.48 0 0 0-.21-1.004h-3.522c.06.353.102.698.13 1.036a12.996 12.996 0 0 1 0 2.046c-.027.338-.07.684-.13 1.036Zm-.288-5.539h3.214a7.408 7.408 0 0 0-2-2.48 6.977 6.977 0 0 0-2.866-1.348c.363.552.686 1.155.969 1.81.283.654.51 1.327.683 2.018Zm-5.515 0h4.056a12.171 12.171 0 0 0-.83-2.189A11.064 11.064 0 0 0 9 1.441a11.061 11.061 0 0 0-1.198 1.89c-.333.668-.61 1.397-.83 2.19Zm-4.673 0h3.214c.172-.69.4-1.364.683-2.018.283-.655.606-1.258.97-1.81-1.091.242-2.048.693-2.872 1.353A7.508 7.508 0 0 0 2.3 5.52Z'/%3E%3C/svg%3E");
            transition: 0.3s ease;
          }
        }
      }
    }

    .choices__inner {
      display: flex;
      align-items: center;
      background-color: transparent !important;
      padding: 0px !important;
      border: 0 !important;
      border-radius: 0 !important;
      min-height: 34px !important;
    }

    .choices__list--dropdown {
      right: 0;
      width: 100px !important;
      border: 1px solid var(--Lime-III) !important;
      border-radius: 0 !important;
      background-color: var(--white) !important;

      [data-value='uk'],
      [data-value='ire'],
      [data-value='us'],
      [data-value='eu'] {
        &::before {
          content: '';
          margin-right: 7px;
          width: 24px;
          height: 24px;
          flex-shrink: 0;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          transition: 0.3s ease;
        }
      }
      [data-value='ire'] {
        &::before {
          background-image: url(../../assets/images/irl.svg);
        }
      }

      [data-value='uk'] {
        &::before {
          background-image: url(../../assets/images/uk.svg);
        }
      }

      [data-value='us'] {
        &::before {
          background-image: url(../../assets/images/us.svg);
        }
      }

      [data-value='eu'] {
        &::before {
          background-image: url(../../assets/images/eu.svg);
        }
      }

      .choices__item {
        &:not(:last-child) {
          border-bottom: 1px solid var(--Gray-III);
        }
      }

      .choices__item--selectable {
        padding: 0px !important;
        display: flex;
        align-items: center;
        color: var(--black) !important;
        font-weight: 500;

        &::after {
          display: none;
        }

        &:hover,
        &.is-highlighted {
          background-color: var(--Lime-III) !important;
        }

        &.is-selected {
          display: none;
        }
      }
    }
  }

  .js-dropdown {
    position: relative;
    z-index: 2;

    &:last-child {
      .header__link {
        margin-right: 0;
      }
    }

    &.open {
      & > .header__link span {
        transform: translateY(1px) rotate(-135deg);
        border-color: var(--Lime-II);
      }

      .header__dropdown {
        max-height: 400px;
        opacity: 1;
        visibility: visible;
        overflow: hidden;
      }

      & > .header__link {
        color: var(--Lime-II);
      }
    }

    & > .header__link {
      cursor: pointer;
      position: relative;
      display: inline-flex;
      align-items: center;

      & > span {
        margin-left: 7px;
        height: 7px;
        width: 7px;
        transform: translateY(ac(-1px, -3px)) rotate(45deg);
        border-bottom: 2px solid var(--white);
        border-right: 2px solid var(--white);
        transition: 0.3s ease;
      }

      &:hover {
        & > span {
          border-color: var(--Lime-II);
        }
      }
    }
  }

  &__dropdown {
    position: absolute;
    left: 0px;
    bottom: -1px;
    transform: translateY(100%);
    min-width: 209px;
    max-width: calc(100vw - 15px);
    width: auto;
    max-height: 0px;
    transform-origin: 0 0;
    border: 1px solid var(--Lime-III);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.19);
    overflow: hidden;
    visibility: hidden;
    background-color: var(--white);
    transition: 0.5s ease-in-out;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .header__link {
      position: relative;
      width: 100%;
      padding: 9px 36px 9px 16px;
      margin-left: 0;
      margin-right: 0;
      white-space: nowrap;

      color: var(--Gray-I);
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      text-transform: uppercase;
      white-space: normal;

      &:after {
        content: '';
        position: absolute;
        right: ac(16px, 5px);
        width: 16px;
        top: 50%;
        transform: translate(100%, -50%);
        height: 12px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' fill='none'%3E%3Cpath fill='%23255F47' d='m6.055 12 1.543-1.579-3.32-3.319H15.95V4.898H4.28l3.319-3.319L6.055 0 .05 6l6.005 6Z'/%3E%3C/svg%3E");
        opacity: 0;
        transition: 0.3s ease;
      }

      &:before {
        display: none;
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--Gray-III);
      }

      &:hover {
        background-color: var(--Lime-III);
        color: var(--forest);

        &:after {
          transform: translate(0%, -50%);
          opacity: 1;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    &__layout,
    &__btn--mob,
    &__upload-btn {
      display: none !important;
    }

    &__navbar-box {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 100px);
    }

    &__simplebar {
      width: 100%;
      width: calc(100% - ac(200px, 180px, 1200));
      max-height: 48px;
      margin-left: auto;
      margin-right: ac(60px, 20px, 1024, 1440);

      .simplebar-content-wrapper,
      .simplebar-mask {
        overflow: visible !important;
      }

      .simplebar-track {
        display: none !important;
      }

      .simplebar-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    &__burger-box,
    &__soc-box {
      display: none;
    }

    &__navbar {
      position: relative;
    }

    &__link {
      flex-shrink: 0;
      font-size: 16px;
      line-height: 48px;
      font-weight: 700;
      text-transform: uppercase;

      &:not(:last-child) {
        &.header__link--btn {
          margin-right: ac(30px, 10px, 1024, 1440);
        }

        margin-right: ac(33px, 10px, 1024, 1440);
      }

      &.is-active {
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: var(--Lime-I);
        }
      }
    }
  }

  @media (max-width: 1199px) {
    &__layout {
      background-color: var(--white);
      opacity: 0;
      position: absolute;
      top: 80px;
      left: 0;
      width: 100vw;
      height: calc(100vh - 80px);
      visibility: hidden;
    }
    &,
    &.active.sticky {
      .header__wrapper {
        padding: 16px 0;
      }
    }
    &.active {
      background-color: var(--forest);
      .header__layout {
        opacity: 0.2;
        visibility: visible;
      }
      .header__logo {
        #path108 {
          fill: var(--white);
        }
      }

      .header__navbar-box {
        right: 0;
        opacity: 1;
        visibility: visible;
      }

      .header__simplebar {
        transform: translateX(0);
        opacity: 1;
      }
      .header__burger {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none'%3E%3Cpath stroke='%23fff' d='M.5.5h47v47H.5z'/%3E%3Cpath fill='%23fff' d='M15.439 30.547 16.302 34l7.77-7.77 6.475 6.475L34 31.842l-7.77-7.77 6.62-6.619L31.985 14l-7.914 7.914-6.619-6.619-3.453.863 7.914 7.914-6.475 6.475Z'/%3E%3C/svg%3E");
      }

      .header__lang-box {
        opacity: 1;
        visibility: visible;
      }
    }

    .js-dropdown {
      width: 100%;

      &.open {
        & > .header__link {
          color: var(--forest);
        }
        & > .header__link span {
          margin-top: 9px;
        }

        .header__dropdown {
          &::before {
            width: 100%;
          }
        }
      }

      & > .header__link {
        margin-top: 2.2vh;
        margin-right: 0px;

        & > span {
          display: none;
        }
      }
    }

    &__dropdown {
      position: relative;
      transform: none;
      left: 0;
      bottom: 8px;
      border: 0;
      background-color: unset;
      box-shadow: none;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0px;
        height: 1px;
        width: 0%;
        background-color: var(--Gray-III);
        transition: 0.3s ease;
      }

      .header__link {
        width: auto;
        border-bottom: 0 !important;
        padding: 0px;
        font-size: 18px;
        line-height: 32px;
        margin: 0 !important;
        color: var(--black);
        text-transform: capitalize;
        max-width: calc(100% - 80px);
        &::after {
          right: -25px;
        }

        &:hover {
          background-color: unset;
        }
      }

      & > div {
        padding-top: 10px;
        gap: 6px;
      }
    }

    &__lang-box {
      z-index: 3;
    }

    &__search {
      button {
        top: -2px;
        right: 8px;
        width: 48px;
        height: 48px;

        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none'%3E%3Cpath fill='%23fff' d='M30.94 29.27 36 34.33 34.33 36l-5.06-5.06a10.587 10.587 0 0 1-6.637 2.326C16.763 33.266 12 28.503 12 22.633 12 16.763 16.764 12 22.633 12c5.87 0 10.633 4.764 10.633 10.633 0 2.51-.87 4.817-2.326 6.636Zm-2.37-.877a8.243 8.243 0 0 0 2.334-5.76c0-4.57-3.701-8.27-8.27-8.27a8.268 8.268 0 0 0-8.271 8.27c0 4.57 3.7 8.27 8.27 8.27 2.24 0 4.27-.889 5.76-2.333l.177-.177Z'/%3E%3C/svg%3E");

        &:hover {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none'%3E%3Cpath fill='%23E3E7BA' d='M30.94 29.27 36 34.33 34.33 36l-5.06-5.06a10.587 10.587 0 0 1-6.637 2.326C16.763 33.266 12 28.503 12 22.633 12 16.763 16.764 12 22.633 12c5.87 0 10.633 4.764 10.633 10.633 0 2.51-.87 4.817-2.326 6.636Zm-2.37-.877a8.243 8.243 0 0 0 2.334-5.76c0-4.57-3.701-8.27-8.27-8.27a8.268 8.268 0 0 0-8.271 8.27c0 4.57 3.7 8.27 8.27 8.27 2.24 0 4.27-.889 5.76-2.333l.177-.177Z'/%3E%3C/svg%3E");
        }
      }
    }

    &__btn.header__btn--mob.btn--green {
      width: 100%;
      max-width: 380px;
      margin-top: auto;
    }

    &__btn--desk.btn--no-bg {
      display: none;
    }

    &__burger {
      width: 48px;
      height: 48px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none'%3E%3Cpath fill='%23EBF3F3' d='M0 0h48v48H0z'/%3E%3Cpath fill='%23255F47' d='M36.4 15.5h-23l-1.8 3h23l1.8-3ZM36.4 22.5h-23l-1.8 3h23l1.8-3ZM36.4 29.5h-23l-1.8 3h23l1.8-3Z'/%3E%3C/svg%3E");
      z-index: 2;
      cursor: pointer;
    }

    &__navbar-box {
      position: absolute;
      right: -100%;
      top: 80px;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-width: 400px;
      height: calc(100vh - 80px);
      height: calc(100dvh - 80px);
      padding: 32px 24px 24px;
      background-color: var(--white);
      border-top: 1px solid var(--Gray-III);

      transition: 0.3s ease;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease;
      z-index: 2;
    }

    &__simplebar {
      max-height: calc(100vh - 340px);
      max-height: calc(100svh - 340px);
      width: 100%;
      margin-bottom: 20px;

      .simplebar-track {
        background-color: rgba(37, 95, 71, 0.2);
      }

      .simplebar-scrollbar:before {
        background-color: var(--forest);
      }
    }

    &__navbar {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }

    &__link {
      font-size: 20px;
      line-height: 30px;
      font-weight: 700;
      color: var(--forest);
      margin: 2.2vh 0;
      text-transform: uppercase;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__soc-box {
      display: flex;
      margin-top: 24px;
      gap: 8px;
    }

    &__soc {
      width: 36px;
      height: 36px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      transition: 0.3s ease;
    }

    &__soc--inst {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='37' height='36' fill='none'%3E%3Cpath fill='%23255F47' d='M18.5 21.5a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z'/%3E%3Cpath fill='%23255F47' fill-rule='evenodd' d='M18.5 9.5c-2.444 0-2.75.01-3.71.054-3.269.15-5.085 1.963-5.235 5.235-.044.96-.055 1.267-.055 3.711s.01 2.751.054 3.711c.15 3.269 1.963 5.085 5.235 5.235.96.043 1.267.054 3.711.054s2.751-.01 3.711-.054c3.266-.15 5.087-1.963 5.234-5.235.044-.96.055-1.267.055-3.711s-.01-2.75-.054-3.71c-.147-3.266-1.963-5.085-5.234-5.235C21.25 9.51 20.944 9.5 18.5 9.5Zm0 4.379a4.622 4.622 0 1 0 0 9.243 4.622 4.622 0 0 0 0-9.243Zm-3 4.621a3 3 0 1 0 6 0 3 3 0 0 0-6 0Zm6.724-4.804a1.08 1.08 0 1 1 2.16.001 1.08 1.08 0 0 1-2.16 0Z' clip-rule='evenodd'/%3E%3C/svg%3E");

      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='37' height='36' fill='none'%3E%3Cpath fill='%23c3d600' d='M18.5 21.5a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z'/%3E%3Cpath fill='%23c3d600' fill-rule='evenodd' d='M18.5 9.5c-2.444 0-2.75.01-3.71.054-3.269.15-5.085 1.963-5.235 5.235-.044.96-.055 1.267-.055 3.711s.01 2.751.054 3.711c.15 3.269 1.963 5.085 5.235 5.235.96.043 1.267.054 3.711.054s2.751-.01 3.711-.054c3.266-.15 5.087-1.963 5.234-5.235.044-.96.055-1.267.055-3.711s-.01-2.75-.054-3.71c-.147-3.266-1.963-5.085-5.234-5.235C21.25 9.51 20.944 9.5 18.5 9.5Zm0 4.379a4.622 4.622 0 1 0 0 9.243 4.622 4.622 0 0 0 0-9.243Zm-3 4.621a3 3 0 1 0 6 0 3 3 0 0 0-6 0Zm6.724-4.804a1.08 1.08 0 1 1 2.16.001 1.08 1.08 0 0 1-2.16 0Z' clip-rule='evenodd'/%3E%3C/svg%3E");
      }
    }

    &__soc--in {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='37' height='36' fill='none'%3E%3Cpath fill='%23255F47' d='M13.868 10.348c0 1.02-.812 1.848-1.813 1.848-1.002 0-1.814-.827-1.814-1.848 0-1.02.812-1.848 1.814-1.848 1.001 0 1.813.828 1.813 1.848Zm.014 3.326h-3.655V25.5h3.655V13.674Zm5.837 0h-3.633V25.5h3.634v-6.208c0-3.452 4.408-3.734 4.408 0V25.5h3.647v-7.488c0-5.825-6.524-5.612-8.056-2.745v-1.593Z'/%3E%3C/svg%3E");

      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='37' height='36' fill='none'%3E%3Cpath fill='%23c3d600' d='M13.868 10.348c0 1.02-.812 1.848-1.813 1.848-1.002 0-1.814-.827-1.814-1.848 0-1.02.812-1.848 1.814-1.848 1.001 0 1.813.828 1.813 1.848Zm.014 3.326h-3.655V25.5h3.655V13.674Zm5.837 0h-3.633V25.5h3.634v-6.208c0-3.452 4.408-3.734 4.408 0V25.5h3.647v-7.488c0-5.825-6.524-5.612-8.056-2.745v-1.593Z'/%3E%3C/svg%3E");
      }
    }

    &__soc--x {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='37' height='36' fill='none'%3E%3Cpath fill='%23255F47' d='M16.473 18.877 9.453 9.5h5.41l4.602 6.147L25.162 9.5h1.59l-6.576 7.096L27.59 26.5h-5.41l-4.998-6.675-6.185 6.675h-1.59l7.065-7.623Z'/%3E%3C/svg%3E");

      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='37' height='36' fill='none'%3E%3Cpath fill='%23c3d600' d='M16.473 18.877 9.453 9.5h5.41l4.602 6.147L25.162 9.5h1.59l-6.576 7.096L27.59 26.5h-5.41l-4.998-6.675-6.185 6.675h-1.59l7.065-7.623Z'/%3E%3C/svg%3E");
      }
    }

    &__upload-btn {
      margin-top: 12px;
      width: 100%;
      max-width: 380px;
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      padding: 11px;
      height: 48px;
      border: 0;

      font-size: 18px;
      line-height: 25px;
      font-weight: 700;
      color: var(--forest);

      background-color: var(--white);
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23255f47' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='12' stroke-linecap='square'/%3e%3c/svg%3e");
      transition: 0.3s ease;

      &::after {
        content: '';
        margin-left: 8px;
        width: 24px;
        height: 24px;
        margin-left: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23255F47' d='M11 16V7.85l-2.6 2.6L7 9l5-5 5 5-1.4 1.45-2.6-2.6V16h-2Zm-5 4c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 4 18v-3h2v3h12v-3h2v3c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 18 20H6Z'/%3E%3C/svg%3E");
        transition: 0.3s ease;
      }

      &:hover {
        background-color: var(--forest);
        color: var(--white);

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23fff' d='M11 16V7.85l-2.6 2.6L7 9l5-5 5 5-1.4 1.45-2.6-2.6V16h-2Zm-5 4c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 4 18v-3h2v3h12v-3h2v3c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 18 20H6Z'/%3E%3C/svg%3E");
        }
      }
    }
  }

  @mixin max-sm {
    &__navbar-box {
      max-width: unset;
    }
  }

  @mixin max-xs {
    &__lang-box {
      margin-right: 10px;
    }
  }
}
