.popup-subscribe {
    position: relative;
    max-width: 800px;
    max-height: 85dvh;
    padding: 0;
    border: 1px solid var(--Gray-III);

    & > .simplebar-track.simplebar-vertical {
        top: 55px;

        .simplebar-scrollbar::before {
            opacity: 0.3 !important;
            background-color: var(--black);
        }
    }

    &__wrapper {
        display: flex;
        padding: ac(45px, 40px) ac(35px, 15px) ac(30px, 20px);
        border: 1px solid var(--Gray-III);
        background-color: var(--white);
    }

    &__title {
        max-width: 380px;
        font-size: ac(36px, 24px);
        line-height: ac(42px, 30px);
        font-weight: 600;
    }

    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -1px;
        right: -1px;
        width: 56px;
        height: 56px;
        border: 1px solid var(--forest);
        background-color: var(--forest);
        transition: 0.3s ease;
        cursor: pointer;
        z-index: 5;

        &::after {
            content: '';
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23C3D600' d='M1.439 16.547 2.302 20l7.77-7.77 6.475 6.475L20 17.842l-7.77-7.77 6.62-6.619L17.985 0l-7.914 7.914-6.619-6.619L0 2.158l7.914 7.914-6.475 6.475Z'/%3E%3C/svg%3E");
            cursor: pointer;
            transition: 0.3s ease;
        }

        &:hover {
            border-color: var(--forest);
            background-color: var(--Lime-III);

            &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23255f47' d='M1.439 16.547 2.302 20l7.77-7.77 6.475 6.475L20 17.842l-7.77-7.77 6.62-6.619L17.985 0l-7.914 7.914-6.619-6.619L0 2.158l7.914 7.914-6.475 6.475Z'/%3E%3C/svg%3E");
            }
        }
    }
}
