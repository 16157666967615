.fp-watermark {
  display: none;
}

html {
  font-size: ac(18px, 16px);
  line-height: ac(28px, 24px);
  font-family: var(--font-main);
  scroll-padding-top: 70px;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: var(--forest) rgba(30, 30, 30, 0.2);

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(30, 30, 30, 0.2);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--forest);
    border-radius: 0;
  }
}

body {
  min-width: 320px;
  background-color: var(--white);
  color: var(--black);
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  overflow: hidden;
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1272px;
  padding: 0 24px;
}

#bold-credits {
  width: ac(150px, 136px);
  transition: ease-in-out 0.25s;

  &:hover {
    transform: scale(1.1);
  }
}

.simplebar-track.simplebar-vertical {
  width: 6px;
}

.simplebar-scrollbar::before,
.simplebar-scrollbar {
  border-radius: 0 !important;
  opacity: 1 !important;
  top: 0 !important;
  left: 0;
  bottom: 0 !important;
  right: 0;
  background-color: var(--white);
}

.simplebar-track {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0 !important;
}

.swiper {
  width: 100%;
  overflow: visible;
}

.swiper-wrapper {
  left: 0 !important;
}

.swiper-slide {
  height: auto;
}

img,
video,
svg {
  width: 100%;
  height: 100%;
}

img,
video {
  object-fit: cover;
}

svg {
  object-fit: contain;
}

.js-scroller[data-animated='true'] {
  /* mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent); */
}

.js-scroller[data-animated='true'] .js-scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 30s)
    var(--_animation-direction, forwards) linear infinite;
}

.js-scroller[data-animated='true'] {
  &:hover {
    .js-scroller__inner {
      animation-play-state: paused;
    }
  }
}

.js-scroller[data-direction='right'] {
  --_animation-direction: reverse;
}

.js-scroller[data-direction='left'] {
  --_animation-direction: forwards;
}

.js-scroller[data-speed='fast'] {
  --_animation-duration: 20s;
}

.js-scroller[data-speed='slow'] {
  --_animation-duration: 60s;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

.plyr {
  width: 100%;
  height: 100%;

  video {
    object-fit: cover;
  }

  &__poster {
    background-size: cover;
    filter: brightness(0.7);
  }

  &__control--overlaid {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 96px !important;
    height: 96px;
    border-radius: 0px;
    opacity: 1;
    margin-top: 4px;
    margin-left: 4px;
    z-index: 1;

    &::before,
    &::after {
      content: '';
      position: absolute;
      transition: 0.3s ease;
    }

    &::before {
      inset: 0;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='96' height='96' fill='none'%3E%3Cpath fill='%23EFF6FF' d='M0 0h96v96H0V0Z'/%3E%3Cpath fill='%23143366' d='M67.444 48 33 68V28l34.444 20Z'/%3E%3C/svg%3E")
        center / contain no-repeat;
    }
    &::after {
      top: -8px;
      left: -8px;
      width: 100%;
      height: 100%;
      background-color: var(--Blue-I);
      z-index: -1;
    }

    &:hover {
      &::before {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='96' height='96' fill='none'%3E%3Cpath fill='%23143366' d='M0 0h96v96H0V0Z'/%3E%3Cpath fill='%23EFF6FF' d='M67.444 48 33 68V28l34.444 20Z'/%3E%3C/svg%3E")
          center / contain no-repeat !important;
      }

      &::after {
        top: -4px;
        left: -4px;
        width: calc(100% + 8px);
        height: calc(100% + 8px);
      }
    }

    svg {
      display: none;
    }
  }

  .plyr__video-wrapper {
    z-index: 1;
  }
}

.plyr--video.plyr--stopped .plyr__controls {
  display: none;
}

.team-group {
  margin-top: ac(120px, 72px);
  padding-bottom: ac(96px, 48px);
}

.group {
  position: relative;
  margin-top: -265px;
  padding-top: 267px;
  padding-bottom: ac(117px, 41px);
  z-index: 1;

  &--sectors {
    margin-top: ac(96px, 48px);
    padding-top: 0;
    padding-bottom: ac(120px, 41px);

    .group__bg {
      bottom: ac(-60px, -30px);
      top: auto;
      height: calc(100% - ac(195px, 300px));
    }
  }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    @mixin max-xxxl {
      img {
        object-position: left top;
      }
    }

    @mixin max-sm {
      img {
        clip-path: polygon(0 0, 100% 17%, 100% 100%, 0% 100%);

        object-position: right bottom;
      }
    }
  }
  @mixin xxl {
    margin-top: -165px;

    &--sectors {
      margin-top: ac(96px, 48px);

      .group__bg {
        height: 85%;
      }
    }

    &--client {
      .group__bg {
        clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);

        img {
          object-position: left bottom;
        }
      }
    }
  }

  @mixin max-sm {
    margin-top: 170px;

    &--sectors {
      margin-top: ac(96px, 48px);
    }

    &--client,
    &--sectors {
      .group__bg {
        img {
          object-position: left top;
        }
      }
    }
  }
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .main {
    flex-grow: 1;
    padding: 0;
  }
}

.cookie-terms-section {
  padding: ac(250px, 140px) 0 ac(150px, 50px);
  p {
    padding: 0;
    margin-bottom: 10px;
  }
  ul,
  ol {
    padding-left: 20px;
    li {
      p {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: circle;
  }

  ol {
    list-style: decimal;
  }

  a {
    display: inline;
    text-decoration: underline;
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
}

.single-page-404 {
  min-height: 100vh;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1,
  p {
    margin-bottom: 15px;
    padding: 0;
  }

  a {
    display: inline-block;
    margin-top: 20px;
  }
}

@mixin max-sm {
  .plyr {
    &__control--overlaid {
      width: 46px !important;
      height: 46px;

      &::before {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='46' height='46' fill='none'%3E%3Cpath fill='%23EFF6FF' d='M0 0h46v46H0V0Z'/%3E%3Cpath fill='%23143366' d='M32 23 16 33V13l16 10Z'/%3E%3C/svg%3E")
          center / contain no-repeat;
      }

      &::after {
        top: -4px;
        left: -4px;
      }

      &:hover {
        &::before {
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='46' height='46' fill='none'%3E%3Cpath fill='%23143366' d='M0 0h46v46H0V0Z'/%3E%3Cpath fill='%23EFF6FF' d='M32 23 16 33V13l16 10Z'/%3E%3C/svg%3E")
            center / contain no-repeat !important;
        }

        &::after {
          top: -2px;
          left: -2px;
          width: calc(100% + 4px);
          height: calc(100% + 4px);
        }
      }
    }
  }
}

@mixin max-xs {
  .cont {
    padding: 0 15px;
  }
}

.choices__list {
  scroll-padding-top: 70px;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: var(--forest) rgba(30, 30, 30, 0.2);

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(30, 30, 30, 0.2);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--forest);
    border-radius: 0;
  }
}

/* Animate in view */
[data-aiv^='fade'] {
  --duration: 500ms;
  --delay: 0ms;

  opacity: 0 !important;

  transition: all ease var(--duration) var(--delay);

  @mixin media 551 {
    transition: all ease var(--duration);
  }

  &[data-aiv$='top'] {
    translate: 0 30% !important;
  }
  &[data-aiv$='bottom'] {
    translate: 0 -50% !important;
  }
  &[data-aiv$='left'] {
    translate: 50% 0 !important;
  }
  &[data-aiv$='right'] {
    translate: -50% 0 !important;
  }
  &[data-aiv$='in'] {
    scale: 0 !important;
  }
  &[data-aiv$='out'] {
    scale: 2 !important;
  }

  &.in-view {
    opacity: 1 !important;

    &[data-aiv$='top'],
    &[data-aiv$='bottom'],
    &[data-aiv$='left'],
    &[data-aiv$='right'] {
      translate: 0 0 !important;
    }

    &[data-aiv$='in'],
    &[data-aiv$='out'] {
      scale: 1 !important;
    }
  }
}

.bold-logo {
  width: 100%;
}

/*
.case-toggler {
  border: 1px solid var(--Gray-III);
  box-shadow: 0 0 15px 0 rgba(0,0,0,.12);

  &__mask {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: var(--forest);
    z-index: -1;
    transition: .3s ease;
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;

    &:not(:checked) {
      &~.case-toggler__item:not(:last-child) {
        color: var(--Lime-II);
      }
      &~.case-toggler__mask {
        left: 0%;
      }
    }

    &:checked {
      &~.case-toggler__mask {
        left: 50%;
      }
      &~.case-toggler__item:last-child {
        color: var(--Lime-II);
      }
    }
  }

  &__item {
    padding: 10px 15px;
    color: var(--Gray-I);
    text-align: center;
    font-weight: 800;
    transition: .3s ease;
  }
}
*/
.case-toggler {
  border: 1px solid var(--Gray-III);
  box-shadow: 0 0 15px 0 rgba(0,0,0,.12);

  &__mask {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--forest);
    z-index: -1;
    opacity: 0;
    transition: .3s ease;
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;

    &:not(:checked) {
      &~.case-toggler__item:not(:last-child) {
        color: var(--Lime-II);
      }

    }

    &:checked {
      &~.case-toggler__mask {
        opacity: 1;
      }
      &~.case-toggler__item:last-child {
        color: var(--Lime-II);
      }
    }
  }

  &__item {
    width: 100%;
    padding: 10px 15px;
    color: var(--Gray-I);
    text-align: center;
    font-weight: 800;
    transition: .3s ease;
  }
}