.are-experts {
  /* margin-top: ac(96px, 48px); */
  padding: ac(96px, var(--mmt)) 0;
  background-color: var(--Lime-III);

  &__wrapper {
    gap: ac(56px, 20px);
  }

  &__img-box {
    aspect-ratio: 1 / 1;
  }

  &__img {
    &:nth-child(1) {
      aspect-ratio: 0.88 / 1;
      width: calc(50% - 5px);
      max-width: 294px;
      top: 0;
      left: 0;
      clip-path: polygon(0 0, 100% 0%, 100% 80%, 0% 100%);
    }

    &:nth-child(2) {
      aspect-ratio: 1.11/1;
      width: calc(50% - 5px);
      max-width: 293px;
      top: 0;
      right: 0;

      clip-path: polygon(0 0, 100% 0%, 100% 75%, 0% 100%);
    }

    &:nth-child(3) {
      bottom: 0;
      width: 100%;
      aspect-ratio: 14 / 9;
      clip-path: polygon(0 35%, 100% 0%, 100% 100%, 0 100%);
    }
  }

  &__subtitle {
    margin-top: ac(25px, 15px);
    color: var(--forest);
    font-weight: 700;
    font-size: ac(22px, 18px);
    line-height: ac(32px, 24px);
  }

  &__scroll {
    margin-top: ac(24px, 10px);
    max-height: calc(ac(28px, 24px) * 10);

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      right: -10px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  @mixin md {
    &__text-box {
      max-width: ac(566px, 400px);
    }
  }

  @mixin max-md {
    &__img-box {
      max-width: 500px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }
}
