.touch {
  padding: ac(108px, var(--mmt)) 0;
  background: var(--Blue-III);

  &__wrapper {
    overflow: hidden;
    padding: ac(55px, 20px) ac(63px, 15px) ac(30px, 20px);
    border: 1px solid var(--Gray-III);
    background-color: var(--white);
  }

  &__title {
    color: var(--forest);
    font-size: ac(56px, 24px);
    font-weight: 700;
    line-height: ac(68px, 28px);
    letter-spacing: -0.448px;
  }

  &__subtitle {
    margin-top: ac(16px, 10px);
    color: var(--Gray-I);
    font-size: ac(22px, 18px);
    font-weight: 700;
    line-height: ac(32px, 24px);

    a {
      transition: color ease 250ms;

      hover {
        color: var(--forest);
      }
    }
  }

  &__thumb {
    margin-top: ac(24px, 15px);

    &::before {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--Gray-III);
    }

    .swiper-slide {
      width: auto;
      cursor: default;

      &:last-child {
        .touch__tab {
          &::before {
            display: none;
          }
        }
      }

      &:not(.swiper-slide-thumb-active) {
        cursor: pointer;
      }

      &-thumb-active {
        .touch__tab {
          padding: ac(11px, 8px) ac(25px, 5px, 320) ac(11px, 8px)
            ac(42px, 15px, 320);

          span {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }

      &-thumb-active,
      &:hover {
        .touch__tab {
          background-color: var(--forest);
          color: var(--Lime-II);

          &::before {
            height: 100%;
            background-color: var(--forest);
          }
        }
      }
    }
  }

  &__tab {
    position: relative;
    padding: ac(11px, 8px) ac(21px, 15px);

    color: var(--Gray-I);
    font-size: ac(16px, 14px, 320);
    line-height: 22px;
    font-weight: 800;
    text-transform: capitalize;

    transition: 0.3s ease;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      width: 1px;
      height: ac(20px, 14px);
      background-color: var(--Gray-III);
      transition: 0.3s ease;
    }

    span {
      position: absolute;
      left: ac(21px, 5px, 320);
      width: ac(16px, 12px);
      height: ac(16px, 12px);
      border-radius: 100%;
      border: 1px solid var(--Lime-I);
      transform: translateX(-100%);
      opacity: 0;
      transition: 0.3s ease;

      &::before {
        content: '';
        width: ac(8px, 6px);
        height: ac(8px, 6px);
        background-color: var(--Lime-I);
        border-radius: 100%;
      }
    }
  }

  .touch__slider {
    .swiper-slide {
      opacity: 0 !important;

      &-active {
        opacity: 1 !important;
      }
    }
  }

  &__form {
    justify-content: space-between;
    padding-top: ac(32px, 20px);
    gap: ac(24px, 15px) ac(30px, 10px);
  }

  .agreement {
    margin-top: ac(9px, 0px);
    flex: 1 0 ac(500px, 200px, 320);
    max-width: 391px;

    .checkBox {
      input:checked + span:before {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='10' fill='none'%3E%3Cpath opacity='1' fill='%23C3D600' d='M10.084.834a1.031 1.031 0 0 1 1.472 1.444L6.067 9.139a1.031 1.031 0 0 1-1.485.028L.945 5.528a1.031 1.031 0 1 1 1.458-1.457l2.88 2.878L10.056.864a.334.334 0 0 1 .028-.03h-.001Z'/%3E%3C/svg%3E")
          center / 12px 10px no-repeat;
      }

      span {
        padding-left: 38px;
        font-weight: 700;

        &::before {
          left: 0;
          width: 22px;
          height: 22px;
          border: 1px solid #92afa3;
          border-radius: 3px;
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='10' fill='none'%3E%3Cpath opacity='0' fill='%23C3D600' d='M10.084.834a1.031 1.031 0 0 1 1.472 1.444L6.067 9.139a1.031 1.031 0 0 1-1.485.028L.945 5.528a1.031 1.031 0 1 1 1.458-1.457l2.88 2.878L10.056.864a.334.334 0 0 1 .028-.03h-.001Z'/%3E%3C/svg%3E")
            center / 12px 10px no-repeat;
        }
      }
    }
  }

  &__btn {
    margin-top: ac(9px, 0px);
  }

  &__input-box {
    display: inline-flex;
    flex-direction: column;
    flex: 1 0 ac(500px, 200px, 320);

    input,
    textarea {
      border: 1px solid var(--Gray-III);
      background-color: var(--white);
      @mixin transparent-bg-input var(--Gray-I);

      &,
      &::placeholder {
        color: var(--Gray-I);
      }
    }

    textarea {
      padding: 10px 15px;
      height: 100%;
      min-height: ac(172px, 120px);
    }
  }

  &__label {
    margin-bottom: ac(8px, 5px);
    margin-left: ac(16px, 8px);
    color: var(--Gray-I);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  &__input {
    height: ac(48px, 40px);
  }

  @mixin max-sm {
    &__btn {
      width: 100%;
    }

    &__tab {
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }
}
