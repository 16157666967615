.statistics {
  margin-top: ac(128px, var(--mmt));
  padding: ac(96px, var(--mmt)) 0 ac(111px, var(--mmt));
  background-color: var(--Blue-III);

  &--client {
    background-color: transparent;
    margin-top: 0;
    padding-bottom: 0;

    .statistics {
      &__title {
        span {
          color: var(--forest);
        }
      }

      &__count {
        color: var(--forest);
      }

      &__sep {
        background-color: var(--forest);
      }

      &__descr {
        color: var(--Gray-I);
      }

      &__count-box {
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16),
          0px 0px 24px 0px rgba(0, 0, 0, 0.12);
      }
    }
  }

  &__title {
    span {
      color: var(--navy);
    }
  }

  &__subtitle {
    margin-top: ac(24px, 15px);
  }

  &__row {
    position: relative;
    margin-top: ac(48px, 24px);
    gap: ac(72px, 30px);
    padding: 0 ac(24px, 0px);
    z-index: 1;
  }

  &__count-box {
    align-items: center;
    justify-content: center;
    padding: ac(45px, 25px) ac(24px, 15px);
    background: var(--white);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  }

  &__decor {
    width: calc(100% + ac(48px, 24px));
    height: calc(100% - ac(54px, 45px));
    border: 1px solid var(--Gray-III);
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  &__count {
    font-size: ac(56px, 32px);
    line-height: ac(68px, 40px);
    letter-spacing: -0.448px;
    font-weight: 700;
    color: var(--navy);
  }

  &__sep {
    margin-top: ac(17px, 10px);
    height: 4px;
    width: ac(64px, 40px);
    background-color: var(--Blue-I);
  }

  &__descr {
    margin-top: ac(24px, 15px);
    font-size: ac(28px, 22px);
    line-height: ac(36px, 28px);
    font-weight: 700;
    letter-spacing: -0.07px;
    color: var(--navy);
    max-height: calc(ac(36px, 28px) * 3);
    @mixin max-line-length 3;
  }
}
