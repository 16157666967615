.with-us {
  margin-top: ac(96px, var(--mmt));

  &__title {
    margin-top: ac(28px, 10px);
  }

  &__subtitle {
    margin-top: ac(25px, 16px);
    max-height: calc(ac(28px, 24px) * 8);
    @mixin max-line-length 8;
  }

  &__card-box {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(24px, 16px);
    grid-row-gap: ac(24px, 16px);
    margin-top: ac(48px, 20px);

    @mixin max-sm {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__card {
    overflow: hidden;
    background-color: var(--forest);
  }

  &__img {
    @mixin aspect-ratio 600, 338;
    width: 100%;

    @mixin md {
      min-height: ac(338px, 196px);
    }
  }

  &__bottom {
    padding: ac(40px, 15px) ac(32px, 15px) ac(32px, 15px);

    @mixin sm {
      height: 100%;
    }
  }

  &__name,
  &__descr {
    color: var(--white);
  }

  &__name {
    font-weight: 700;
    font-size: ac(36px, 24px);
    line-height: ac(48px, 32px);
    letter-spacing: -0.09px;
  }

  &__scroll {
    margin: ac(16px, 10px) 0 ac(31px, 20px);
    max-height: calc(ac(32px, 24px) * 6);

    .simplebar-track {
      right: -10px;
    }
  }

  &__descr {
    font-size: ac(22px, 18px);
    line-height: ac(32px, 24px);
    font-weight: 300;
  }

  &__btn {
    @media (max-width: 551px) {
      width: 100%;
    }
  }

  &__line {
    background: rgba(227, 231, 186, 0.3);

    @media (max-width: 551px) {
      display: none;
    }
  }
}
