.how-work {
  margin-top: ac(96px, var(--mmt));

  &__slider {
    margin-top: ac(64px, 25px);
  }

  &__title {
    width: 100%;
  }

  &__card {
    min-height: ac(314px, 200px);
    padding: ac(32px, 15px);
    border: 1px solid var(--Gray-III);
    background-color: var(--Lime-III);
    overflow: hidden;
  }

  &__name {
    color: var(--Gray-I);
    font-size: ac(28px, 20px);
    font-weight: 700;
    line-height: ac(36px, 24px);
    letter-spacing: -0.07px;
  }

  &__icon {
    width: ac(72px, 60px);
    height: ac(72px, 60px);
  }

  &__scroll {
    margin-bottom: 20px;
    margin-top: ac(24px, 15px);
    max-height: calc(24px * 8);

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      right: -10px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__descr {
    &,
    & > * {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  &__line {
    background-color: #2b7051;
  }
}
