.start {
  position: relative;
  padding-top: ac(96px, var(--mmt));
  z-index: 1;
  background-color: var(--Blue-III);

  &--dynamic {
    padding-top: 0;
    margin-top: ac(128px, var(--mmt));
  }

  &--sol {
    background-color: #fff1f2;
    padding-top: 0;
  }

  &--sectors {
    padding-top: ac(108px, var(--mmt));
    background-color: transparent;
  }

  &--dynamic,
  &--about,
  &--client,
  &--diver {
    background-color: var(--white);
  }

  &--client {
    padding-top: ac(64px, var(--mmt));
  }

  &--join {
    padding-top: ac(110px, var(--mmt));
    background-color: var(--white);
  }

  &--team {
    padding-top: 0;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: ac(240px, 105px);
    width: 100%;
    background-color: var(--Lime-III);
    z-index: -1;
  }

  &__wrapper {
    padding: ac(64px, 32px) ac(72px, 25px);
  }

  &__head {
    display: flex;
    gap: 24px;
    padding-bottom: ac(24px, 8px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }

  &__title {
    color: var(--white);
    font-size: ac(56px, 32px);
    line-height: ac(68px, 40px);
    letter-spacing: -0.256px;
  }

  &__subtitle {
    margin-top: ac(16px, 12px);
    max-width: ac(582px, 279px);

    color: var(--Lime-II);
    font-size: ac(56px, 32px);
    font-weight: 700;
    line-height: ac(68px, 31px);
    letter-spacing: -0.256px;
  }

  &__btn-box {
    display: flex;
    flex-wrap: wrap;
    margin-top: ac(185px, 36px);
    gap: 16px;
  }

  &__btn {
    position: relative;
    display: inline-flex;
    align-items: flex-end;
    height: ac(90px, 80px);
    padding: 6px 4px 15px 14px;
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    border: 2px solid var(--Lime-II);
    transition: 0.3s ease;

    background-color: var(--Lime-II);
    color: var(--black);
    &::after {
      content: '';
      margin-bottom: auto;
      margin-left: auto;
      width: 40px;
      height: 40px;
      margin-left: 10px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='none'%3E%3Cpath fill='%23255F47' d='m27.089 23.824-2.943-.035v-6.257L13.143 28.535l-2.078-2.08 11.003-11.002H15.81l-.035-2.943 11.32-.005-.006 11.32Z'/%3E%3C/svg%3E");
      transition: 0.3s ease;
    }

    &:hover {
      color: var(--white);
      background-color: transparent;

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='none'%3E%3Cpath fill='%23fff' d='m27.089 23.824-2.943-.035v-6.257L13.143 28.535l-2.078-2.08 11.003-11.002H15.81l-.035-2.943 11.32-.005-.006 11.32Z'/%3E%3C/svg%3E");
      }
    }

    &--upload {
      padding: 8px 9px 16px 16px;
      border: 0;
      color: var(--white);
      background-color: transparent;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E3E7BA' stroke-width='1.5' stroke-dasharray='12%2c 12' stroke-dashoffset='27' stroke-linecap='square'/%3e%3c/svg%3e");

      &::after {
        margin-left: 25px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='none'%3E%3Cpath fill='%23E3E7BA' d='M18.625 25.5V14.294l-3.575 3.575-1.925-1.994L20 9l6.875 6.875-1.925 1.994-3.575-3.575V25.5h-2.75ZM11.75 31c-.756 0-1.404-.27-1.942-.808A2.648 2.648 0 0 1 9 28.25v-4.125h2.75v4.125h16.5v-4.125H31v4.125c0 .756-.27 1.404-.808 1.942A2.648 2.648 0 0 1 28.25 31h-16.5Z'/%3E%3C/svg%3E");
      }

      &:hover {
        background-color: var(--Lime-II);
        color: var(--forest);
        &::after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='none'%3E%3Cpath fill='%23255F47' d='M18.625 25.5V14.294l-3.575 3.575-1.925-1.994L20 9l6.875 6.875-1.925 1.994-3.575-3.575V25.5h-2.75ZM11.75 31c-.756 0-1.404-.27-1.942-.808A2.648 2.648 0 0 1 9 28.25v-4.125h2.75v4.125h16.5v-4.125H31v4.125c0 .756-.27 1.404-.808 1.942A2.648 2.648 0 0 1 28.25 31h-16.5Z'/%3E%3C/svg%3E");
        }
      }
    }
  }

  @mixin md {
    &__title {
      letter-spacing: -0.28px;
      font-weight: 300;
    }

    &__subtitle {
      letter-spacing: -0.448px;
    }
  }

  @mixin max-md {
    &__head {
      flex-direction: column-reverse;
    }
  }

  @mixin max-sm {
    &__wrapper {
      padding-bottom: 204px;
    }

    &__btn {
      width: 100%;
      gap: 5px;

      &::after {
        margin-left: auto;
      }
    }
  }
}
