.document {
  margin-top: ac(96px, var(--mmt));
  padding-top: ac(154px, var(--mmt));
  background-color: var(--Blue-III);

  &__wrapper {
    gap: ac(96px, 25px);
    align-items: center;
  }

  &__btn-box {
    position: relative;
    width: 100%;
    padding: ac(54px, 25px) 0 ac(77px, 25px);
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23007DC4' stroke-width='4' stroke-dasharray='20%2c 20' stroke-dashoffset='20' stroke-linecap='round'/%3e%3c/svg%3e");
    transition: 0.3s ease;

    &:hover {
      background-color: var(--Lime-III);
      .btn {
        background-color: var(--forest);
        color: var(--white);

        .btn__icon {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23FFFFFF' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
        }
      }
    }
  }

  &__label {
    margin-top: 8px;
    color: var(--Gray-I);
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  &__title {
    span {
      color: var(--navy);
    }
  }

  &__scroll {
    width: 100%;
    margin-top: ac(24px, 15px);
    padding-right: ac(20px, 10px);
    max-height: calc(ac(32px, 24px) * 8);

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__subtitle {
    font-size: ac(22px, 18px);
    line-height: ac(32px, 24px);
    font-weight: 300;
  }

  &__btn {
    margin-top: ac(25px, 15px);
  }
  @mixin md {
    &__btn-box {
      margin-left: ac(39px, 0px);
      flex-shrink: 0;
      max-width: ac(486px, 300px);
    }
  }
}
