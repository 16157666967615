.solutions {
  margin-top: ac(96px, var(--mmt));

  &--client {
    .solutions__col {
      margin-top: ac(16px, 0px);
    }
  }

  &--tech {
    margin-top: ac(48px, var(--mmt));
  }

  &--change {
    margin-top: ac(116px, var(--mmt));
  }

  &__wrapper {
    gap: ac(40px, 8px);
  }

  &__suptitle {
    margin-bottom: ac(28px, 10px);
  }

  &__col {
    margin-top: ac(8px, 0px);
    width: 100%;
  }

  &__thumb {
    margin-top: ac(40px, 24px);

    .swiper-slide {
      &:nth-child(3n + 1) {
        &.swiper-slide-thumb-active,
        &:hover {
          .solutions__tab {
            border-color: var(--Lime-I);
          }
        }
      }

      &:nth-child(3n + 2) {
        &.swiper-slide-thumb-active,
        &:hover {
          .solutions__tab {
            border-color: var(--Blue-I);
          }
        }
      }
      &:nth-child(3n + 3) {
        &.swiper-slide-thumb-active,
        &:hover {
          .solutions__tab {
            border-color: var(--Salmon-I);
          }
        }
      }

      &-thumb-active {
        .solutions__tab {
          cursor: default;
        }

        .solutions__arrow {
          span {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='13' fill='none'%3E%3Cpath fill='%23255F47' d='M10.21 0 8.617 1.63l3.424 3.424H0v2.275h12.042l-3.424 3.425 1.591 1.629 6.198-6.191L10.209 0Z'/%3E%3C/svg%3E");
          }
        }
      }

      &:nth-child(3n + 2) {
        &.swiper-slide-thumb-active {
          .solutions__arrow {
            span {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='13' fill='none'%3E%3Cpath fill='%23143366' d='M10.21 0 8.617 1.63l3.424 3.424H0v2.275h12.042l-3.424 3.425 1.591 1.629 6.198-6.191L10.209 0Z'/%3E%3C/svg%3E");
            }
          }
        }

        .solutions__tab {
          color: var(--navy);
          background-color: var(--Blue-III);
          border-color: var(--Blue-III);
        }

        .solutions__arrow {
          background-color: var(--Blue-II);

          span {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='13' fill='none'%3E%3Cpath fill='%23007DC4' d='M10.21 0 8.617 1.63l3.424 3.424H0v2.275h12.042l-3.424 3.425 1.591 1.629 6.198-6.191L10.209 0Z'/%3E%3C/svg%3E");
          }
        }
      }

      &:nth-child(3n + 3) {
        &.swiper-slide-thumb-active {
          .solutions__arrow {
            span {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='13' fill='none'%3E%3Cpath fill='%23780F30' d='M10.21 0 8.617 1.63l3.424 3.424H0v2.275h12.042l-3.424 3.425 1.591 1.629 6.198-6.191L10.209 0Z'/%3E%3C/svg%3E");
            }
          }
        }
        .solutions__tab {
          color: var(--maroon);
          background-color: var(--Salmon-III);
          border-color: var(--Salmon-III);
        }

        .solutions__arrow {
          background-color: var(--Salmon-II);

          span {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='13' fill='none'%3E%3Cpath fill='%23ED6B85' d='M10.21 0 8.617 1.63l3.424 3.424H0v2.275h12.042l-3.424 3.425 1.591 1.629 6.198-6.191L10.209 0Z'/%3E%3C/svg%3E");
          }
        }
      }
    }

    @mixin max-sm {
      touch-action: pan-y !important;
    }
  }

  &__tab {
    padding: ac(19px, 7px) ac(20px, 7px) ac(19px, 7px) ac(31px, 15px);
    background-color: var(--Lime-III);
    border: 1px solid var(--Lime-III);
    color: var(--forest);
    font-size: ac(22px, 16px);
    line-height: ac(32px, 20px);
    font-weight: 700;
    transition: 0.3s ease;
    cursor: pointer;
  }

  &__arrow {
    width: ac(40px, 32px);
    height: ac(40px, 32px);
    background-color: var(--Lime-II);

    span {
      width: 17px;
      height: 13px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='13' fill='none'%3E%3Cpath fill='%23C3D600' d='M10.21 0 8.617 1.63l3.424 3.424H0v2.275h12.042l-3.424 3.425 1.591 1.629 6.198-6.191L10.209 0Z'/%3E%3C/svg%3E");
      transition: 0.3s ease;
    }
  }

  &__slider {
    overflow: hidden;

    .swiper-slide {
      opacity: 0 !important;

      &-active {
        opacity: 1 !important;
      }

      &:nth-child(3n + 2) {
        .solutions__card {
          background-color: var(--Blue-III);
        }

        .solutions__head {
          color: var(--navy);
        }

        .solutions__btn {
          border-color: var(--navy);
          color: var(--navy);

          .btn__icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23143366' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
          }

          &:hover {
            background-color: var(--navy);
            color: var(--white);

            .btn__icon {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23FFFFFF' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
            }
          }
        }
      }

      &:nth-child(3n + 3) {
        .solutions__card {
          background-color: var(--Salmon-III);
        }

        .solutions__head {
          color: var(--maroon);
        }

        .solutions__btn {
          border-color: var(--maroon);
          color: var(--maroon);

          .btn__icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23780f30' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
          }

          &:hover {
            background-color: var(--maroon);
            color: var(--white);

            .btn__icon {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23FFFFFF' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
            }
          }
        }
      }
    }
  }

  &__card {
    padding: ac(40px, 16px) ac(40px, 14px) ac(48px, 24px) ac(48px, 24px);
    background-color: var(--Lime-III);
  }

  &__head {
    margin-bottom: ac(24px, 12px);
    gap: ac(19px, 8px);
    font-size: ac(36px, 22px);
    line-height: ac(48px, 32px);
    font-weight: 700;
    color: var(--forest);
  }

  &__icon {
    width: ac(56px, 40px);
    height: ac(56px, 40px);
  }

  [data-simplebar] {
    width: calc(100% + 10px);
    margin-bottom: ac(32px, 25px);
    max-height: calc(ac(28px, 24px) * 12 + ac(32px, 20px));

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__subtitle {
    display: flex;
    flex-direction: column;
    gap: ac(10px, 5px);
    margin-top: ac(25px, 15px);
    color: var(--black);
    font-size: ac(18px, 16px);
    font-weight: 400;
    line-height: ac(28px, 22px);
  }
  &__descr {
    padding-right: 10px;

    &,
    & > * {
      &:not(:last-child) {
        margin-bottom: ac(16px, 10px);
      }
    }
  }

  @mixin lg {
    &__wrapper {
      max-width: 1184px;
      align-items: center;
    }

    &__col {
      max-width: ac(516px, 350px, 1024);
      flex-shrink: 0;
    }
  }

  @mixin max-lg {
    &__wrapper {
      flex-direction: column;
    }
  }

  @mixin max-sm {
    &__card {
      .btn {
        width: 100%;
      }
    }

    &__thumb {
      margin-top: ac(40px, 24px);

      .swiper-slide {
        &-thumb-active {
          .solutions__arrow {
            span {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='11' fill='none'%3E%3Cpath fill='%23255F47' d='m8.712.434-1.358 1.39 2.922 2.922H0v1.941h10.276L7.354 9.61 8.712 11 14 5.717 8.712.434Z'/%3E%3C/svg%3E");
            }
          }
        }

        &:nth-child(3n + 2) {
          &.swiper-slide-thumb-active {
            .solutions__arrow {
              span {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='11' fill='none'%3E%3Cpath fill='%23143366' d='m8.712.434-1.358 1.39 2.922 2.922H0v1.941h10.276L7.354 9.61 8.712 11 14 5.717 8.712.434Z'/%3E%3C/svg%3E");
              }
            }
          }

          .solutions__arrow {
            span {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='11' fill='none'%3E%3Cpath fill='%23007DC4' d='m8.712.434-1.358 1.39 2.922 2.922H0v1.941h10.276L7.354 9.61 8.712 11 14 5.717 8.712.434Z'/%3E%3C/svg%3E");
            }
          }
        }

        &:nth-child(3n + 3) {
          &.swiper-slide-thumb-active {
            .solutions__arrow {
              span {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='11' fill='none'%3E%3Cpath fill='%23780F30' d='m8.712.434-1.358 1.39 2.922 2.922H0v1.941h10.276L7.354 9.61 8.712 11 14 5.717 8.712.434Z'/%3E%3C/svg%3E");
              }
            }
          }
          .solutions__arrow {
            span {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='11' fill='none'%3E%3Cpath fill='%23ED6B85' d='m8.712.434-1.358 1.39 2.922 2.922H0v1.941h10.276L7.354 9.61 8.712 11 14 5.717 8.712.434Z'/%3E%3C/svg%3E");
            }
          }
        }
      }
    }

    &__arrow {
      span {
        width: 14px;
        height: 11px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='11' fill='none'%3E%3Cpath fill='%23C3D600' d='m8.712.434-1.358 1.39 2.922 2.922H0v1.941h10.276L7.354 9.61 8.712 11 14 5.717 8.712.434Z'/%3E%3C/svg%3E");
      }
    }
  }
}
