.our-work {
  margin-top: ac(96px, var(--mmt));

  &__wrapper {
    gap: ac(56px, 20px);
  }

  &__title {
    padding-bottom: ac(40px, 15px);
    border-bottom: 1px solid var(--Gray-III);
  }

  &__card-box {
    margin-top: ac(39px, 15px);
    gap: ac(40px, 15px);
  }

  &__icon {
    width: ac(56px, 46px);
    height: ac(56px, 46px);
    margin-right: ac(12px, 8px);
  }

  &__name {
    color: var(--Gray-I);
    font-size: ac(22px, 18px);
    font-weight: 700;
    line-height: ac(32px, 24px);
  }

  &__scroll {
    margin-top: 8px;
    max-height: calc(ac(28px, 24px) * 5);

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      right: -10px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__img {
    aspect-ratio: 0.65 / 1;
  }

  @mixin md {
    &__col {
      flex-shrink: 0;
      max-width: ac(776px, 400px);
    }
  }

  @mixin max-md {
    &__img-box {
      max-width: 500px;
      margin: 0 auto;
    }
  }
}
