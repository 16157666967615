.interested {
  margin-top: ac(64px, var(--mmt));
  padding: ac(108px, var(--mmt)) 0 ac(64px, var(--mmt));
  background-color: var(--Lime-III);

  &--team {
    margin-top: ac(80px, var(--mmt));
    background-color: var(--Blue-III);

    .interested__suptitle {
      color: var(--Gray-I);

      &::before {
        content: '';
        width: 28px;
        height: 28px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' fill='none'%3E%3Cpath fill='%234C4E50' d='M15.133 14 9 7.867 10.867 6l8 8-8 8L9 20.133 15.133 14Z'/%3E%3C/svg%3E")
          center / contain no-repeat;
      }
    }

    .interested__title {
      span {
        color: var(--navy);
      }
    }

    .interested__head {
      background-color: var(--white);
      color: var(--navy);
    }

    .interested__nav .slider-btn {
      background-color: var(--Gray-III);
      border: 1px solid var(--Gray-III);

      &:hover {
        border-color: var(--Lime-II);
        background-color: var(--forest);
      }
    }
  }

  &__wrapper {
    gap: ac(56px, 25px);
  }

  &__title {
    margin-top: ac(28px, 7px);
  }

  &__subtitle {
    margin-top: ac(24px, 16px);
    max-height: calc(ac(32px, 26px) * 10);
    @mixin max-line-length 6;

    & > * {
      margin-top: ac(23px, 15px);

      &:first-child {
        margin-top: 0;
        font-size: ac(22px, 18px);
        line-height: ac(32px, 22px);
        font-weight: 700;
      }
    }
  }

  &__btn {
    margin-top: ac(40px, 20px);
  }

  &__slider-box {
    width: 100%;

    @media (max-width: 640px) {
      margin-bottom: 10px;
    }
  }

  .swiper-slide {
    &:only-child {
      .interested__head {
        padding: ac(38px, 15px) ac(32px, 15px) ac(30px, 15px);
      }
    }
    opacity: 0 !important;

    &-active {
      opacity: 1 !important;
    }
  }

  &__card {
    border: 1px solid var(--Gray-III);
  }

  &__head {
    padding: ac(38px, 15px) ac(160px, 140px) ac(30px, 15px) ac(32px, 15px);
    width: 100%;
    background-color: var(--forest);
    color: var(--white);

    font-size: ac(28px, 22px);
    line-height: ac(36px, 28px);
    font-weight: 700;
    letter-spacing: -0.07px;
  }

  &__img {
    width: 100%;
    aspect-ratio: 2 / 1;
  }

  &__bottom {
    padding: ac(32px, 15px);
    background-color: var(--white);
  }

  &__scroll {
    width: calc(100% + 10px);
    padding-right: 10px;
    max-height: calc(ac(28px, 24px) * 6);

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__nav {
    z-index: 2;
    right: ac(32px, 15px);
    top: ac(32px, 15px);

    .slider-btn {
      background-color: var(--Lime-II);
      border: 1px solid var(--white);

      &:hover {
        border-color: var(--Lime-II);
        background-color: var(--forest);
      }
    }
  }

  &__col {
    .hero__link {
      margin-top: ac(36px, 15px);
      display: inline-flex;
      align-items: center;
      justify-content: center;

      padding: 0 19px 0 25px;
      border: 1px solid var(--forest);
      color: var(--forest);
      background-color: var(--white);

      font-weight: 700;
      font-size: 18px;
      line-height: 46px;
      white-space: nowrap;
      cursor: pointer;
      transition: 0.3s ease;

      &::before {
        display: none;
      }

      &::after {
        margin-left: 4px;
        width: 24px;
        height: 24px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23255f47' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
        transition: 0.3s ease;

        position: relative;
        background-color: unset;
        right: unset;
        transform: unset;
      }

      &:hover {
        background-color: var(--forest);
        color: var(--white);

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23FFFFFF' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
        }
      }

      @media (max-width: 640px) {
        width: 100%;
      }
    }

    .interested__btn {
      @media (max-width: 640px) {
        width: 100%;
      }
    }
  }

  @mixin md {
    &__col {
      max-width: ac(510px, 295px);
      flex-shrink: 0;
    }

    &__slider-box {
      max-width: ac(658px, 402px);
    }
  }

  @mixin max-md {
    &__head {
      padding-right: 15px;
    }

    .slider-btn {
      margin-top: 20px;
    }
  }
}
