/*
Styles for popup background and basic styles for fonts
*/

.termsfeed-com---nb .cc-nb-main-container {
  padding-bottom: ac(50px, 24px);
  padding-top: ac(50px, 24px);
}

.termsfeed-com---palette-dark.termsfeed-com---nb {
  border: 1px solid var(--forest);
  background: var(--white) !important;
  border-radius: 0;
  color: var(--black);
  font-family: var(--font-main);

  @mixin min-media 652 {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  @mixin media 651 {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  @mixin media 380 {
    margin-right: 0;
  }

  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    text-transform: uppercase;
  }

  #cc-nb-text {
    font-size: ac(18px, 16px) !important;
    font-weight: 400 !important;
    line-height: 1.55 !important;
    font-family: var(--font-main) !important;
    color: var(--black) !important;
  }
  #cc-nb-title {
    font-weight: 700 !important;
    font-family: var(--font-main) !important;
    font-size: ac(40px, 24px) !important;
    color: var(--forest) !important;
  }
}

.termsfeed-com---palette-dark .cc-pc-head-title-headline {
  color: var(--forest) !important;
  font-weight: 700 !important;
  font-family: var(--font-main) !important;
}

.termsfeed-com---palette-dark .cc-pc-head-title-text {
  font-size: ac(18px, 16px) !important;
  font-weight: 400 !important;
  line-height: 1.55 !important;
  font-family: var(--font-main) !important;
  color: var(--black) !important;
}

.termsfeed-com---nb {
  /*
    Styles for buttons
    */
  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep,
  .cc-cp-foot-button .cc-cp-foot-save {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 2;
    transition: all 0.3s ease;
    font-family: var(--font-main);
    text-transform: none !important;
    min-width: 140px;

    padding: 0 19px 0 25px;
    border: 1px solid var(--forest);
    background-color: #fafbf4;

    font-weight: 700;
    font-size: 18px;
    line-height: 46px;
    white-space: nowrap;
    color: var(--forest);

    &:before {
      display: block;
      content: '';
      margin-left: 4px;
      width: 24px;
      height: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23255F47' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
      transition: 0.3s ease;
    }

    &:focus {
      box-shadow: none !important;
    }

    &:hover {
      background-color: var(--forest);
      color: var(--white);

      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23FFFFFF' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
      }
    }
  }

  .cc-nb-buttons-container {
    display: flex !important;
    flex-wrap: wrap;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .cc-nb-changep {
    border: 1px solid var(--forest);
    color: var(--forest);
    background-color: var(--white);

    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23255f47' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
    }

    /*&:hover {
      border-color: var(--Lime-I) !important;
      background-color: var(--white) !important;
    }*/
  }
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry a {
  color: var(--Blue-I);
  transition: all 0.3s ease;
  text-transform: none;
  font: inherit;
  text-decoration: none;

  &:hover {
    color: var(--forest);
  }
}

/*
Styles for popup with settings
*/
.termsfeed-com---palette-dark {
  .cc-pc-container {
    font-family: var(--font-main) !important;
    border: 1px solid var(--forest);
    border-radius: 0;
    overflow: hidden;
  }

  .cc-pc-head,
  .cc-cp-body-content,
  .cc-cp-foot {
    background: var(--white) !important;
    border-top-color: var(--forest) !important;
    border-bottom-color: var(--forest) !important;
  }

  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
  }

  .cc-cp-body-tabs {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 3px;
    padding: 3px;
    border-right: 1px solid var(--forest);
  }

  .cc-cp-body-tabs-item {
    background: var(--Lime-II);
    transition: all 0.3s ease;
    opacity: 1;
    border: none !important;
    border-radius: 0 !important;

    &:hover {
      opacity: 0.8;
      .cc-cp-body-tabs-item-link {
        color: var(--forest) !important;
      }
    }
  }

  .cc-cp-body-tabs-item-link {
    color: var(--black);
    transition: all 0.3s ease;

    &:focus {
      box-shadow: none;
    }
  }

  .cc-cp-body-tabs-item[active='true'] {
    background: var(--forest) !important;

    .cc-cp-body-tabs-item-link {
      color: var(--white) !important;
    }

    &:hover {
      opacity: 1;

      .cc-cp-body-tabs-item-link {
        color: var(--white) !important;
      }
    }
  }

  .cc-pc-head-lang {
    select {
      color: var(--black);
      padding: 5px;
      background: var(--white);
      cursor: pointer;
      border: 1px solid var(--black);

      &:focus {
        box-shadow: none;
      }

      option {
        font-size: ac(18px, 16px);
        font-weight: 400;
        line-height: ac(32px, 28px);
        font-style: normal;
        font-family: var(--font-main);
        cursor: pointer;
      }
    }
  }

  .cc-pc-head-close {
    color: var(--maroon);
    transition: all 0.3s ease;

    &:hover {
      color: var(--forest);
      transform: scale(1.1);
    }

    &:focus {
      border: 0 !important;
    }
  }
}

/*
Styles for the lower part of the popup with settings
*/
.termsfeed-com---pc-dialog {
  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
  }

  .cc-cp-foot {
    padding: 10px 0;
    flex-wrap: wrap;
  }

  .cc-cp-foot-byline {
    a {
      color: var(--Blue-I);
      text-decoration: none;
      transition: all 0.3s ease;

      &:hover {
        color: var(--forest);
        text-decoration-color: transparent;
      }
    }
  }

  .cc-cp-foot-button .cc-cp-foot-save {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 2;
    transition: all 0.3s ease;
    font-family: var(--font-main);
    text-transform: none !important;
    min-width: 140px;

    padding: 0 19px 0 25px;
    border: 1px solid var(--forest);
    background-color: #fafbf4;

    font-weight: 700;
    font-size: 18px;
    line-height: 46px;
    white-space: nowrap;
    color: var(--forest);

    &:before {
      display: block;
      content: '';
      margin-left: 4px;
      width: 24px;
      height: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23255F47' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
      transition: 0.3s ease;
    }

    &:focus {
      box-shadow: none !important;
    }

    &:hover {
      background-color: var(--forest);
      color: var(--white);

      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23FFFFFF' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
      }
    }

    @mixin media 530 {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry-text {
  a {
    display: inline-block;
  }
}

.termsfeed-com---palette-dark {
  .cc-cp-body-content-entry-text {
    color: var(--black);
    font-weight: 500;
  }

  .cc-cp-body-content-entry-title {
    color: var(--forest);
    font-weight: 700;
    font-family: var(--font-main);
  }

  .cc-pc-head-title-text {
    color: var(--black);
    font-weight: 500;
  }

  .cc-pc-head-title-headline {
    color: var(--black);
    font-weight: 400;
    font-family: var(--font-main);
  }

  .cc-cp-foot-byline {
    color: var(--black);
    font-weight: 500;
  }
}

.cc-custom-checkbox {
  label {
    font-weight: 500;
  }
}

.termsfeed-com---pc-dialog input[type='checkbox'].cc-custom-checkbox + label {
  color: var(--black);

  &:after,
  &:before {
    border-color: var(--forest);
  }
}

.termsfeed-com---pc-dialog
  input[type='checkbox'].cc-custom-checkbox:checked
  + label::before {
  background: var(--Lime-II);
}

/*
Styles for mobile adaptation
*/
@mixin media 651 {
  .termsfeed-com---nb-simple {
    max-width: 90%;
  }

  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    margin-top: 0 !important;
    max-width: 100%;
  }

  .termsfeed-com---nb .cc-nb-main-container {
    padding: 2rem;
  }
}

@mixin media 425 {
  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    min-width: auto;
    text-align: center !important;
    justify-content: center;
    font-size: 16px;
  }
}

@mixin media 380 {
  .termsfeed-com---nb-simple {
    max-width: 100%;
  }

  .termsfeed-com---pc-dialog .cc-pc-head-lang {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    justify-content: space-between;

    select {
      max-width: 200px;
    }
  }
}
