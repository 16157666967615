input,
textarea {
  padding: 5px 15px;
  width: 100%;
  border: 1px solid #537a5d;
  background-color: var(--forest);
  border-radius: 0;
  @mixin transparent-bg-input var(--white);

  &,
  &::placeholder {
    color: var(--white);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    transition: 0.3s ease;
  }

  &::placeholder {
    opacity: 1;
  }

  &:hover {
    border-color: var(--Lime-II);

    &,
    &::placeholder {
      color: var(--Lime-II);
    }
  }
}

textarea {
  padding-top: ac(13px, 18px);
  height: ac(110px, 95px);
  resize: none;
  scrollbar-width: thin;
  scrollbar-color: var(--black) rgba(30, 30, 30, 0.2);
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(30, 30, 30, 0.2);
    border-radius: 0;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--black);
    border-radius: 0;
  }
}

.meta-upload-file {
  padding: 15px;
  position: relative;
  cursor: pointer;

  flex: 1 0 ac(500px, 200px, 320);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23255f47' stroke-width='4' stroke-dasharray='18%2c 18' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  transition: 0.3s ease;

  &.drag,
  &:hover {
    background-color: var(--forest);

    .meta-upload-file__descr {
      color: var(--white);
    }
  }

  input {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .btn {
    max-width: 100%;
    margin-top: 15px;
    min-width: 171px;
    padding: 0 20px;
    white-space: unset;

    span {
      word-break: break-all;
      @mixin max-line-length 1;
    }
  }

  &__icon {
    width: 41px;
    height: 40px;

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__descr {
    text-align: center;
    margin-top: 8px;
    color: var(--forest);
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    transition: 0.3s ease;
  }
}

.choices {
  width: 100%;
  &::after {
    display: none;
  }

  &:hover {
    &::before {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' fill='none'%3E%3Cpath fill='%23000' fill-rule='evenodd' d='M.853.228a.562.562 0 0 1 .796 0L8.001 6.58 14.353.228a.562.562 0 1 1 .796.796L8.4 7.774a.563.563 0 0 1-.796 0l-6.75-6.75a.563.563 0 0 1 0-.796Z' clip-rule='evenodd'/%3E%3C/svg%3E")
        center / contain no-repeat;
    }

    .choices__inner {
      border: 1px solid var(--Gray-III) !important;
    }
    .choices__list--single .choices__item {
      color: var(--Gray-I);
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 20px;
    right: 13px;
    width: 16px;
    height: 8px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' fill='none'%3E%3Cpath fill='%23000' fill-rule='evenodd' d='M.853.228a.562.562 0 0 1 .796 0L8.001 6.58 14.353.228a.562.562 0 1 1 .796.796L8.4 7.774a.563.563 0 0 1-.796 0l-6.75-6.75a.563.563 0 0 1 0-.796Z' clip-rule='evenodd'/%3E%3C/svg%3E")
      center / contain no-repeat;
    transition: 0.3s ease;
  }

  &.is-open {
    &::before {
      transform: rotate(-180deg);
    }
  }

  &__inner {
    padding: 13px 40px 11px 15px !important;
    min-height: 48px;

    border: 1px solid var(--Gray-III) !important;
    transition: 0.3s ease;
    border-radius: 0;
  }

  .choices__list {
    width: 100%;
    display: flex;
    flex-direction: column;

    &--single {
      .choices__item {
        color: var(--Gray-I);
        font-size: 16px;
        line-height: 20px;
        transition: 0.3s ease;
        @mixin max-line-length 1;
      }
    }

    &--dropdown {
      border-radius: 0px !important;
      z-index: 20;
      background-color: var(--white);
      border: 1px solid var(--Gray-III);

      &:not(.is-active) {
        visibility: hidden;
        opacity: 0;
      }

      .choices__item,
      .choices__input {
        font-size: ac(16px, 15px);
        line-height: 22px;
      }

      .choices__item {
        padding-right: 10px;
        &:first-child {
          display: none;
        }
        &::after {
          display: none;
        }
      }
    }

    & > div {
      width: 100%;
      max-height: calc(42px * 3);
    }

    .simplebar-horizontal {
      display: none;
    }

    .simplebar-vertical:nth-child(2 of .simplebar-vertical) {
      display: none;
    }
    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .choices__inner,
  .choices__list--single {
    padding: 0;
    padding-bottom: 0 !important;
  }

  .choices__inner {
    background-color: var(--white);
    border: 0;
  }

  .choices__item--selectable {
    font-size: 15px !important;
    font-weight: 300;
    color: var(--black);
    line-height: 30px;
    opacity: 1;

    &.is-highlighted {
      color: var(--forest);
    }
  }
}

.agreement {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .checkBox {
    display: flex;
    position: relative;
    width: 100%;

    input {
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      visibility: hidden;
    }

    input:checked + span:before {
      background: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.40657 11.5732C5.32827 11.6515 5.20132 11.6515 5.12301 11.5732L0.739907 7.19013C0.420031 6.87026 0.420031 6.35164 0.739907 6.03176C1.05978 5.71189 1.5784 5.71189 1.89828 6.03176L5.26479 9.39828L14.4232 0.239906C14.743 -0.0799692 15.2617 -0.0799685 15.5815 0.239907C15.9014 0.559782 15.9014 1.0784 15.5815 1.39828L5.40657 11.5732Z' opacity='1' fill='%233C3C3B'/%3E%3C/svg%3E%0A")
        center / 16px 12px no-repeat;
    }

    span {
      padding-left: ac(48px, 30px);
      margin-bottom: 0;
      color: var(--Gray-I);
      cursor: pointer;

      &,
      a {
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
      }

      a {
        display: inline-block;
        color: var(--forest);
        text-decoration: underline;
        transition: 0.3s ease;

        &:hover {
          opacity: 0.8;
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: ac(4px, 0px);
        width: ac(24px, 20px);
        height: ac(24px, 20px);
        border: 1px solid #3c3c3b;
        transition: 0.3s ease;
        background: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.40657 11.5732C5.32827 11.6515 5.20132 11.6515 5.12301 11.5732L0.739907 7.19013C0.420031 6.87026 0.420031 6.35164 0.739907 6.03176C1.05978 5.71189 1.5784 5.71189 1.89828 6.03176L5.26479 9.39828L14.4232 0.239906C14.743 -0.0799692 15.2617 -0.0799685 15.5815 0.239907C15.9014 0.559782 15.9014 1.0784 15.5815 1.39828L5.40657 11.5732Z' opacity='0' fill='%233C3C3B'/%3E%3C/svg%3E%0A")
          center / 16px 12px no-repeat;
      }
    }
  }
}
