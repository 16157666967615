.we-working {
  background-color: var(--Lime-III);
  padding-bottom: ac(108px, var(--mmt));

  & + .work {
    &:not(.work--banking) {
      margin-top: 0;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 1;

    background-color: var(--white);
    box-shadow: 0px 0px 64px 0px rgba(0, 0, 0, 0.12),
      0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 2px 0px rgba(0, 0, 0, 0.24);
  }

  &__col {
    padding: ac(83px, 25px) ac(64px, 15px);
  }

  &__bg {
    max-height: 487px;
    height: 100%;
  }

  &__title {
    margin-top: ac(24px, 14px);
  }

  &__tag-box {
    margin-top: ac(16px, 10px);
    grid-column-gap: ac(12px, 10px) !important;
    grid-row-gap: ac(6px, 5px) !important;
  }

  &__tag {
    /*padding: 3px ac(15px, 5px);*/

    color: var(--Gray-I);
    font-size: ac(18px, 16px);
    font-weight: 600;
    line-height: normal;

    /*border: 1px solid var(--Gray-III);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);*/
    padding-left: 10px;
    position: relative;

    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      content: '';
      background: var(--forest);
      border-radius: 50%;
      width: 5px;
      height: 5px;
    }
  }

  &__btn {
    margin-top: ac(32px, 15px);
  }

  @mixin md {
    &__bg {
      max-height: ac(487px, 200px);
    }

    &__img {
      max-width: ac(580px, 250px);
    }
  }

  @mixin lg {
    &__wrapper {
      width: calc(100% + 56px);
      margin-left: -28px;
    }
  }

  @mixin max-md {
    &__wrapper {
      max-width: 500px;
      margin: 0 auto;
    }

    &__img {
      aspect-ratio: 1 / 1;
    }
  }

  @mixin max-sm {
    &__btn {
      width: 100%;
    }
  }
}
