.needs {
  margin-top: ac(96px, var(--mmt));

  &__wrapper {
    gap: ac(64px, 25px);
  }

  &__img-box {
    gap: ac(16px, 10px, 320);
    padding-bottom: ac(56px, 0px, 320);
  }

  &__img {
    width: 100%;
    aspect-ratio: 1 / 1;

    &:nth-child(even) {
      transform: translateY(ac(56px, 15px, 320));
    }
  }

  &__icon {
    width: ac(122px, 65px);
    height: ac(222px, 110px);
  }

  &__suptitle {
    margin-top: ac(40px, 20px);
  }

  &__title {
    margin-top: ac(20px, 10px);
  }

  &__subtitle {
    margin-top: ac(24px, 15px);
    color: var(--forest);
    font-size: ac(22px, 18px);
    font-weight: 700;
    line-height: ac(32px, 24px);
    max-height: calc(10 * ac(32px, 24px));
    @mixin max-line-length 10;
  }

  &__btn {
    font-weight: 800;
    margin-top: ac(32px, 20px);
  }

  @mixin md {
    &__img-box {
      max-width: ac(616px, 300px);
      flex-shrink: 0;
    }
  }
}
