.rewarded {
  margin-top: ac(148px, var(--mmt));

  &__title {
    margin-top: ac(20px, 8px);
  }

  &__title-box {
    width: 100%;
  }

  &__subtitle {
    width: 100%;
    color: var(--Gray-I);
    font-size: ac(22px, 18px);
    line-height: ac(32px, 24px);
    font-weight: 700;
  }

  &__tabs-box {
    align-items: center;
    margin-top: ac(56px, 20px);
  }

  &__thumb {
    .swiper-slide {
      cursor: pointer;

      &:hover {
        .rewarded__tab {
          color: #28684c;
        }
      }

      &-thumb-active {
        cursor: default;

        .rewarded__tab {
          color: #28684c;
          background-color: var(--Lime-III);
        }

        .rewarded__icon {
          border-color: var(--Gray-III);
          background-color: transparent;

          &::before,
          &::after {
            background-color: var(--Gray-II);
          }

          &::after {
            height: 0;
          }
        }
      }
    }
  }

  &__tab {
    padding: ac(14px, 10px) ac(19px, 10px) ac(14px, 10px) ac(23px, 10px);
    height: 100%;
    border: 1px solid var(--Gray-III);

    color: var(--Gray-I);
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    transition: 0.3s ease;
  }

  &__icon {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border: 1px solid var(--forest);
    background-color: var(--forest);
    transition: 0.3s ease;

    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: var(--Lime-I);
      transition: 0.3s ease;
    }

    &::before {
      width: 16px;
      height: 2px;
    }
    &::after {
      width: 2px;
      height: 16px;
    }
  }

  &__slider {
    overflow: hidden;

    .swiper-slide {
      opacity: 0 !important;

      &-active {
        opacity: 1 !important;
      }
    }
  }

  &__card {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__head {
    padding: ac(36px, 20px) ac(32px, 15px);

    border-bottom: 1px solid var(--Gray-III);
    background: var(--forest);

    color: var(--Lime-II);
    font-size: ac(28px, 20px);
    font-style: normal;
    font-weight: 700;
    line-height: ac(36px, 26px);
    letter-spacing: -0.07px;
  }

  &__bottom {
    height: 100%;
    padding: ac(31px, 20px) ac(32px, 15px) ac(40px, 20px);
    gap: ac(32px, 15px);
    background-color: #1e4737;
  }

  &__location {
    color: var(--Lime-III);
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;

    span {
      margin-left: 5px;
      color: var(--Lime-I);
    }

    & > *:only-child {
      display: none;
    }
  }

  &__scroll {
    width: calc(100% + 10px);
    padding-right: 10px;
    max-height: calc(ac(32px, 24px) * 6);
  }

  &__descr {
    color: var(--white);
    font-size: ac(22px, 18px);
    line-height: ac(32px, 24px);
    font-weight: 300;
  }

  &__accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @mixin lg {
      display: none;
    }
  }

  &__accordion-content {
    width: 100%;
    max-width: 100%;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.4s ease;
    padding-bottom: 0;

    .rewarded__bottom {
      border-left: 1px solid var(--Gray-III);
      border-right: 1px solid var(--Gray-III);
      border-bottom: 1px solid var(--Gray-III);
    }

    &.active {
      opacity: 1;
      max-height: 800px;
      padding-bottom: 10px !important;
    }
  }

  &__accordion-toggle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.2s ease;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .rewarded__tab {
      width: 100%;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    &.active {
      margin-bottom: 0 !important;

      .rewarded__tab {
        color: var(--Lime-II);
        background-color: var(--forest);
        border-color: var(--Gray-III);
      }

      .rewarded__icon {
        border-color: var(--Lime-II);
        background-color: transparent;

        &::before,
        &::after {
          background-color: var(--Lime-II);
        }

        &::after {
          height: 0;
        }
      }
    }
  }

  @mixin md {
    &__title-box {
      width: ac(500px, 300px);
    }

    &__subtitle {
      width: ac(568px, 400px);
    }
  }

  @mixin lg {
    &__scrollbar {
      display: none;
    }

    &__tabs-box {
      justify-content: space-between;
    }

    &__slider-box {
      max-width: ac(568px, 460px, 1024);
    }

    &__thumb-box {
      max-width: ac(624px, 500px, 1024);
    }

    &__thumb {
      .swiper-wrapper {
        display: grid;
        grid-auto-rows: 1fr;
        grid-template-columns: 1fr 1fr;
      }

      .swiper-slide {
        &:not(:nth-child(1), :nth-child(2)) {
          .rewarded__tab {
            border-top: 0;
          }
        }

        &:nth-child(even) {
          .rewarded__tab {
            &:nth-child(odd) {
              border-left: 0;
            }
          }
        }
      }
    }
  }

  @mixin max-lg {
    &__tabs-box {
      flex-direction: column;
    }

    &__scrollbar {
      margin-top: 15px;
      width: 100% !important;
      height: 8px !important;

      background: var(--Lime-II);
      border-radius: 0 !important;
      opacity: 1;

      .swiper-scrollbar-drag {
        background: var(--forest);
        border-radius: 0 !important;
        opacity: 1;
        cursor: grab;
      }
    }

    &__slider-box {
      max-width: 600px;
      margin-top: 20px;
    }

    &__thumb-box {
      display: none;
    }

    &__slider-box {
      display: none;
    }
  }

  @mixin max-md {
    &__subtitle {
      margin-top: 20px;
    }
  }
}
