.studies {
  margin-top: ac(64px, var(--mmt));
  padding: ac(94px, var(--mmt)) 0;
  background-color: var(--Salmon-III);

  &--sectors {
    margin-top: 0;
  }

  &--tech {
    margin-top: 0;
  }

  &__wrapper {
    gap: ac(64px, 32px);
  }

  &__title {
    margin-top: ac(17px, 5px);

    span {
      color: var(--maroon);
    }
  }

  &__col {
    margin-left: auto;
  }

  .slider-btn.prev {
    margin-left: 0;
  }

  &__btn {
    margin-right: 24px;
  }

  &__slider-box {
    width: 100%;
    max-width: 960px;
  }

  &__card {
    height: 100%;
    cursor: pointer;

    &:hover {
      .studies__img {
        img {
          transform: scale(1.05);
        }
      }
      .studies__name {
        color: var(--forest);
      }
    }
  }

  &__img {
    border: 1px solid var(--Gray-III);
    flex-shrink: 0;
    width: 100%;
    aspect-ratio: 3 / 2;
    overflow: hidden;
    img {
      transition: 0.3s ease;
    }
  }

  &__tag-box {
    margin-top: ac(33px, 15px);
  }

  &__tag {
    padding: 3px ac(15px, 10px);
    border: 1px solid var(--Gray-III);
    background: var(--white);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);

    color: var(--Gray-I);
    font-size: ac(14px, 12px);
    font-weight: 600;
    line-height: ac(24px, 20px);
  }

  &__name {
    margin-top: ac(32px, 15px);
    font-size: ac(28px, 22px);
    line-height: ac(36px, 28px);
    font-weight: 700;
    color: #333;
    letter-spacing: -0.07px;
    max-height: calc(ac(36px, 28px) * 6);
    @mixin max-line-length 6;
    transition: 0.3s ease;
  }

  @mixin md {
    &__title-box {
      flex-shrink: 0;
      width: 100%;
      max-width: ac(702px, 350px);
    }

    &__col {
      margin-top: auto;
    }
  }

  @mixin lg {
    &__title-box {
      max-width: ac(702px, 500px);
    }
  }

  @mixin max-md {
    &--tech {
      .studies__col {
        width: 100%;
        justify-content: flex-start;

        .slider-btn {
          margin-right: 0;
        }
      }
    }
    &__col {
      order: 1;
      margin-right: auto;
      width: 100%;
      justify-content: flex-start;
    }

    &__btn {
      margin-right: 16px;
    }

    &__slider-box {
      max-width: 500px;
    }
  }

  @mixin max-xs {
    .slider-btn.prev {
      margin-left: auto;
    }

    &--tech {
      .slider-btn.prev {
        margin-left: 0;
      }
    }

    &__col {
      margin-left: ac(60px, 40px, 320, 374);
      margin-right: ac(60px, 40px, 320, 374);
      justify-content: center;
    }

    &__btn {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}
