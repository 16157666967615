.international {
  margin-top: ac(95px, var(--mmt));

  &__title {
    span {
      color: var(--navy);
    }
  }

  &__sep {
    margin-top: ac(39px, 15px);
    height: 1px;
    background: rgba(37, 95, 71, 0.5);
  }

  &__card-box {
    margin-top: ac(40px, 5px);
    gap: ac(24px, 15px);
  }

  &__card {
    overflow: hidden;
  }

  &__icon {
    width: ac(140px, 100px, 320);
    height: ac(140px, 100px, 320);
  }

  &__name {
    max-width: 13ch;
    font-size: ac(28px, 22px);
    font-weight: 700;
    line-height: ac(36px, 28px);
    letter-spacing: -0.07px;
    color: var(--navy);

    @mixin md {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-height: ac(72px, 56px);
    }
  }

  &__scroll {
    margin-top: 15px;
    max-height: calc(ac(28px, 24px) * 6);

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      right: 0px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__descr {
    padding: 0 10px;
  }
}
