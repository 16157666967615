.team-slider {
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: calc(100% - 156px);
        background-color: var(--Lime-III);
    }

    &__slider {
        background-color: var(--white);
        border: 1px solid var(--Gray-III);

        .swiper-slide {
            opacity: 0 !important;

            &-active {
                opacity: 1 !important;
            }
        }

        .team-slider {
            &__name {
                font-size: ac(36px, 24px);
                line-height: ac(48px, 32px);
                font-weight: 700;
                letter-spacing: -0.09px;
            }
            &__position {
                margin-top: 8px;
                color: var(--forest);
                font-size: ac(18px, 16px);
                line-height: ac(25px, 20px);
                font-weight: 700;
            }
        }
    }

    .slider-btn {
        z-index: 2;
        background-color: var(--white);
        border: 1px solid var(--Gray-III);

        &:hover {
            border-color: var(--forest);
            background-color: var(--forest);
        }
    }

    &__card {
        padding: ac(96px, 25px) 120px 208px;
    }

    &__thumb {
        padding: ac(32px, 15px) ac(64px, 20px);
        overflow: hidden;
        background: rgba(227, 231, 186, 0.8);

        .swiper-slide {
            cursor: pointer;
            &:nth-child(2) {
                .team-slider__person {
                    transform: translateX(ac(-10px, 0px));
                }
            }
            &-thumb-active {
                cursor: default;
            }
            &-thumb-active,
            &:hover {
                .team-slider__ava {
                    box-shadow: inset 0px 0px 0px 1px var(--maroon);
                }

                .team-slider__name,
                .team-slider__position {
                    color: var(--maroon);
                }
            }
        }

        .team-slider {
            &__name,
            &__position {
                transition: 0.3s ease;
            }

            &__name {
                font-size: ac(28px, 20px);
                line-height: ac(36px, 26px);
                font-weight: 700;
                letter-spacing: -0.07px;
            }

            &__position {
                margin-top: ac(8px, 5px);
                font-size: 16px;
                line-height: 22px;
                font-weight: 700;
            }
        }
    }

    &__scroll {
        margin-bottom: ac(32px, 20px);
        margin-top: ac(24px, 15px);
        padding-right: 10px;
        max-height: calc(ac(28px, 24px) * 7);

        .simplebar-scrollbar::before {
            background-color: var(--black);
        }

        .simplebar-track {
            background-color: rgba(0, 0, 0, 0.2);
        }
    }

    &__descr {
        color: var(--Gray-I);
    }

    &__btn-box {
        gap: ac(32px, 15px);
    }
    &__contact-box {
        gap: 16px;
    }

    &__soc {
        width: 44px;
        height: 45px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        transition: 0.3s ease;

        &--msn {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='45' fill='none'%3E%3Cpath fill='%23255F47' d='M0 .371h44v44H0z'/%3E%3Cpath fill='%23fff' d='M9.578 15.426a3.125 3.125 0 0 1 3.047-2.43h18.75a3.125 3.125 0 0 1 3.047 2.43L22 23.018 9.578 15.426ZM9.5 17.21v11.1l9.067-5.56L9.5 17.21Zm10.564 6.458L9.798 29.96a3.125 3.125 0 0 0 2.827 1.786h18.75A3.126 3.126 0 0 0 34.2 29.96l-10.266-6.293L22 24.85l-1.936-1.181Zm5.369-.916L34.5 28.31v-11.1l-9.067 5.54v.002Z'/%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='45' fill='none'%3E%3Cpath fill='%23E3E7BA' d='M0 .371h44v44H0z'/%3E%3Cpath fill='%23255F47' d='M9.578 15.426a3.125 3.125 0 0 1 3.047-2.43h18.75a3.125 3.125 0 0 1 3.047 2.43L22 23.018 9.578 15.426ZM9.5 17.21v11.1l9.067-5.56L9.5 17.21Zm10.564 6.458L9.798 29.96a3.125 3.125 0 0 0 2.827 1.786h18.75A3.126 3.126 0 0 0 34.2 29.96l-10.266-6.293L22 24.85l-1.936-1.181Zm5.369-.916L34.5 28.31v-11.1l-9.067 5.54v.002Z'/%3E%3C/svg%3E");
            }
        }

        &--tel {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='45' fill='none'%3E%3Cpath fill='%23255F47' d='M0 .371h44v44H0z'/%3E%3Cpath fill='%23fff' d='M30.778 32.871c-2.296 0-4.57-.546-6.82-1.639a22.15 22.15 0 0 1-6.027-4.292 22.152 22.152 0 0 1-4.292-6.028C12.546 18.662 12 16.39 12 14.093a1.186 1.186 0 0 1 1.222-1.222h4.083c.26 0 .491.088.695.264.204.176.324.403.361.68l.75 3.64c.037.259.033.495-.014.708a1.066 1.066 0 0 1-.292.541l-2.75 2.778a21.162 21.162 0 0 0 3.292 4.25 19.379 19.379 0 0 0 4.208 3.167l2.64-2.667c.166-.204.375-.347.624-.43.25-.084.505-.098.764-.042l3.472.75c.278.055.505.19.681.402.176.214.264.46.264.737v4a1.186 1.186 0 0 1-1.222 1.222Z'/%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='45' fill='none'%3E%3Cpath fill='%23E3E7BA' d='M0 .371h44v44H0z'/%3E%3Cpath fill='%23255F47' d='M30.778 32.871c-2.296 0-4.57-.546-6.82-1.639a22.15 22.15 0 0 1-6.027-4.292 22.152 22.152 0 0 1-4.292-6.028C12.546 18.662 12 16.39 12 14.093a1.186 1.186 0 0 1 1.222-1.222h4.083c.26 0 .491.088.695.264.204.176.324.403.361.68l.75 3.64c.037.259.033.495-.014.708a1.066 1.066 0 0 1-.292.541l-2.75 2.778a21.162 21.162 0 0 0 3.292 4.25 19.379 19.379 0 0 0 4.208 3.167l2.64-2.667c.166-.204.375-.347.624-.43.25-.084.505-.098.764-.042l3.472.75c.278.055.505.19.681.402.176.214.264.46.264.737v4a1.186 1.186 0 0 1-1.222 1.222Z'/%3E%3C/svg%3E");
            }
        }

        &--in {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='45' fill='none'%3E%3Cpath fill='%23255F47' d='M0 .371h44v44H0z'/%3E%3Cpath fill='%23fff' d='M15.857 12.762c0 1.321-1.05 2.392-2.347 2.392-1.296 0-2.346-1.07-2.346-2.392 0-1.32 1.05-2.39 2.346-2.39s2.347 1.07 2.347 2.39Zm.019 4.305h-4.731V32.37h4.73V17.067Zm7.553 0h-4.701V32.37h4.701v-8.034c0-4.467 5.705-4.832 5.705 0v8.034h4.72v-9.69c0-7.538-8.442-7.263-10.425-3.553v-2.061Z'/%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='45' fill='none'%3E%3Cpath fill='%23E3E7BA' d='M0 .371h44v44H0z'/%3E%3Cpath fill='%23255F47' d='M15.857 12.762c0 1.321-1.05 2.392-2.347 2.392-1.296 0-2.346-1.07-2.346-2.392 0-1.32 1.05-2.39 2.346-2.39s2.347 1.07 2.347 2.39Zm.019 4.305h-4.731V32.37h4.73V17.067Zm7.553 0h-4.701V32.37h4.701v-8.034c0-4.467 5.705-4.832 5.705 0v8.034h4.72v-9.69c0-7.538-8.442-7.263-10.425-3.553v-2.061Z'/%3E%3C/svg%3E");
            }
        }
    }

    &__ava {
        width: ac(96px, 60px, 320);
        height: ac(96px, 60px, 320);
        transition: 0.3s ease;
    }

    &__img {
        aspect-ratio: 0.82 / 1;
    }

    @mixin lg {
        &__col {
            max-width: ac(555px, 350px);
        }

        .slider-btn {
            transform: translateY(-79px);

            &.prev {
                left: 32px;
            }
            &.next {
                right: 32px;
            }
        }
    }

    @mixin max-lg {
        &__wrapper {
            margin: 0 auto;
            max-width: 750px;
        }

        &__card {
            padding: 20px 15px ac(300px, 250px, 320, 1024);
        }

        &__img {
            max-height: ac(300px, 250px, 320, 1024);
        }

        .slider-btn {
            bottom: 150px;

            &.prev {
                left: 15px;
            }

            &.next {
                left: 60px;
            }
        }
    }

    @mixin max-sm {
        &__btn-box {
            justify-content: center;
        }
    }
}
