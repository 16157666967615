.sectors {
  position: relative;
  margin-top: ac(160px, var(--mmt));
  z-index: 1;

  &--client {
    margin-top: ac(168px, var(--mmt));
  }

  &--change,
  &--tech {
    background-color: var(--Lime-III);
    padding: ac(96px, var(--mmt)) 0 var(--mmt);
  }

  &--tech {
    margin-top: ac(108px, var(--mmt));
  }

  &--change {
    margin-top: ac(96px, var(--mmt));
  }

  &__wrapper {
    gap: ac(56px, 40px);
  }

  &__text-box {
    padding-top: ac(27px, 0px);

    .hero__link {
      margin-top: ac(39px, 20px);
      display: inline-flex;
      align-items: center;
      justify-content: center;

      padding: 0 19px 0 25px;
      border: 1px solid var(--forest);
      color: var(--forest);
      background-color: var(--white);

      font-weight: 700;
      font-size: 18px;
      line-height: 46px;
      white-space: nowrap;
      cursor: pointer;
      transition: 0.3s ease;

      &::before {
        display: none;
      }

      &::after {
        margin-left: 4px;
        width: 24px;
        height: 24px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23255f47' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
        transition: 0.3s ease;

        position: relative;
        background-color: unset;
        right: unset;
        transform: unset;
      }

      &:hover {
        background-color: var(--forest);
        color: var(--white);

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23FFFFFF' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
        }
      }

      @media (max-width: 640px) {
        width: 100%;
      }
    }
  }

  &__slider-box {
    width: 100%;
  }

  &__slider {
    max-width: ac(356px, 311px, 375, 1440);
    margin-left: 0;
  }

  &__title {
    margin-top: ac(19px, 5px);
  }

  &__subtitle {
    margin-top: ac(33px, 19px);
    max-height: calc(ac(28px, 24px) * 8);
    @mixin max-line-length 8;
  }

  &__btn {
    margin-top: ac(39px, 20px);
  }

  &__slide {
    min-height: ac(524px, 458px);
    display: flex;
    align-items: flex-end;
    overflow: hidden;

    &.swiper-slide-active {
      .sectors__card {
        max-height: 100%;
      }

      .sectors__name {
        line-height: ac(38px, 36px);
      }

      .sectors__card {
        padding: ac(32px, 24px) ac(32px, 24px) ac(72px, 64px);
        border-top: 0;
        background: linear-gradient(
          180deg,
          rgba(37, 95, 71, 0.8) 0%,
          #255f47 100%
        );
      }

      .sectors__simplebar {
        max-height: calc(ac(26px, 24px) * 10);
        opacity: 1;
        visibility: visible;
        transition: max-height 0.3s linear, opacity 0.3s 0.3s linear;
      }
    }
  }

  &__card {
    padding: ac(32px, 24px) ac(32px, 24px) ac(48px, 34px);
    width: 100%;
    height: 100%;
    max-height: ac(168px, 150px);
    justify-content: flex-end;

    border-top: 1px solid var(--mid-gray);
    background: var(--forest);
    box-shadow: 0px -4px 24px 0px rgba(0, 0, 0, 0.32);
    transition: 0.3s linear;

    &::before {
      content: '';
      position: absolute;
      bottom: ac(41px, 32px);
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - ac(32px, 24px) * 2);
      height: 1px;
      background-color: var(--Lime-I);
    }
  }

  &__name {
    margin-bottom: 16px;
    font-size: ac(28px, 24px);
    line-height: ac(40px, 36px);
    font-weight: 700;
    letter-spacing: -0.07px;
    color: var(--white);
    text-transform: capitalize;
    transition: 0.3s ease;
  }

  &__simplebar {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: max-height 0.3s linear, opacity 0s 0s linear;

    .simplebar-track.simplebar-vertical {
      right: -10px;
    }
  }

  &__descr {
    font-size: 16px;
    line-height: ac(26px, 24px);
    color: var(--white);
  }

  &__nav {
    margin-top: ac(40px, 32px);
    margin-left: ac(470px, 110px);
    width: 161px;
    display: flex;
    align-items: center;
    user-select: none;

    .swiper-pagination-fraction {
      position: static;
      display: flex;
      justify-content: center;
      gap: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 40px;
      color: var(--light-gray);

      .swiper-pagination-current {
        color: var(--mid-gray);
      }

      .swiper-pagination-total {
        color: var(--forest);
      }
    }

    .slider-btn {
      &.prev {
        margin-left: 0;
      }

      &.next {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  @mixin md {
    &__text-box {
      width: 100%;
      max-width: ac(392px, 300px);
      flex-shrink: 0;
    }

    &__slider-box {
      flex-shrink: 0;
      overflow: hidden;
    }

    &__name {
      letter-spacing: -0.06px;
    }
  }

  @mixin max-md {
    &__wrapper {
      flex-direction: column;
    }

    &__nav {
      margin-left: 0;
      margin-right: auto;
    }
  }

  @mixin max-sm {
    &__btn {
      width: 100%;
    }

    &__img {
      height: 115%;

      img {
        object-position: 0px 0px;
      }
    }
  }
}
