.included {
  margin-top: ac(96px, var(--mmt));

  &__wrapper {
    gap: ac(56px, 20px);
  }

  &__slider {
    background-color: var(--Blue-III);
  }

  .swiper-slide {
    opacity: 0 !important;

    &-active {
      opacity: 1 !important;
    }
  }

  &__card {
    padding: ac(48px, 20px) ac(56px, 15px);
    height: 100%;
  }

  &__line {
    width: ac(56px, 40px);
    height: ac(3px, 2px);
    background-color: var(--Gray-I);
    opacity: 0.5;
  }

  &__scroll {
    margin-top: ac(31px, 20px);
    margin-bottom: ac(40px, 20px);
    max-height: calc((ac(28px, 24px) * 10) + ac(16px, 10px));

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      right: -10px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__descr {
    & > *:not(:first-child) {
      margin-top: ac(16px, 10px);
    }
  }

  &__bottom {
    margin-top: auto;
    gap: ac(25px, 15px);
  }

  &__img {
    position: relative;
    width: 80px;
    height: 80px;
    padding: 7px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: conic-gradient(
        from -45deg,
        #818286 0deg 90deg,
        #dfe0e1 90deg 180deg,
        #818286 180deg 270deg,
        #dfe0e1 270deg 360deg
      );
      z-index: -1;
    }
  }

  &__name {
    color: var(--navy);
    font-size: ac(24px, 18px);
    font-weight: 800;
    line-height: ac(36px, 24px);
    letter-spacing: -0.06px;
  }

  &__position {
    margin-top: ac(8px, 5px);
    color: var(--Gray-I);
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  &__title {
    margin-top: ac(29px, 10px);

    span {
      color: var(--navy);
    }
  }

  &__subtitle {
    margin-top: ac(24px, 15px);
    font-size: ac(22px, 18px);
    font-weight: 300;
    line-height: ac(32px, 24px);
    max-height: calc(ac(32px, 24px) * 6);
    @mixin max-line-length 4;
  }

  &__btn-box {
    margin-top: ac(39px, 20px);
  }

  @mixin md {
    &__col {
      padding-top: ac(38px, 15px);
    }
    &__slider {
      max-width: ac(672px, 355px);
    }
  }

  @mixin max-md {
    &__btn-box {
      width: 100%;
      gap: 15px;
    }

    &__nav {
      margin-left: auto;
    }
  }

  @mixin max-xs {
    &__btn-box {
      flex-direction: column;
    }
  }
}
