.clients {
  margin-top: ac(65px, var(--mmt));
  padding: ac(108px, 48px) 0 ac(91px, 48px);
  background-color: var(--Blue-III);

  &--candidates {
    .clients__wrapper {
      flex-direction: row;
      align-items: center;

      @mixin media 769 {
        flex-direction: column;
      }
    }

    .clients__text-box {
      border-bottom: none;
    }
  }

  &--about,
  &--banking,
  &--tech {
    margin-top: ac(116px, var(--mmt));
    padding: 0;
    background-color: transparent;

    .clients {
      &__wrapper {
        align-items: flex-start;
        gap: 20px;
      }

      &__title {
        span {
          color: var(--forest);
        }
      }

      &__subtitle {
        & > * {
          margin-top: ac(23px, 15px);

          &:first-child {
            margin-top: 0;
            color: var(--forest);
            font-size: ac(22px, 18px);
            line-height: ac(32px, 22px);
            font-weight: 700;
          }
        }
      }

      &__text-box {
        gap: ac(40px, 20px);
        border-color: var(--forest);
      }
    }
  }
  &--banking,
  &--tech {
    margin-top: ac(54px, var(--mmt));
  }

  &__img {
    margin-right: 50px;
    aspect-ratio: 1.23 / 1;
  }

  &__shadow {
    position: absolute;
    bottom: ac(-32px, -13px);
    right: ac(-32px, -15px);
    width: calc(100% - ac(4px, 0px));
    height: calc(100% - 3px);

    background-color: var(--forest);
    z-index: -1;
  }
  &--dynamic {
    margin-top: 0;
  }

  &__wrapper {
    gap: ac(30px, 20px);
    display: flex;
    flex-direction: column;
  }

  &__btn {
    transition: 0.3s ease !important;

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  &__text-box {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0 0 ac(38px, 40px);
    border-bottom: 1px solid var(--Blue-I);
  }

  &__title {
    margin-top: ac(21px, 5px);
    span {
      color: var(--navy);
    }
  }

  &__subtitle {
    margin-top: ac(24px, 16px);

    & > *:last-child {
      max-height: calc(ac(28px, 24px) * 10);
      @mixin max-line-length 8;
    }

    & > * {
      &:first-child {
        color: var(--black);
        font-size: ac(24px, 18px);
        line-height: ac(36px, 24px);
        font-weight: 700;
      }
    }
  }

  .js-marquee-wrapper {
    display: flex;
  }

  &__marquee-box {
    padding-top: 10px;
    width: calc(100% + ac(300px, 150px));
    margin-left: ac(-150px, -75px);
    overflow: hidden;
    position: relative;
    height: ac(570px, 240px);
    mask: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 1) 30%,
      rgba(255, 255, 255, 1) 70%,
      transparent
    );
  }

  &__marquee {
    display: flex;
  }

  &__row {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    flex-wrap: wrap;
    padding-right: calc(ac(33px, 20px) * var(--client-multi));

    width: calc(
      ac(261px, 100px) * var(--client-multi) + ac(33px, 20px) *
        var(--client-multi)
    );
    column-gap: ac(33px, 20px);
    height: calc(ac(261px, 130px) * 3);
  }

  &__logo-box {
    position: relative;
    width: ac(261px, 100px);
    height: ac(261px, 100px);
    pointer-events: none;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.08))
      drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.04));

    &:nth-child(3n + 2) {
      transform: translate(
        calc(50% + ac(16px, 10px)),
        calc(-50% + ac(16px, 10px))
      );
    }

    &:nth-child(3n + 3) {
      transform: translate(0%, calc(-100% + ac(34px, 17px)));
    }
  }
  &__logo {
    position: absolute;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    width: 100%;
    height: 100%;
    background-color: var(--Gray-III);
    pointer-events: all;
    transition: 0.3s ease;

    &:hover {
      background-color: var(--forest);
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background-color: var(--white);
      clip-path: polygon(
        50% calc(0% + 1px),
        calc(100% - 1px) 50%,
        50% calc(100% - 1px),
        calc(0% + 1px) 50%
      );
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) translate3d(0, 0, 1px);
      object-fit: contain;
      z-index: 2;
    }
  }

  .clients__image {
    &-img {
      position: relative;

      aspect-ratio: 1 / 1;
      max-width: 90%;

      @mixin max-sm {
        max-width: calc(100% - 17px) !important;
        margin-left: 0 !important;
        margin-bottom: 12px !important;
      }
    }

    &-shadow {
      position: absolute;
      bottom: ac(-32px, -13px);
      right: ac(-32px, -15px);
      width: 100%;
      height: calc(100% - 3px);

      background-color: var(--forest);
      z-index: -1;

      &:nth-child(2) {
        background-color: var(--Salmon-I);
        bottom: ac(-32px, -17px);
        left: ac(-32px, -17px);
      }
    }
  }

  @mixin md {
    &__title-box {
      max-width: ac(603px, 320px);
    }

    &__subtitle {
      max-width: ac(453px, 320px);
    }

    &--about,
    &--banking,
    &--tech {
      .clients__marquee-box {
        margin-top: ac(0px, 100px);
        mask: linear-gradient(
          90deg,
          transparent,
          rgba(255, 255, 255, 1) 30%,
          rgba(255, 255, 255, 1) 90%,
          transparent
        );
      }
      .clients__title-box,
      .clients__subtitle {
        max-width: unset;
      }
      .clients__wrapper {
        flex-direction: row-reverse;
      }
      .clients__text-box {
        padding-top: ac(56px, 20px);
        max-width: ac(544px, 350px);
        padding-bottom: 0;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 0;
      }
    }

    &--tech {
      .clients__wrapper {
        gap: ac(88px, 40px);
      }
      .clients__img {
        margin-right: 0;
        width: 100%;
        flex-shrink: 0;
        max-width: ac(580px, 350px);
      }
      .clients__text-box {
        max-width: ac(556px, 350px);
      }
    }

    &--banking {
      .clients__wrapper {
        flex-direction: row;
      }
      .clients__marquee-box {
        position: absolute;
        width: ac(766px, 380px);
        left: 50%;
        margin-left: 0;
        transform: translateX(calc(-50% + ac(320px, 200px)));

        mask: linear-gradient(
          -90deg,
          transparent,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 1) 90%,
          transparent
        );
      }
    }
  }

  @mixin max-md {
    &__img {
      width: calc(100% - 15px);
      transform: translateX(-8px);
    }

    &--tech {
      .clients__img {
        transform: translateX(0px);
      }
    }
  }
}
