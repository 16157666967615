.advantages {
  margin-top: ac(95px, var(--mmt));

  &__title {
    span {
      color: var(--navy);
    }
  }

  &__subtitle {
    font-size: 16px;
    line-height: 24px;
    max-height: calc(24px * 10);
    @mixin max-line-length 10;
  }

  &__slider {
    margin-top: ac(63px, 25px);
  }

  &__card {
    height: 100%;
    min-height: ac(314px, 250px);
    padding: ac(31px, 15px) ac(30px, 15px) ac(31px, 15px) ac(31px, 15px);
    border: 1px solid var(--Gray-III);
    background: var(--Blue-III);
  }

  &__name {
    color: var(--Gray-I);
    font-size: ac(28px, 22px);
    line-height: ac(36px, 28px);
    font-weight: 700;
    letter-spacing: -0.07px;

    max-height: calc(ac(36px, 28px) * 3);
    @mixin max-line-length 3;
  }

  &__icon {
    width: ac(72px, 60px);
    height: ac(73px, 60px);
  }

  &__scroll {
    margin: ac(23px, 15px) 0;
    width: 100%;
    max-height: calc(24px * 6);

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      right: -10px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__descr {
    font-size: 16px;
    line-height: 24px;
  }

  &__line {
    background-color: var(--Blue-I);
  }

  .slider-btn {
    margin-top: 20px;
  }

  @mixin md {
    &__title {
      width: 100%;
      max-width: ac(674px, 250px);
    }
  }

  @mixin lg {
    .slider-btn {
      display: none;
    }

    .swiper-wrapper {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 24px;
    }
  }
}
