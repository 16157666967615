.timeline {
  margin-top: ac(96px, var(--mmt));
  padding: ac(108px, var(--mmt)) 0;
  background-color: var(--Lime-III);

  &__wrapper {
    gap: ac(48px, 20px);
  }

  &__subtitle {
    & > * {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;

      &:not(:last-child) {
        margin-bottom: ac(12px, 8px);
      }

      &:first-child {
        margin-bottom: ac(32px, 10px);

        font-size: ac(22px, 18px);
        line-height: ac(32px, 24px);
        font-weight: 700;
      }
    }
  }

  &__col {
    position: relative;
    width: 100%;
    background-color: var(--forest);
    padding: ac(55px, 35px) ac(32px, 15px) ac(55px, 35px) ac(48px, 25px);
    overflow: hidden;

    &.is-start {
      &::before {
        inset: -100px 0 0 0;
      }
    }
    &.is-end {
      &::before {
        inset: 0 0 -100px 0;
      }
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      width: calc(100% - 30px);
      background: linear-gradient(
        180deg,
        #255f47 0%,
        rgba(37, 95, 71, 0) 25%,
        rgba(37, 95, 71, 0) 75.52%,
        #255f47 100%
      );
      pointer-events: none;
      z-index: 2;

      transition: all ease 250ms;
    }
  }

  &__scrollbar {
    position: absolute;
    top: ac(48px, 25px);
    right: ac(32px, 10px);
    flex-shrink: 0;
    width: ac(16px, 10px) !important;
    height: calc(100% - ac(96px, 50px)) !important;
    background: #1f503b;
    overflow: hidden;
    z-index: 2;

    .swiper-scrollbar-drag {
      border-radius: 0;
      border: ac(4px, 2px) solid #1f503b;

      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0.2) 100%
        ),
        #255f47;
    }
  }

  &__slider {
    height: 390px;

    padding-bottom: 40px;
    /* padding-bottom: 50px; */
  }
  &__scroll {
    width: 100%;
    max-height: 500px;
    margin-top: ac(33px, 16px);

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      right: -10px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__year {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: 25px;
    font-size: ac(36px, 24px);
    line-height: ac(48px, 32px);
    font-weight: 700;
    letter-spacing: -0.09px;
    color: var(--Lime-II);

    &::before {
      content: '';
      width: 13px;
      height: 13px;
      margin-right: 16px;
      border: 2px solid var(--Lime-II);
    }
  }

  &__descr {
    padding-right: ac(56px, 25px);
    padding-left: 16px;
    border-left: 1px solid var(--Lime-II);
    color: var(--white);
  }

  @mixin md {
    &__wrapper {
      align-items: center;
    }

    &__title-box {
      width: 100%;
      max-width: ac(510px, 300px);
    }

    &__col {
      max-width: ac(666px, 400px);
    }
  }

  @mixin max-lg {
    &__row {
      flex-direction: column;
    }

    &__year {
      margin-bottom: 5px;
    }
  }
}
