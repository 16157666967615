.work {
  &--home,
  &--about-alt {
    .work {
      &__img {
        aspect-ratio: 1.23 / 1;
      }

      &__shadow {
        bottom: ac(-32px, -13px);
        right: ac(-32px, -15px);
        width: calc(100% - ac(4px, 0px));
        height: calc(100% - 3px);

        background-color: var(--forest);
      }
    }
  }

  &--about {
    .work {
      &__shadow {
        bottom: ac(-32px, -13px);
        right: ac(-32px, -15px);
        width: 100%;
        height: calc(100% - 3px);

        background-color: var(--forest);
      }
    }
  }

  &--candid {
    margin-top: ac(108px, var(--mmt));
    z-index: 2;

    .work {
      &__wrapper {
        gap: ac(86px, 40px);
      }

      &__shadow {
        bottom: ac(-32px, -17px);
        right: ac(-32px, -17px);
        width: 100%;
        height: 100%;

        background-color: var(--Lime-I);
      }

      &__text-box {
        overflow: hidden;
      }

      &__scroll {
        width: 100%;
        margin-top: ac(26px, 16px);
        max-height: 432px;

        .simplebar-scrollbar::before {
          background-color: var(--black);
        }

        .simplebar-track {
          right: 0px;
          background-color: rgba(0, 0, 0, 0.2);
        }
      }

      &__subtitle {
        padding-bottom: 2px;
        padding-right: 10px;

        & > * {
          strong {
            font-size: ac(24px, 18px);
            line-height: ac(36px, 24px);
          }
          font-size: ac(18px, 16px);
          line-height: ac(24px, 20px);
          font-weight: 400;

          &:not(:last-child) {
            margin-bottom: ac(24px, 10px);
          }
        }
      }
    }
  }

  &--client {
    padding: ac(64px, var(--mmt)) 0 ac(96px, var(--mmt));
    background-color: var(--Blue-III);

    .work {
      &__wrapper {
        gap: ac(88px, 40px);
        position: relative;
        z-index: 1;
      }

      &__img {
        aspect-ratio: 1.23 / 1;
      }

      &__shadow {
        bottom: ac(-32px, -17px);
        right: ac(-32px, -17px);
        width: 100%;
        height: 100%;

        background-color: var(--Blue-I);
      }

      &__text-box {
        width: 100%;
        overflow: hidden;
      }

      &__title {
        margin-top: ac(21px, 10px);

        span {
          color: var(--navy);
        }
      }

      &__scroll {
        width: 100%;
        margin-top: ac(24px, 16px);
        max-height: 732px;

        .simplebar-scrollbar::before {
          background-color: var(--black);
        }

        .simplebar-track {
          right: -10px;
          background-color: rgba(0, 0, 0, 0.2);
        }
      }

      &__subtitle {
        padding-bottom: 2px;

        & > * {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          padding-right: ac(30px, 0px);

          &:not(:last-child) {
            margin-bottom: ac(24px, 10px);
          }

          &:first-child {
            font-size: ac(18px, 16px);
            line-height: ac(28px, 20px);
            padding-right: 0px;
            font-weight: 700;
          }
        }
      }

      &__btn {
        margin-top: ac(30px, 20px);
      }
    }
  }

  &--client-sec {
    margin-top: ac(96px, var(--mmt));
    padding: ac(96px, var(--mmt)) 0 ac(119px, var(--mmt));
    background-color: var(--Salmon-III);

    .work {
      &__wrapper {
        gap: ac(56px, 40px);
        position: relative;
        z-index: 1;
      }

      &__img {
        aspect-ratio: 1.23 / 1;
      }

      &__shadow {
        bottom: ac(-24px, -17px);
        left: ac(-24px, -17px);
        width: 100%;
        height: 100%;

        background-color: var(--Salmon-I);
      }

      &__text-box {
        width: 100%;
        overflow: hidden;
      }

      &__title {
        margin-top: ac(21px, 10px);

        span {
          color: var(--maroon);
        }
      }

      &__scroll {
        width: 100%;
        margin-top: ac(24px, 16px);
        max-height: calc(ac(32px, 24px) * 6);

        .simplebar-scrollbar::before {
          background-color: var(--black);
        }

        .simplebar-track {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }

      &__subtitle {
        padding-right: 10px;
        &,
        & > * {
          font-size: ac(22px, 18px);
          font-weight: 300;
          line-height: ac(32px, 24px);
        }
      }

      &__btn {
        margin-top: ac(30px, 20px);
        padding: 0px 19px 0 30px;
      }
    }
  }

  &--home,
  &--join,
  &--about-alt {
    margin-top: ac(96px, var(--mmt));
    padding-bottom: ac(122px, var(--mmt));

    .work {
      &__subtitle {
        margin-top: ac(24px, 18px);
        max-height: calc(ac(32px, 28px) * 6);
        @mixin max-line-length 6;
      }

      &__title {
        margin-top: ac(21px, 5px);
      }
    }
  }

  &--london,
  &--new-york,
  &--galway {
    margin-top: ac(96px, var(--mmt));
    padding: ac(96px, var(--mmt)) 0;
    background-color: var(--Blue-III);
    border-bottom: 1px solid var(--Gray-III);

    .work {
      &__wrapper {
        gap: ac(56px, 40px);
        justify-content: flex-end;
      }

      &__img {
        aspect-ratio: 1.49 / 1;
      }

      &__shadow {
        top: ac(-24px, -17px);
        right: ac(-24px, -17px);
        width: 100%;
        height: 100%;

        background-color: var(--Blue-I);
      }

      &__text-box {
        width: 100%;
      }

      &__title {
        margin-top: ac(21px, 10px);

        span {
          color: var(--navy);
        }
      }

      &__scroll {
        width: 100%;
        margin-top: ac(24px, 16px);
        max-height: 732px;

        .simplebar-scrollbar::before {
          background-color: var(--black);
        }

        .simplebar-track {
          right: -10px;
          background-color: rgba(0, 0, 0, 0.2);
        }
      }

      &__subtitle {
        padding-bottom: 2px;

        & > * {
          font-size: ac(18px, 16px);
          line-height: ac(28px, 20px);
          font-weight: 400;

          &:not(:last-child) {
            margin-bottom: ac(15px, 8px);
          }

          &:first-child {
            margin-bottom: ac(15px, 10px);

            font-size: ac(22px, 18px);
            line-height: ac(32px, 24px);
            font-weight: 700;
            color: var(--navy);
          }
        }
      }

      &__btn {
        margin-top: ac(30px, 20px);
      }
    }
  }

  &--new-york {
    background-color: var(--Lime-III);

    .work {
      &__title {
        span {
          color: var(--forest);
        }
      }

      &__subtitle {
        & > * {
          &:first-child {
            color: var(--forest);
            font-weight: 700;
          }
        }
      }

      &__shadow {
        background-color: var(--Lime-I);
      }
    }
  }

  &--galway {
    background-color: var(--Salmon-III);

    .work {
      &__title {
        span {
          color: var(--maroon);
        }
      }

      &__subtitle {
        & > * {
          &:first-child {
            color: var(--maroon);
            font-weight: 700;
          }
        }
      }

      &__shadow {
        background-color: var(--Salmon-I);
      }
    }
  }

  &--join {
    padding-bottom: 0;

    .work {
      &__wrapper {
        gap: ac(88px, 40px);
      }

      &__img {
        aspect-ratio: 1.23 / 1;
      }

      &__shadow {
        bottom: ac(-32px, -17px);
        right: ac(-32px, -17px);
        width: 100%;
        height: 100%;

        background-color: var(--Blue-I);
      }

      &__title {
        span {
          color: var(--navy);
        }
      }

      &__subtitle {
        margin-top: ac(24px, 16px);
        color: var(--Gray-I);
        font-weight: 400;
        font-size: ac(24px, 18px);
        line-height: ac(36px, 26px);
        max-height: calc(ac(36px, 26px) * 6);
        @mixin max-line-length 6;
      }
    }
  }

  &--about {
    margin-top: ac(33px, var(--mmt));

    .work {
      &__wrapper {
        gap: ac(88px, 40px);
      }

      &__text-box {
        width: 100%;
      }

      &__scroll {
        width: 100%;
        margin-top: ac(24px, 16px);
        max-height: 732px;

        .simplebar-scrollbar::before {
          background-color: var(--black);
        }

        .simplebar-track {
          right: -10px;
          background-color: rgba(0, 0, 0, 0.2);
        }
      }

      &__subtitle {
        padding-right: 0px;

        & > * {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;

          &:not(:last-child) {
            margin-bottom: ac(12px, 8px);
          }

          &:first-child {
            margin-bottom: ac(16px, 10px);

            font-size: 18px;
            line-height: 25px;
            font-weight: 700;
          }
        }
      }
    }
  }

  &--diver,
  &--tech,
  &--change,
  &--dynamic,
  &--about-red,
  &--join-alt,
  &--candid-alt {
    margin-top: ac(128px, var(--mmt));

    .work {
      &__img {
        aspect-ratio: 1 / 1;
      }

      &__shadow {
        position: absolute;

        width: ac(307px, 300px);
        height: ac(307px, 300px);

        z-index: -1;

        &:nth-child(1) {
          background-color: var(--Salmon-II);
          top: ac(-32px, -17px);
          right: ac(-32px, -17px);
        }

        &:nth-child(2) {
          background-color: var(--Salmon-I);
          bottom: ac(-32px, -17px);
          left: ac(-32px, -17px);
        }
      }

      &__text-box {
        width: 100%;
      }

      &__title {
        margin-top: ac(21px, 5px);

        span {
          color: var(--maroon);
        }
      }

      &__scroll {
        width: 100%;
        margin-top: ac(24px, 16px);
        max-height: 732px;

        .simplebar-scrollbar::before {
          background-color: var(--black);
        }

        .simplebar-track {
          right: -10px;
          background-color: rgba(0, 0, 0, 0.2);
        }
      }

      &__subtitle {
        padding-bottom: 2px;

        & > * {
          font-size: ac(18px, 16px);
          line-height: ac(28px, 20px);
          font-weight: 400;

          &:not(:last-child) {
            margin-bottom: ac(12px, 8px);
          }

          &:first-child {
            margin-bottom: ac(24px, 10px);

            font-size: ac(22px, 18px);
            line-height: ac(32px, 24px);
            font-weight: 700;
            color: var(--maroon);
          }
        }
      }
    }
  }
  &--diver {
    & + .news,
    & + .are-experts {
      margin-top: ac(96px, var(--mmt));
    }
  }
  &--tech,
  &--change,
  &--dynamic {
    .work {
      &__title {
        margin-top: ac(21px, 5px);

        span {
          color: var(--forest);
        }
      }

      &__shadow {
        &:nth-child(1) {
          background-color: var(--Lime-II);
          top: ac(-32px, -17px);
          right: ac(-32px, -17px);
        }

        &:nth-child(2) {
          background-color: var(--forest);
          bottom: ac(-32px, -17px);
          left: ac(-32px, -17px);
        }
      }

      &__subtitle {
        padding-bottom: 2px;

        & > * {
          &,
          &:first-child {
            margin-bottom: 0;
            color: var(--Gray-I);
            font-size: ac(24px, 18px);
            line-height: ac(36px, 24px);
            font-weight: 700;
          }
        }
      }
    }
  }

  &--dynamic {
    .work__subtitle {
      p {
        &:not(:first-child) {
          margin-top: ac(15px, 10px);
        }
      }
    }
  }

  &--change {
    .work {
      &__title {
        margin-top: ac(21px, 10px);

        span {
          color: var(--navy);
        }
      }

      &__subtitle {
        padding-bottom: 2px;

        & > * {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;

          &:not(:last-child) {
            margin-bottom: ac(12px, 8px);
          }

          &:first-child {
            margin-bottom: ac(16px, 10px);
            color: var(--navy);

            font-size: 18px;
            line-height: 25px;
            font-weight: 700;
          }
        }
      }

      &__shadow {
        &:nth-child(1) {
          background-color: var(--Blue-I);
          top: ac(-32px, -17px);
          right: ac(-32px, -17px);
        }

        &:nth-child(2) {
          background-color: var(--Blue-II);
          bottom: ac(-32px, -17px);
          left: ac(-32px, -17px);
        }
      }
    }
  }

  &--join-alt {
    margin-top: ac(113px, var(--mmt));
    padding: ac(95px, var(--mmt)) 0 ac(128px, var(--mmt));
    background-color: var(--Lime-III);

    .work {
      &__img {
        z-index: 1;
      }

      &__shadow {
        &:nth-child(1) {
          background-color: var(--Lime-II);
        }

        &:nth-child(2) {
          background-color: var(--Lime-I);
        }
      }

      &__title {
        &::first-child {
          margin-top: ac(36px, 0px);
        }
        span {
          color: var(--forest);
        }
      }

      &__subtitle {
        & > * {
          &:first-child {
            color: var(--forest);
            font-weight: 700;
          }
        }
      }
    }
  }

  &--banking {
    margin-top: ac(174px, var(--mmt));

    .work {
      &__img {
        aspect-ratio: 1 / 1;
      }

      &__wrapper {
        gap: ac(99px, 40px);
      }

      &__shadow {
        position: absolute;

        width: 307px;
        height: 307px;

        z-index: -1;

        &:nth-child(1) {
          background-color: var(--Blue-II);
          top: ac(-32px, -17px);
          right: ac(-32px, -17px);
        }

        &:nth-child(2) {
          background-color: var(--Blue-I);
          bottom: ac(-32px, -17px);
          left: ac(-32px, -17px);
        }
      }

      &__text-box {
        width: 100%;
        overflow: hidden;
      }

      &__title {
        margin-top: ac(21px, 5px);

        span {
          color: var(--navy);
        }
      }

      &__scroll {
        width: 100%;
        margin-top: ac(24px, 16px);
        max-height: 732px;

        .simplebar-scrollbar::before {
          background-color: var(--black);
        }

        .simplebar-track {
          right: -10px;
          background-color: rgba(0, 0, 0, 0.2);
        }
      }

      &__subtitle {
        padding-bottom: 2px;

        & > * {
          font-size: ac(18px, 16px);
          line-height: ac(28px, 20px);
          font-weight: 400;

          &:not(:last-child) {
            margin-bottom: ac(12px, 8px);
          }

          &:first-child {
            margin-bottom: ac(24px, 10px);

            font-size: ac(22px, 18px);
            line-height: ac(32px, 24px);
            font-weight: 700;
            color: var(--navy);
          }
        }
      }

      &__btn-box {
        margin-top: ac(29px, 20px);
        gap: ac(20px, 10px);
      }

      &__btn {
        margin-top: 0;
      }
    }
  }

  &--sectors {
    margin-top: ac(174px, var(--mmt));

    .work {
      &__img {
        aspect-ratio: 1 / 1;
      }

      &__wrapper {
        gap: ac(99px, 40px);
      }

      &__shadow {
        position: absolute;

        width: 307px;
        height: 307px;

        z-index: -1;

        &:nth-child(1) {
          background-color: var(--Lime-II);
          top: ac(-32px, -17px);
          right: ac(-32px, -17px);
        }

        &:nth-child(2) {
          background-color: var(--Lime-I);
          bottom: ac(-32px, -17px);
          left: ac(-32px, -17px);
        }
      }

      &__text-box {
        width: 100%;
        overflow: hidden;
      }

      &__title {
        margin-top: ac(21px, 10px);

        span {
          color: var(--forest);
        }
      }

      &__scroll {
        width: 100%;
        margin-top: ac(24px, 16px);
        max-height: 732px;

        .simplebar-scrollbar::before {
          background-color: var(--black);
        }

        .simplebar-track {
          right: -10px;
          background-color: rgba(0, 0, 0, 0.2);
        }
      }

      &__subtitle {
        padding-bottom: 2px;

        & > * {
          font-size: ac(18px, 16px);
          line-height: ac(28px, 20px);
          font-weight: 400;

          &:not(:last-child) {
            margin-bottom: ac(12px, 8px);
          }

          &:first-child {
            margin-bottom: ac(24px, 10px);

            font-size: ac(22px, 18px);
            line-height: ac(32px, 24px);
            font-weight: 700;
            color: var(--Gray-I);
          }
        }
      }

      &__btn-box {
        margin-top: ac(29px, 20px);
        gap: ac(20px, 10px);
      }

      &__btn {
        margin-top: 0;
      }
    }
  }

  &--candid-alt {
    margin-top: ac(140px, var(--mmt));
    padding: ac(95px, var(--mmt)) 0 ac(96px, var(--mmt));
    background-color: var(--Blue-III);
    z-index: 1;

    .work {
      &__wrapper {
        gap: ac(88px, 40px);
      }
      &__btn {
        margin-top: ac(32px, 15px);
      }

      &__img {
        z-index: 1;
      }

      &__shadow {
        &:nth-child(1) {
          background-color: var(--Blue-I);
        }

        &:nth-child(2) {
          background-color: var(--Blue-II);
        }
      }

      &__title {
        span {
          color: var(--navy);
        }
      }

      &__subtitle {
        & > * {
          &:first-child {
            color: var(--navy);
            font-weight: 700;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &--about-alt {
    padding: ac(96px, var(--mmt)) 0;
    background: var(--Salmon-III);

    .work {
      &__img {
        z-index: 1;
      }

      &__shadow {
        background-color: var(--maroon);
      }

      &__title {
        span {
          color: var(--maroon);
        }
      }
    }
  }

  &__wrapper {
    gap: ac(96px, 40px);
  }

  &__subtitle {
    font-weight: 300;
    font-size: ac(22px, 18px);
    line-height: ac(32px, 28px);

    ul {
      & > li {
        position: relative;
        padding-left: ac(36px, 20px);

        &:not(:last-child) {
          margin-bottom: ac(12px, 10px);
        }

        &::before {
          content: '';
          position: absolute;
          top: ac(2px, -2px);
          left: ac(0px, -8px);
          width: 24px;
          height: 24px;
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%2375767A' d='m7.888 9.139 8.224 5.327v-5.14L7.888 4V9.14ZM7.888 14.903V20l8.06-5.18-3.965-2.569-4.095 2.652Z'/%3E%3C/svg%3E")
            center / contain no-repeat;
        }
      }
    }
  }

  &__btn {
    margin-top: ac(39px, 20px);
  }

  &__img {
    position: relative;

    @mixin media 500 {
      &.max-w-container {
        max-width: calc(100% - 34px) !important;
        width: 100%;

        img {
          position: absolute;
          inset: 0;
        }
      }

      &.max-w-container-right {
        max-width: calc(100% - 17px) !important;
        margin-right: auto !important;
        margin-left: 0 !important;
        width: 100%;

        img {
          position: absolute;
          inset: 0;
        }
      }

      &.max-w-container-left {
        max-width: calc(100% - 17px) !important;
        margin-right: 0 !important;
        margin-left: auto !important;
        width: 100%;

        img {
          position: absolute;
          inset: 0;
        }
      }
    }
  }

  &__shadow {
    position: absolute;
    z-index: -1;
  }

  @mixin md {
    &--home {
      .work__img {
        max-width: ac(584px, 380px);
      }

      .work__text-box {
        max-width: 544px;
        margin-top: 31px;
      }
    }

    &--about-alt {
      .work__img {
        transform: translateY(ac(-12px, 0px));
        max-width: ac(580px, 380px);
      }

      .work__text-box {
        max-width: 544px;
      }
    }

    &--london,
    &--new-york,
    &--galway {
      .work__wrapper {
        flex-direction: row-reverse;
      }

      .work__img {
        transform: translateY(ac(12px, 0px));
        max-width: ac(600px, 380px);
      }

      .work__text-box {
        max-width: ac(544px, 300px);
      }
    }

    &--join {
      .work__wrapper {
        align-items: flex-start;
      }

      .work__img {
        transform: translateY(0);
        max-width: ac(580px, 380px);
      }

      .work__text-box {
        max-width: 556px;
        margin-top: 16px;
      }
    }

    &--candid,
    &--client {
      .work__img {
        max-width: ac(580px, 350px);
      }

      .work__text-box {
        margin-top: 31px;
      }
    }

    &--client-sec {
      .work__img {
        max-width: ac(588px, 350px);
        margin-left: ac(24px, 0px);
      }

      .work__text-box {
        margin-top: 23px;

        .hero__link {
          margin-top: ac(39px, 20px);
          display: inline-flex;
          align-items: center;
          justify-content: center;

          padding: 0 19px 0 25px;
          border: 1px solid var(--forest);
          color: var(--forest);
          background-color: var(--white);

          font-weight: 700;
          font-size: 18px;
          line-height: 46px;
          white-space: nowrap;
          cursor: pointer;
          transition: 0.3s ease;

          &::before {
            display: none;
          }

          &::after {
            margin-left: 4px;
            width: 24px;
            height: 24px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23255f47' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
            transition: 0.3s ease;

            position: relative;
            background-color: unset;
            right: unset;
            transform: unset;
          }

          &:hover {
            background-color: var(--forest);
            color: var(--white);

            &::after {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23FFFFFF' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
            }
          }
        }
      }
    }

    &--about {
      .work__img {
        max-width: ac(580px, 350px);
      }

      .work__text-box {
        margin-top: 31px;
      }
    }

    &--diver,
    &--join-alt,
    &--candid-alt {
      .work__wrapper {
        flex-direction: row-reverse;
      }
      .work__img {
        margin-right: ac(32px, 0px, 1024);
        max-width: ac(549px, 350px);
      }

      .work__text-box {
        margin-right: auto;
        max-width: ac(544px, 331px);
      }
    }

    &--banking,
    &--sectors {
      .work__img {
        transform: translateY(ac(-17px, 0px));
        margin-left: ac(32px, 0px, 1024);
        max-width: ac(549px, 350px);
      }
    }

    &--diver,
    &--candid-alt {
      .work__text-box {
        max-width: ac(552px, 331px);
      }
    }

    &--tech,
    &--change,
    &--dynamic,
    &--about-red {
      .work__img {
        margin-left: ac(32px, 0px, 1024);
        max-width: ac(552px, 350px);
      }

      .work__text-box {
        max-width: ac(544px, 331px);
      }
    }

    &__wrapper {
      align-items: center;
    }

    &__img {
      flex-shrink: 0;
      width: 100%;
    }
  }

  @mixin max-md {
    &--home {
      margin-top: var(--mmt);
      background-color: var(--Lime-III);
    }

    &--london,
    &--new-york,
    &--galway,
    &--home,
    &--join,
    &--about,
    &--about-alt {
      .work__img {
        width: calc(100% - 15px);
        transform: translateX(-8px);
      }
    }

    &--candid,
    &--client {
      .work__wrapper {
        flex-direction: column;
      }
    }

    &--about,
    &--join-alt {
      .work__wrapper {
        gap: 40px;
      }
    }

    &__decor {
      top: 8px;
      bottom: unset;
      transform: translateY(-100%);

      img {
        object-position: bottom;
      }
    }

    &__wrapper {
      flex-direction: column-reverse;
    }

    &__img {
      margin: 0 auto;
      max-width: 500px;
      margin-bottom: 17px;
    }

    &__subtitle {
      font-weight: 400;
    }
  }

  @mixin max-sm {
    &__btn {
      width: 100%;
    }
  }
}
