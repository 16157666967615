.diversity {
  position: relative;
  margin-top: ac(102px, var(--mmt));
  padding: ac(96px, var(--mmt)) 0 ac(96px, var(--mmt));
  background-color: var(--Lime-III);
  z-index: 1;

  &__subtitle {
    margin-top: ac(24px, 12px);
  }

  &__card-box {
    width: 100%;
    margin-top: ac(88px, 30px);
    gap: ac(72px, 20px);
    padding: 0 ac(24px, 0px);
  }

  &__card {
    padding: ac(52px, 25px) ac(24px, 12px);
    border: 1px solid var(--Gray-III);
    background: var(--white);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
  }

  &__shadow {
    top: ac(-24px, -12px);
    left: ac(-24px, -12px);
    background-color: var(--Lime-II);
    z-index: -1;
  }

  &__counter {
    color: var(--forest);
    font-size: ac(56px, 30px);
    font-weight: 700;
    line-height: ac(68px, 36px);
    letter-spacing: -0.448px;
  }

  &__name {
    margin-top: 11px;

    color: var(--Gray-I);
    font-size: ac(28px, 18px);
    font-weight: 700;
    line-height: ac(36px, 22px);
    letter-spacing: -0.07px;
  }

  &__scroll {
    margin-top: 12px;
    width: 100%;
    max-height: calc(ac(28px, 24px) * 6);

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      right: -10px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__descr {
    text-align: center;
    color: var(--Gray-I);
  }

  @mixin sm {
    &__title,
    &__subtitle {
      max-width: 492px;
    }
  }

  @mixin max-md {
    &__card-box {
      width: 100%;
      max-width: 400px;
    }
  }
}
