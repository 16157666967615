.frameworks {
  margin-top: ac(116px, var(--mmt));

  &__wrapper {
    gap: ac(57px, 40px);
  }

  &__grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-right: calc(ac(33px, 20px));

    max-width: ac(900px, 520px);
    max-height: ac(408px, 238px);
    transform: scaleY(-1);

    column-gap: ac(33px, 20px);
  }

  &__logo-box {
    position: relative;
    width: ac(261px, 150px);
    height: ac(261px, 150px);
    pointer-events: none;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.08))
      drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.04));

    &:nth-child(4),
    &:nth-child(5) {
      transform: translate(
        calc(50% + ac(16px, 10px)),
        calc(-50% + ac(16px, 10px))
      );
    }
  }

  &__logo {
    display: flex;
    padding: ac(60px, 30px);
    position: absolute;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    width: 100%;
    height: 100%;
    background-color: var(--Gray-III);
    pointer-events: all;
    transition: 0.3s ease;
    transform: scaleY(-1);

    &:hover {
      background-color: var(--forest);
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background-color: var(--white);
      clip-path: polygon(
        50% calc(0% + 1px),
        calc(100% - 1px) 50%,
        50% calc(100% - 1px),
        calc(0% + 1px) 50%
      );
    }

    img {
      object-fit: contain;
      z-index: 2;
    }
  }

  @mixin md {
    &__grid {
      margin-right: -35px;
    }
    &__title-box {
      margin-right: auto;
      max-width: ac(320px, 200px);
    }
  }

  @mixin max-md {
    &__wrapper {
      gap: ac(40px, 20px, 320, 568);
    }

    &__grid {
      margin-right: ac(-20px, -6px, 320, 568);
      padding-right: ac(20px, 6px, 320, 568);
      max-width: ac(520px, 285px, 320, 568);
      max-height: ac(238px, 144px, 320, 568);
      column-gap: ac(20px, 6px, 320, 568);
    }

    &__logo-box {
      width: ac(150px, 89px, 320, 568);
      height: ac(150px, 89px, 320, 568);

      &:nth-child(4),
      &:nth-child(5) {
        transform: translate(
          calc(50% + ac(16px, 3px, 320, 568)),
          calc(-50% + ac(16px, 3px, 320, 568))
        );
      }
    }

    &__logo {
      padding: ac(30px, 20px, 320, 568);
    }
  }
}
