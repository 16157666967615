.news {
  padding: ac(96px, var(--mmt)) 0 ac(64px, var(--mmt));
  background-color: var(--Blue-III);

  &--article {
    position: relative;
    margin-top: ac(80px, var(--mmt));
    z-index: 1;

    + .news--article {
      padding-top: ac(20px, 0px) !important;
      margin-top: 0 !important;
    }
  }

  &--change {
    margin-top: ac(127px, var(--mmt));

    .news__subtitle {
      max-width: 520px;
    }
  }

  &--tech {
    margin-top: ac(127px, var(--mmt));

    .news__subtitle {
      max-width: 520px;
    }
  }

  &--candid {
    background-color: var(--Salmon-III);
    margin-top: ac(63px, var(--mmt));

    .news {
      &__title {
        span {
          color: var(--maroon);
        }
      }
    }
  }

  &--client {
    background-color: var(--white);
    padding-bottom: 0;

    .news {
      &__title {
        span {
          color: var(--forest);
        }
      }

      &__slider {
        margin-top: ac(49px, 25px);
      }
    }
  }

  &__title {
    margin-top: ac(24px, 5px);
    text-align: center;

    span {
      color: var(--navy);
    }
  }

  &__subtitle {
    max-width: 450px;
    margin-top: ac(24px, 12px);
    text-align: center;
    font-size: ac(22px, 18px);
    font-weight: 300;
    line-height: ac(32px, 24px);
  }

  &__slider {
    margin-top: ac(56px, 25px);
  }

  &__card {
    border: 1px solid var(--Gray-III);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
    background-color: var(--white);
    cursor: pointer;

    &:hover {
      .news__link {
        max-width: 100%;
        padding-left: 12px;

        &::before {
          max-width: 100%;
        }

        &::after {
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='14' fill='none'%3E%3Cpath fill='%23255F47' d='m2.317 14 7-7-7-7L.684 1.633 6.05 7 .684 12.367 2.317 14Z'/%3E%3C/svg%3E")
            center / contain no-repeat;
        }
      }

      background-color: var(--Lime-III);
    }
  }

  &__img {
    aspect-ratio: 3 / 2;

    img {
      object-position: top;
    }
  }

  &__bottom {
    gap: 31px;
    padding: ac(24px, 20px) ac(32px, 15px) ac(43px, 34px);
  }

  &__name {
    font-size: ac(28px, 20px);
    font-weight: 700;
    line-height: ac(36px, 26px);
    letter-spacing: -0.07px;
    max-height: calc(ac(36px, 26px) * 5);
    @mixin max-line-length 5;
  }

  &__link {
    max-width: 134px;
    margin-top: auto;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
    padding-right: 35px;
    transition: 0.3s ease;

    &::after,
    &::before {
      content: '';
      position: absolute;
      transition: 0.3s ease;
    }

    &::after {
      right: 9px;
      width: 10px;
      height: 14px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='14' fill='none'%3E%3Cpath fill='%23000' d='m2.317 14 7-7-7-7L.684 1.633 6.05 7 .684 12.367 2.317 14Z'/%3E%3C/svg%3E")
        center / contain no-repeat;
      transition: 0.3s ease;
    }

    &::before {
      right: 0;
      bottom: -12px;
      width: 100%;
      max-width: 32px;
      height: 1px;
      background-color: var(--forest);
    }
  }

  &__col {
    margin-top: ac(48px, 20px);

    @media (max-width: 640px) {
      max-width: 100%;
      width: 100%;
      justify-content: flex-start;

      .news__btn {
        margin-right: auto;
      }

      .slider-btn.prev {
        margin-left: 0 !important;
      }

      .slider-btn.next {
        margin-right: 0 !important;
      }
    }
  }

  .slider-btn {
    &.prev {
      margin-left: 15px;
    }

    &.next {
      margin-right: 15px;
    }
  }

  @mixin max-sm {
    &__wrapper {
      align-items: flex-start;
    }

    &__suptitle {
      text-align: left;
    }

    &__title {
      text-align: left;
    }

    &__link {
      max-width: 100%;
      padding-left: 12px;

      &::before {
        max-width: 100%;
      }
    }
  }
}
