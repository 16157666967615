.subscribe {
  padding: ac(88px, var(--mmt)) 0;
  overflow: hidden;
  &--news {
    margin-top: ac(80px, var(--mmt));
  }

  &__wrapper {
    max-width: 612px;
    margin-left: auto;
    padding: ac(72px, 25px) 0 ac(95px, 25px) ac(64px, 24px);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 100vw);
      height: 100%;
      background-color: var(--white);
      z-index: -1;
    }
  }

  &__title {
    color: var(--forest);
    font-size: ac(72px, 28px);
    font-weight: 700;
    line-height: ac(84px, 37px);
    letter-spacing: -0.72px;
  }

  &__form {
    margin-top: ac(40px, 20px);
    gap: ac(32px, 15px) ac(40px, 10px);
    justify-content: space-between;

    .agreement {
      max-width: 340px;
    }

    input {
      border: 1px solid var(--Gray-III);
      background: var(--white);

      padding: 12px 15px;
      @mixin transparent-bg-input var(--Gray-I);

      &,
      &::placeholder {
        color: var(--Gray-I);
      }

      &:hover {
        border-color: var(--forest);

        &,
        &::placeholder {
          color: var(--forest);
        }
      }
    }
  }

  &__img {
    @mixin media 551 {
      height: 250px !important;
      width: 100% !important;
    }
  }

  &__btn {
    font-weight: 800;
  }

  @mixin max-sm {
    padding-top: 200px;
    &__btn {
      width: 100%;
    }
  }
}
