h1,
h2 {
  font-weight: 300;
  color: var(--Gray-I);

  span {
    color: var(--forest);
    font-weight: 700;
  }
}

h1 {
  font-size: ac(56px, 28px);
  line-height: ac(68px, 38px);
  letter-spacing: -0.28px;

  span {
    letter-spacing: -0.448px;
  }

  &.sm {
    font-size: ac(48px, 28px);
    font-weight: 700;
    line-height: ac(64px, 37px);
    letter-spacing: -0.24px;
  }

  &.md {
    color: var(--white);
    font-size: ac(56px, 28px);
    line-height: ac(68px, 37px);
    font-weight: 300;

    span {
      color: var(--Lime-II);
    }
  }

  &.lg {
    font-size: ac(72px, 28px);
    font-weight: 300;
    line-height: ac(76px, 37px);
    letter-spacing: -0.72px;

    span {
      font-weight: 700;
      line-height: ac(84px, 37px);
      letter-spacing: -0.72px;
    }
  }
}

h2 {
  font-size: ac(48px, 28px);
  line-height: ac(64px, 34px);
  letter-spacing: -0.24px;
}

.sup1 {
  display: inline-flex;
  align-items: center;
  color: var(--forest);
  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
  text-transform: uppercase;

  &::before {
    content: '';
    width: 28px;
    height: 28px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' fill='none'%3E%3Cpath fill='%23255F47' d='M15.133 14 9 7.867 10.867 6l8 8-8 8L9 20.133 15.133 14Z'/%3E%3C/svg%3E")
      center / contain no-repeat;
  }

  &--gray {
    color: var(--Gray-I);

    &::before {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' fill='none'%3E%3Cpath fill='%234C4E50' d='M15.133 14 9 7.867 10.867 6l8 8-8 8L9 20.133 15.133 14Z'/%3E%3C/svg%3E")
        center / contain no-repeat;
    }
  }
  &--white {
    color: var(--white);

    &::before {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' fill='none'%3E%3Cpath fill='%23fff' d='M15.133 14 9 7.867 10.867 6l8 8-8 8L9 20.133 15.133 14Z'/%3E%3C/svg%3E")
        center / contain no-repeat;
    }
  }

  &--navy {
    color: var(--navy);

    &::before {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' fill='none'%3E%3Cpath fill='%23143366' d='M15.133 14 9 7.867 10.867 6l8 8-8 8L9 20.133 15.133 14Z'/%3E%3C/svg%3E")
        center / contain no-repeat;
    }
  }
}

.sub1 {
  &,
  & > * {
    color: var(--Gray-I);
    font-size: ac(22px, 18px);
    line-height: ac(32px, 26px);
    font-weight: 300;
  }
}

h3,
.h3 {
  color: var(--forest);
  font-size: ac(36px, 22px);
  font-weight: 700;
  line-height: ac(48px, 28px);
  letter-spacing: -0.09px;
}

h4,
.h4 {
  font-size: 48px;
  line-height: 64px;
  letter-spacing: -0.25%;
}

h4,
h5,
.h4,
.h5 {
  letter-spacing: -0.25%;
}

h5,
.h5 {
  font-size: 48px;
  line-height: 64px;
}

h6,
.h6 {
  font-size: 36px;
  line-height: 48px;
  font-weight: 500;
  letter-spacing: -0.15%;
}

a {
  display: inline-block;
}

.content-element {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  }

  h4 {
    color: var(--cl-medium-grey);
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }
  p {
    font-size: ac(18px, 16px);
    font-weight: 400;
    color: var(--cl-medium-grey);

    line-height: 144%;

    @mixin max-sm {
      line-height: 162%;
    }
  }

  li {
    font-size: ac(18px, 16px);
    font-weight: 400;
    color: var(--cl-medium-grey);

    line-height: 144%;

    @mixin max-sm {
      line-height: 162%;
    }
  }
  ol,
  ul {
    li {
      list-style-position: inside;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  li,
  p {
    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }
}

.dark-bg {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: var(--cl-white);
  }
}

@mixin max-md {
  h1,
  h2 {
    font-weight: 400;

    span {
      font-weight: 800;
    }
  }

  h1 {
    letter-spacing: -0.38px;

    span {
      letter-spacing: -0.55px;
    }
  }

  h2 {
    letter-spacing: -0.14px;

    span {
      letter-spacing: -0.6px;
    }
  }

  .sup1 {
    font-size: 14px;
    line-height: 24px;

    &::before {
      height: 24px;
      width: 24px;
    }
  }
}
