.need-help {
  padding: ac(96px, var(--mmt)) 0 ac(92px, var(--mmt));
  background-color: var(--Blue-III);

  &__wrapper {
    gap: ac(128px, 25px);
  }

  &__title {
    margin-top: ac(27px, 10px);

    span {
      color: var(--navy);
    }
  }

  &__scroll {
    max-height: calc(ac(32px, 20px) * 14);

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      right: -10px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__descr {
    padding-bottom: 2px;
    & > * {
      &:not(:first-child) {
        margin-top: ac(19px, 10px);
      }
      font-size: ac(22px, 16px);
      font-weight: 300;
      line-height: ac(32px, 20px);
    }
  }

  &__btn {
    margin-top: ac(41px, 20px);
  }
  @mixin md {
    &__title-box {
      max-width: ac(392px, 200px);
    }
  }
}
