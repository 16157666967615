.accordion {
  margin-top: ac(110px, var(--mmt));

  &__wrapper {
    gap: ac(48px, 20px);
  }

  &__subtitle {
    margin-top: ac(24px, 12px);
    color: var(--Gray-I0);
    font-size: ac(22px, 18px);
    font-weight: 300;
    line-height: ac(32px, 24px);
    max-height: calc(ac(32px, 24px) * 4);
    @mixin max-line-length 4;
  }

  &__item {
    width: 100%;
    overflow: hidden;
    border: 1px solid var(--Gray-III);
    transition: 0.3s ease !important;

    &:hover {
      background-color: var(--Lime-III);
    }

    &.is-active {
      background-color: var(--Lime-III);
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.12),
        0px 0px 32px 0px rgba(0, 0, 0, 0.04);
      border-color: var(--Lime-III);

      .accordion__content {
        visibility: visible;
        max-height: 1000px;
        opacity: 1;
      }

      .accordion__head {
        color: var(--black);
        border-bottom: 1px solid var(--Gray-III);
      }

      .accordion__arrow {
        &::before {
          transform: rotate(180deg);
        }
      }
    }

    &-box {
      width: 100%;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    padding: 15px 16px 15px ac(23px, 15px);
    gap: 20px;
    cursor: pointer;

    color: var(--Gray-I);
    font-size: ac(22px, 18px);
    font-weight: 700;
    line-height: ac(36px, 24px);
    letter-spacing: -0.11px;
    text-transform: capitalize;
    transition: 0.3s ease;
  }

  &__arrow {
    flex-shrink: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-left: auto;
    border: 1px solid var(--Gray-III);
    transition: 0.3s ease;

    &::before {
      content: '';
      width: 14px;
      height: 16px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='16' fill='none'%3E%3Cpath fill='%23255F47' d='m13.038 6.044-1.59 1.552-3.339-3.34V16H5.89V4.256l-3.34 3.34L.963 6.044 7 0l6.038 6.044Z'/%3E%3C/svg%3E");
      transition: 0.3s ease;
    }
  }

  &__content {
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: 0.3s ease;
  }

  &__text-box {
    padding: ac(31px, 15px);
    overflow: hidden;
  }

  &__scroll {
    width: 100%;
    max-height: calc(ac(30px, 20px) * 6);

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      right: -10px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__descr {
    &,
    & > * {
      font-size: ac(18px, 16px);
      font-weight: 400;
      line-height: ac(30px, 20px);
    }
  }

  @mixin md {
    &__wrapper {
      align-items: center;
    }
    &__title-box {
      max-width: ac(496px, 300px);
    }
  }
}
