.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 0 19px 0 25px;
  border: 1px solid var(--forest);
  background-color: #fafbf4;

  font-weight: 700;
  font-size: 18px;
  line-height: 46px;
  white-space: nowrap;
  color: var(--forest);
  cursor: pointer;
  transition: 0.3s ease;

  &__icon {
    margin-left: 4px;
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23255F47' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
    transition: 0.3s ease;
  }

  &:hover {
    background-color: var(--forest);
    color: var(--white);

    .btn__icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23FFFFFF' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
    }
  }

  &--no-bg {
    border: 1px solid var(--Lime-II);
    color: var(--white);
    background-color: transparent;

    .btn__icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23FFFFFF' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
    }

    &:hover {
      color: var(--forest);
      background-color: var(--Lime-II);

      .btn__icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23255F47' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
      }
    }
  }

  &--dark {
    border: 1px solid var(--Lime-II);
    color: var(--black);
    background-color: var(--Lime-II);

    .btn__icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23000' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
    }
  }

  &--wh {
    border: 1px solid var(--forest);
    color: var(--forest);
    background-color: var(--white);

    .btn__icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23255f47' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
    }
  }

  &--green {
    border: 1px solid var(--forest);
    color: var(--white);
    background-color: var(--forest);

    .btn__icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23fff' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
    }

    &:hover {
      color: var(--forest);
      background-color: var(--Lime-II);

      .btn__icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23255F47' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
      }
    }
  }

  &--leaf {
    border: 1px solid var(--forest);
    color: var(--Lime-II);
    background-color: var(--forest);

    .btn__icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23e3e7ba' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
    }

    &:hover {
      color: var(--forest);
      background-color: var(--Lime-II);

      .btn__icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23255F47' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
      }
    }
  }
}

.slider-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  background-color: var(--Gray-III);
  cursor: pointer;
  transition: 0.3s ease;
  user-select: none;
  z-index: 2;

  &__icon {
    width: 17px;
    height: 13px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.3s ease;
  }

  &.prev {
    margin-left: auto;
    .slider-btn__icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='13' fill='none'%3E%3Cpath fill='%23255F47' d='m6.197 12.383 1.591-1.63L4.364 7.33h12.042V5.054H4.364l3.424-3.425L6.197 0 0 6.191l6.197 6.192Z'/%3E%3C/svg%3E");
    }

    @media (max-width: 551px) {
      margin-left: 0;
    }
  }

  &.next {
    margin-left: 12px;
    margin-right: auto;
    .slider-btn__icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='13' fill='none'%3E%3Cpath fill='%23255F47' d='M10.21 0 8.617 1.63l3.424 3.424H0v2.275h12.042l-3.424 3.424 1.591 1.63 6.198-6.192L10.209 0Z'/%3E%3C/svg%3E");
    }
  }

  &.swiper-button-lock {
    display: none !important;
  }

  &.swiper-button-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    background-color: var(--forest);

    &.prev {
      .slider-btn__icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='13' fill='none'%3E%3Cpath fill='%23fff' d='m6.197 12.383 1.591-1.63L4.364 7.33h12.042V5.054H4.364l3.424-3.425L6.197 0 0 6.191l6.197 6.192Z'/%3E%3C/svg%3E");
      }
    }

    &.next {
      .slider-btn__icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='13' fill='none'%3E%3Cpath fill='%23fff' d='M10.21 0 8.617 1.63l3.424 3.424H0v2.275h12.042l-3.424 3.424 1.591 1.63 6.198-6.192L10.209 0Z'/%3E%3C/svg%3E");
      }
    }
  }
}
