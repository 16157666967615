.news-slider {
  margin-top: ac(48px, var(--mmt));
  z-index: 1;

  .swiper-slide {
    opacity: 0 !important;

    &-active {
      opacity: 1 !important;
    }
  }

  &__slider {
    background-color: var(--white);
    border: 1px solid var(--Gray-III, #d0d1d3);
    box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.16),
      0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  }

  &__card {
    padding: ac(57px, 25px) ac(46px, 5px) ac(57px, 25px) ac(56px, 15px);
    overflow: hidden;
  }

  &__scroll {
    max-height: 335px;
    width: calc(100% + 10px);
    padding-right: ac(20px, 15px);
    margin-bottom: 32px;

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__descr {
    h3 {
      margin-bottom: 15px;
    }

    h4 {
      color: var(--forest);
      font-size: ac(30px, 18px);
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.05px;
      text-transform: none;

      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }

    p {
      &:not(:last-child) {
        margin-bottom: ac(12px, 6px);
      }
      color: var(--Gray-I);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      &:has(b) {
        &:not(:last-child) {
          margin-bottom: ac(17px, 12px);
        }
        color: var(--forest);
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;

        b {
          font-weight: 700;
        }
      }
    }

    h2 {
      font-size: ac(48px, 32px);
      line-height: ac(64px, 36px);
      letter-spacing: -0.24px;
    }

    h3 {
      &:not(:first-child) {
        margin-top: ac(47px, 20px);
      }
      &:not(:last-child) {
        margin-bottom: ac(24px, 10px);
      }
    }

    h1 {
      font-size: ac(66px, 36px);
      line-height: ac(78px, 42px);
      margin-top: 0.8em;
      margin-bottom: 0.4em;
      font-weight: 700;
      color: var(--forest);
    }

    h2 {
      &:not(:first-child) {
        margin-top: ac(40px, 25px);
      }
      &:not(:last-child) {
        margin-bottom: ac(24px, 20px);
      }

      color: var(--forest);
      font-size: ac(56px, 32px);
      font-weight: 700;
      line-height: ac(68px, 36px);
      letter-spacing: -0.448px;
    }

    h3 {
      &:not(:first-child) {
        margin-top: ac(40px, 20px);
      }
      &:not(:last-child) {
        margin-bottom: ac(15px, 10px);
      }
      color: var(--forest);
      font-size: ac(28px, 22px);
      line-height: ac(36px, 26px);
      font-weight: 700;
      letter-spacing: -0.07px;
    }

    h4,
    h5,
    h6 {
      font-weight: 700;
      color: var(--forest);
      &:not(:first-child) {
        margin-top: ac(30px, 20px);
      }
      &:not(:last-child) {
        margin-bottom: ac(20px, 15px);
      }
    }

    h4 {
      font-size: ac(26px, 21px);
      line-height: ac(34px, 32px);
    }

    h5 {
      font-size: ac(24px, 20px);
      line-height: ac(32px, 30px);
    }

    h6 {
      color: var(--forest);
      font-size: ac(22px, 19px);
      font-weight: 700;
      line-height: ac(32px, 24px);
    }

    & > ul,
    & > ol {
      &:not(:last-child) {
        margin-bottom: ac(40px, 20px);
      }
    }

    p {
      &:not(:last-child) {
        margin-bottom: ac(16px, 10px);
      }
    }

    p,
    li {
      color: var(--black);
      font-size: ac(18px, 16px);
      font-weight: 400;
      line-height: ac(28px, 20px);

      a {
        display: inline-block;
        color: var(--forest);
        word-break: break-word;
        font-weight: 400;
        transition: 0.3s ease;

        &:hover {
          color: var(--maroon);
          text-decoration: none;
        }
      }
    }

    ul,
    ol {
      &:not(:first-child) {
        margin-top: ac(24px, 15px);
      }
    }

    ul {
      & > li {
        position: relative;
        padding-left: ac(36px, 20px);

        &:not(:last-child) {
          margin-bottom: ac(12px, 10px);
        }

        &::before {
          content: '';
          position: absolute;
          top: ac(2px, -2px);
          left: 0;
          width: 24px;
          height: 24px;
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%2375767A' d='m7.888 9.139 8.224 5.327v-5.14L7.888 4V9.14ZM7.888 14.903V20l8.06-5.18-3.965-2.569-4.095 2.652Z'/%3E%3C/svg%3E")
            center / contain no-repeat;
        }
      }
    }

    ol {
      list-style-type: auto;
      list-style-position: inside;
    }

    p {
      &:not(:last-child) {
        margin-bottom: ac(16px, 10px);
      }
    }

    ul {
      & > li {
        &::before {
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23C3D600' d='m7.888 9.139 8.224 5.327v-5.14L7.888 4V9.14ZM7.888 14.903V20l8.06-5.18-3.965-2.569-4.095 2.652Z'/%3E%3C/svg%3E")
            center / contain no-repeat;
        }
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    user-select: none;

    padding-bottom: 25px;

    .swiper-pagination-fraction {
      width: auto;
      position: static;
      display: flex;
      justify-content: center;
      gap: 8px;
      margin: 0 12px;
      font-weight: 600;
      font-size: 16px;
      line-height: 40px;
      color: var(--light-gray);

      .swiper-pagination-current {
        color: var(--mid-gray);
      }

      .swiper-pagination-total {
        color: var(--forest);
      }
    }

    .slider-btn {
      &.prev {
        margin-left: 0;
      }

      &.next {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &__btn {
    max-width: 100%;
    padding-left: 14px;
    margin-left: 180px;
    width: calc(100% - 200px);
    display: flex;
    align-items: center;
    font-weight: 700;
    padding-right: 35px;
    transition: 0.3s ease;
    cursor: pointer;

    &::after,
    &::before {
      content: '';
      position: absolute;
      transition: 0.3s ease;
    }

    &::after {
      right: ac(9px, 11px);
      width: 10px;
      height: 14px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='14' fill='none'%3E%3Cpath fill='%23255F47' d='m2.317 14 7-7-7-7L.684 1.633 6.05 7 .684 12.367 2.317 14Z'/%3E%3C/svg%3E")
        center / contain no-repeat;
      transition: 0.3s ease;
    }

    &::before {
      right: 0;
      bottom: ac(-10px, -7px);
      width: 100%;
      max-width: 100%;
      height: 1px;
      background-color: var(--forest);
    }
    &:hover {
      color: var(--forest);
    }
  }

  @mixin sm {
    &__nav {
      margin-top: ac(-48px, -40px);
      transform: translateY(ac(-45px, -16px));
      margin-left: 20px;
      padding-bottom: 0px;
    }
  }

  @mixin md {
    margin-top: ac(-289px, -100px);

    &__img {
      max-width: ac(530px, 250px);
      width: 100%;
      flex-shrink: 0;
    }

    &__card {
      width: 100%;
      overflow: hidden;
      max-width: calc(100% - ac(530px, 250px));
    }

    &__nav {
      margin-left: ac(586px, 265px);
    }
  }

  @mixin max-md {
    &__wrapper {
      margin: 0 auto;
      max-width: 500px;
      flex-direction: column;
    }
  }

  @mixin max-sm {
    &__scroll {
      margin-bottom: 15px;
    }

    &__nav {
      justify-content: center;
    }

    &__btn {
      width: calc(100% - 20px);
      margin-left: 0;
    }
  }
}
