.stories {
  margin-top: ac(96px, var(--mmt));

  &__wrapper {
    gap: ac(20px, 14px) ac(64px, 32px);
  }

  &__title {
    margin-top: ac(20px, 8px);
  }

  &__col {
    margin-left: auto;
    align-items: center;

    .hero__link {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: ac(24px, 10px);

      padding: 0 19px 0 25px;
      border: 1px solid var(--forest);
      color: var(--forest);
      background-color: var(--white);

      font-weight: 700;
      font-size: 18px;
      line-height: 46px;
      white-space: nowrap;
      cursor: pointer;
      transition: 0.3s ease;

      &::before {
        display: none;
      }

      &::after {
        margin-left: 4px;
        width: 24px;
        height: 24px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23255f47' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
        transition: 0.3s ease;

        position: relative;
        background-color: unset;
        right: unset;
        transform: unset;
      }

      &:hover {
        background-color: var(--forest);
        color: var(--white);

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23FFFFFF' d='m6.86 8.423 10.279 6.659V8.658L6.859 2v6.423ZM6.86 15.63V22l10.074-6.476-4.956-3.21-5.119 3.315Z'/%3E%3C/svg%3E");
        }
      }
    }
  }

  &__descr {
    margin-bottom: ac(40px, 20px);
    max-width: 800px;

    font-size: ac(22px, 18px);
    font-weight: 400;
    line-height: 1.2;

    @mixin media 769 {
      width: 100%;
    }
  }

  .slider-btn.prev {
    margin-left: 0;
  }

  &__btn {
    background-color: var(--white);
    margin-right: 24px;
  }

  &__slider-box {
    width: calc(100% + ac(26px, 0px));
    padding-left: ac(32px, 0px);
  }

  &__slide {
    &:hover {
      .opportunities__link {
        max-width: 100%;
        padding-left: 12px;

        &::before {
          max-width: 100%;
        }

        &::after {
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='14' fill='none'%3E%3Cpath fill='%23255F47' d='m2.317 14 7-7-7-7L.684 1.633 6.05 7 .684 12.367 2.317 14Z'/%3E%3C/svg%3E")
            center / contain no-repeat;
        }
      }
    }
    &--yellow {
      .stories__img {
        border-color: var(--Lime-I);
      }
      .stories__shadow {
        background: var(--Lime-III);
      }
      .stories__position {
        color: var(--forest);
      }

      &:hover {
        .stories__shadow {
          background: var(--Lime-I);
        }
      }
    }
    &--red {
      .stories__img {
        border-color: var(--Salmon-I);
      }
      .stories__shadow {
        background: var(--Salmon-III);
      }
      .stories__position {
        color: var(--maroon);
      }
      &:hover {
        .stories__shadow {
          background: var(--Salmon-I);
        }
      }
    }
    &--blue {
      .stories__img {
        border-color: var(--Blue-I);
      }
      .stories__shadow {
        background: var(--Blue-III);
      }
      .stories__position {
        color: var(--navy);
      }

      &:hover {
        .stories__shadow {
          background: var(--Blue-I);
        }
      }
    }
  }

  &__card {
    height: 100%;
    padding: ac(39px, 15px) ac(39px, 15px) ac(42px, 25px);
    border: 1px solid var(--Gray-III);
    background-color: var(--white);
    cursor: pointer;
  }

  &__shadow {
    top: ac(-32px, -10px);
    left: ac(-32px, -10px);
    transition: 0.3s ease;
    z-index: -1;
    pointer-events: none;
  }

  &__head {
    width: 100%;
    gap: ac(32px, 10px);
    margin-bottom: ac(32px, 15px);
  }

  &__img {
    width: 130px;
    height: 130px;
    border-radius: 100%;
    background: var(--white);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    aspect-ratio: 1;
  }

  &__name-box {
    gap: ac(9px, 5px);
  }

  &__name {
    font-weight: 700;
    font-size: ac(36px, 24px);
    line-height: ac(48px, 32px);
    letter-spacing: -0.09px;
  }

  &__position {
    color: var(--forest);
    font-size: ac(26px, 20px);
    line-height: ac(36px, 26px);
    font-weight: 700;
    letter-spacing: -1px;
  }

  &__row {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
    gap: ac(16px, 10px);
  }

  &__label {
    flex-shrink: 0;
    color: var(--Gray-II);
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
  }

  &__line {
    width: 100%;
    height: 1px;
    background-color: var(--Gray-III);
  }

  &__text {
    margin-bottom: ac(20px, 15px);
    max-height: calc(ac(28px, 24px) * 8);
    @mixin max-line-length 8;

    &--landing {
      margin-bottom: ac(26px, 10px);
      color: var(--Gray-I);
      font-size: ac(22px, 18px);
      line-height: ac(32px, 24px);
      font-weight: 700;
      max-height: calc(ac(32px, 24px) * 4);
      @mixin max-line-length 4;
    }
  }

  .opportunities__link {
    max-width: 138px;
  }

  @mixin md {
    &__title-box {
      flex-shrink: 0;
      width: 100%;
      max-width: ac(702px, 350px);
    }

    &__col {
      margin-top: auto;
    }
  }

  @mixin lg {
    &__title-box {
      max-width: ac(702px, 500px);
    }
  }

  @mixin max-lg {
    &__head {
      width: 100%;
      flex-direction: column;
    }

    &__name,
    &__position {
      text-align: center;
    }
  }

  @mixin max-md {
    &__slider-box {
      max-width: 500px;
      margin: 0 auto;
    }

    &__col {
      margin-right: auto;
      order: 1;
    }
  }

  @mixin max-sm {
    &__col {
      margin-top: 10px;
      margin-right: 0;
      margin-left: 0;
      width: 100%;

      .slider-btn.prev {
        margin-left: auto;
      }

      .slider-btn.next {
        margin-right: 0;
      }
    }

    &__btn {
      margin-right: 16px;
    }
  }

  @mixin max-xs {
    .slider-btn.prev {
      margin-left: auto;
    }

    &__col {
      margin-left: ac(60px, 40px, 320, 374);
      margin-right: ac(60px, 40px, 320, 374);
      justify-content: center;

      .hero__link {
        margin-bottom: 20px;
        margin-right: 0;
      }
    }
  }
}
