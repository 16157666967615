.brands {
  margin-top: ac(160px, var(--mmt));
  background-color: rgba(255, 255, 255, 0.4);

  &--sectors {
    margin-top: ac(115px, 48px);

    .brands__subtitle {
      margin-top: ac(24px, 12px);
      font-size: ac(28px, 18px);
      line-height: ac(36px, 26px);
    }
  }

  &__title-box {
    gap: ac(51px, 20px);
  }

  &__title {
    font-size: ac(56px, 28px);
    line-height: ac(68px, 34px);
    letter-spacing: -0.28px;
  }

  &__subtitle {
    margin-top: ac(24px, 12px);
    color: var(--Gray-I);
    font-size: ac(22px, 18px);
    line-height: ac(32px, 26px);
    font-weight: 700;

    & > * {
      &:not(:last-child) {
        margin-bottom: ac(18px, 10px);
      }
    }
  }

  &__logo-box {
    gap: 16px;
  }

  &__logo {
    max-width: ac(248px, 170px);

    /* &:first-child {
      margin: 0 30px;
    } */

    img {
      object-fit: contain;
    }
  }

  &__wrapper {
    margin-top: ac(47px, 40px);
    gap: ac(20px, 15px);
  }

  &__col {
    z-index: 1;
    overflow: hidden;
    cursor: pointer;

    border: 1px solid var(--Gray-III);

    box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.08),
      0px 0px 4px 0px rgba(0, 0, 0, 0.08);

    &.is-active {
      cursor: default;

      &,
      &:nth-child(2),
      &:nth-child(3) {
        background-color: var(--white);
      }
    }

    &:nth-child(2),
    &:nth-child(3) {
      .brands__inner-title {
        color: #002744;

        &::before {
          background-color: #002744;
        }
      }

      .brands__row {
        span {
          &:first-child {
            color: #002744;
          }
        }
      }
    }
  }

  &__head {
    display: flex;
    flex-direction: column;
    gap: ac(22px, 12px);
  }

  &__name {
    font-size: ac(22px, 14px, 320);
    line-height: ac(36px, 20px, 320);
    font-weight: 700;
    letter-spacing: -0.07px;
    color: var(--white);
  }

  &__line {
    background-color: var(--Lime-I);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ac(50px, 40px);
    height: ac(50px, 40px);
    flex-shrink: 0;
    border: 1px solid rgba(255, 255, 255, 0.4);
    transition: 0.3s ease;

    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: var(--Lime-I);
      transition: 0.3s ease;
    }

    &::before {
      width: ac(20px, 18px);
      height: 3px;
    }

    &::after {
      width: 3px;
      height: ac(20px, 18px);
    }
  }

  &__inner-title {
    padding-bottom: 23px;
    color: var(--forest);
    font-size: ac(36px, 24px);
    line-height: ac(48px, 32px);
    font-weight: 700;
    letter-spacing: -0.09px;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 64px;
      height: 4px;
      background-color: var(--forest);
    }
  }

  &__scroll {
    margin-top: ac(26px, 15px);
    margin-bottom: ac(20px, 10px);
    max-height: calc(ac(32px, 24px) * 5);
    width: calc(100% + 10px);
    padding-right: 10px;

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__descr {
    margin-top: 40px;

    p {
      color: var(--Gray-I);
      font-size: ac(22px, 16px);
      line-height: ac(32px, 24px);
      font-weight: 300;

      &:not(:first-child) {
        margin-top: ac(10px, 15px);
      }
    }
  }

  &__info-box {
    width: 100%;
  }

  &__row {
    padding: ac(24px, 12px) 0;
    border-bottom: 1px solid var(--Gray-III);

    &:last-child {
      border: 0;
      padding-bottom: 0;
    }

    span {
      color: var(--Gray-I);
      font-size: ac(22px, 16px);
      line-height: ac(32px, 24px);
      font-weight: 700;

      &:first-child {
        color: var(--forest);
        font-size: ac(18px, 16px);
        line-height: ac(25px, 20px);
        font-weight: 700;
      }

      @media (max-width: 640px) {
        font-weight: 300 !important;
      }
    }
  }

  @mixin md {
    &__text {
      max-width: ac(661px, 300px);
    }
  }

  @mixin lg {
    &__col {
      flex: 0;

      min-width: ac(126px, 100px, 1024);
      position: relative;
      padding: ac(32px, 26px) ac(39px, 20px);
      height: ac(480px, 400px);
      background-color: var(--forest);
      transition: 0.2s 0.2s linear;

      &:nth-child(2),
      &:nth-child(3) {
        background-color: #002744;
      }

      &.is-active {
        flex: 1;
        padding: ac(49px, 25px) ac(55px, 20px) ac(64px, 30px);

        .brands__content {
          visibility: visible;
          opacity: 1;
          max-height: 1000px;
          transition: all 0.2s ease, opacity 0.2s 0.4s linear;
        }

        .brands__head {
          max-height: 0;
          opacity: 0;
          visibility: 0;
          transform: translateX(-100%);
          transition: none;
        }
      }
    }

    &__head {
      height: 100%;
      transition: 0.2s ease;
    }

    &__content {
      opacity: 0;
      max-height: 0;
    }

    &__name {
      writing-mode: vertical-lr;
      white-space: nowrap;
      transform: rotate(180deg);
    }

    &__line {
      width: 1px;
      height: 100%;
    }
  }

  @mixin max-lg {
    &__wrapper {
      display: grid;
    }

    &__col {
      width: 100%;

      &:nth-child(2) {
        .brands__head {
          background-color: #002744;
        }
      }
      &:nth-child(3) {
        .brands__head {
          background-color: var(--navy);
        }
      }

      &.is-active {
        .brands__content {
          max-height: 1000px;
          padding: 10px 15px;
          opacity: 1;
          transition: all 0.2s ease, opacity 0.2s 0.3s ease;
        }

        .brands__icon {
          &::after {
            height: 0;
          }
        }
      }
    }

    &__content {
      max-height: 0;
      opacity: 0;
      transition: all 0.2s 0.2s ease, opacity 0.2s ease;
    }

    &__head {
      padding: 10px 15px;
      flex-direction: row;
      transition: 0.3s ease;
      background-color: var(--forest);
    }

    &__name {
      order: -1;
    }

    &__line {
      width: 100%;
      height: 1px;
    }

    &__icon {
      &::before {
        height: 2px;
      }
      &::after {
        width: 2px;
      }
    }

    &__inner-title {
      display: none;
    }
  }

  @mixin max-md {
    &__title-box {
      flex-direction: column;
    }

    &__logo-box {
      margin: 0 auto;
      width: 100%;
      max-width: 400px;
    }
  }

  @mixin max-sm {
    &__row {
      gap: 5px;
      flex-direction: column;

      span {
        text-align: center;
      }
    }
  }

  @mixin max-xs {
    &__icon {
      width: 30px;
      height: 30px;

      &::before {
        width: 14px;
      }
      &::after {
        height: 14px;
      }
    }

    &__btn {
      font-size: 14px;
    }
  }
}
