.specialist {
  padding: ac(136px, var(--mmt)) 0 ac(120px, var(--mmt));
  background-color: var(--Lime-III);

  &__wrapper {
    gap: ac(48px, 20px);
  }

  &__img {
    aspect-ratio: 0.85 / 1;
    z-index: 1;

    @mixin media 500 {
      &.max-w-container {
        max-width: calc(100% - 20px) !important;
        width: 100%;

        img {
          position: absolute;
          inset: 0;
        }
      }

      &.max-w-container-right {
        max-width: calc(100% - 10px) !important;
        margin-right: auto !important;
        margin-left: 0 !important;
        width: 100%;

        img {
          position: absolute;
          inset: 0;
        }
      }

      &.max-w-container-left {
        max-width: calc(100% - 10px) !important;
        margin-right: 0 !important;
        margin-left: auto !important;
        width: 100%;

        img {
          position: absolute;
          inset: 0;
        }
      }
    }
  }

  &__shadow {
    top: ac(-32px, -10px);
    left: ac(-32px, -10px);
    background-color: var(--Lime-II);
    z-index: -1;
  }

  &__col {
    padding-right: ac(40px, 0px);
    padding-top: ac(43px, 0px);
  }

  &__title {
    padding-bottom: ac(23px, 15px);
    border-bottom: 1px solid var(--Gray-III);
  }

  &__subtitle {
    margin-top: ac(24px, 15px);

    color: var(--forest);
    font-size: ac(28px, 20px);
    font-weight: 700;
    line-height: ac(36px, 26px);
    letter-spacing: -0.07px;
  }

  &__card-box {
    margin-top: ac(24px, 12px);
  }

  &__card {
    padding: ac(14px, 10px) ac(16px, 10px) ac(14px, 10px) ac(18px, 12px);
    gap: ac(32px, 15px);
    color: var(--Lime-III);
    font-size: ac(18px, 16px);
    font-weight: 700;
    line-height: ac(25px, 20px);
    border: 1px solid #537a5d;
    background: #1f503b;
  }

  &__icon {
    width: ac(40px, 30px);
    height: ac(40px, 30px);
  }

  @mixin md {
    &__img {
      max-width: ac(508px, 250px);
      margin-left: ac(32px, 15px);
    }
  }

  @mixin max-md {
    &__img {
      max-width: 350px;
      margin: 0 auto;
    }
  }
}
