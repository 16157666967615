.hero {
  &--home {
    &.hero {
      .cont {
        min-height: ac(825px, 772px);

        @mixin max-sm {
          max-height: 790px;
        }
      }
    }
    .hero {
      &__img {
        height: 980px;
      }

      &__bg {
        right: 0;
        bottom: ac(-617px, -175px, 375, 1440);
        width: 100%;
        max-width: 813px;
        height: ac(1518px, 700px, 375, 1440);

        z-index: -1;
      }

      &__corner {
        clip-path: polygon(
          100% 0,
          calc(50% - ac(500px, 450px, 1440, 2600)) 0,
          100% calc(50% + 308px)
        );
      }
    }
  }

  &--about,
  &--join {
    &.hero {
      .cont {
        padding-bottom: 13px;
      }
    }
  }

  &--about {
    .hero {
      &__title {
        span {
          line-height: ac(70px, 37px);
        }
      }
      &__img {
        margin-top: 10px;
        width: 100%;
        max-width: ac(542px, 327px, 375, 1440);
        aspect-ratio: 1.23 / 1;

        @mixin sm {
          aspect-ratio: 0.99 / 1;
        }
      }
    }
  }

  &--join {
    .hero {
      &__img {
        margin-top: 10px;
        width: 100%;
        max-width: ac(542px, 327px, 375, 1440);
        aspect-ratio: 0.99 / 1;

        @mixin max-sm {
          aspect-ratio: 1.2/1;
        }
      }
    }
  }

  &--details {
    margin-top: ac(120px, 72px, 1024);

    &.hero {
      .cont {
        padding-top: ac(122px, 50px, 320);
        padding-bottom: ac(122px, 50px, 320);
      }
    }

    .hero {
      &__wrapper {
        max-width: unset;
        align-items: center;
        width: 100%;
        gap: 24px;

        @media (max-width: 640px) {
          align-items: flex-start;
        }
      }
      &__suptitle {
        color: var(--Gray-III);
        font-size: 18px;
        font-weight: 800;
      }

      &__title {
        margin-top: ac(27px, 15px);
        color: var(--Lime-II);
      }

      &__btn-box {
        gap: ac(16px, 10px);
        margin-top: ac(40px, 20px);
      }

      &__col {
        gap: ac(13px, 10px);
        width: 100%;
        padding: ac(39px, 15px) ac(31px, 10px);
        background-color: #1a4332;
        border: 1px solid rgba(235, 238, 207, 0.3);
      }

      &__row {
        color: var(--white);
        font-size: ac(22px, 18px);
        line-height: ac(32px, 24px);
        font-weight: 700;

        &:not(:last-child) {
          padding-bottom: ac(14px, 10px);
          border-bottom: 1px solid rgba(227, 231, 186, 0.3);
        }
      }
    }
  }

  &--roles {
    margin-top: ac(120px, 72px, 1024);
    &.hero {
      .cont {
        padding-top: 108px;
        padding-bottom: ac(148px, 50px, 320);
      }
    }

    .hero {
      &__wrapper {
        max-width: unset;
        width: 100%;
      }
      &__title {
        color: var(--white);

        span {
          color: var(--Lime-II);
        }
      }

      &__filter-box {
        margin-top: ac(64px, 25px);
        gap: ac(24px, 15px) ac(20px, 10px);
        width: 100%;
        max-width: 809px;

        & > * {
          flex-grow: 1;
          flex-shrink: 0;

          &:nth-child(1) {
            flex-basis: ac(340px, 260px, 320);
          }
          &:nth-child(2) {
            flex-basis: 180px;
          }
          &:nth-child(3) {
            flex-basis: 249px;
          }
          &:nth-child(4) {
            flex-basis: ac(319px, 260px, 320);
          }
          &:nth-child(5) {
            flex-basis: ac(286px, 260px, 320);
          }
          &:nth-child(6) {
            flex-basis: 164px;
          }
        }
      }

      &__input-box {
        display: inline-grid;
        height: 48px;

        input {
          width: 100%;
          height: 100%;
        }
      }

      &__select {
        .choices {
          &::before {
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' fill='none'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M.853.228a.562.562 0 0 1 .796 0L8.001 6.58 14.353.228a.562.562 0 1 1 .796.796L8.4 7.774a.563.563 0 0 1-.796 0l-6.75-6.75a.563.563 0 0 1 0-.796Z' clip-rule='evenodd'/%3E%3C/svg%3E")
              center / contain no-repeat;
          }

          &:hover {
            .choices__inner {
              border-color: var(--Lime-II) !important;
            }

            .choices__list--single {
              .choices__item {
                color: var(--Lime-II);
              }
            }

            &::before {
              background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' fill='none'%3E%3Cpath fill='%23e3e7ba' fill-rule='evenodd' d='M.853.228a.562.562 0 0 1 .796 0L8.001 6.58 14.353.228a.562.562 0 1 1 .796.796L8.4 7.774a.563.563 0 0 1-.796 0l-6.75-6.75a.563.563 0 0 1 0-.796Z' clip-rule='evenodd'/%3E%3C/svg%3E")
                center / contain no-repeat;
            }
          }
        }
        .choices__inner {
          background-color: var(--forest);
          border-color: #537a5d !important;
        }

        .choices__list--single {
          .choices__item {
            color: var(--white);
          }
        }
      }

      &__range {
        display: flex;
        align-items: center;
        gap: 8px;

        .single-range {
          padding-right: 16px;
          width: 100%;
          height: 2px;
          border: none;
          box-shadow: none;
          background: var(--Lime-II);

          &-container {
            width: 100%;
            padding-top: 11px;
          }

          .noUi-connect {
            background: #dbe666;
          }

          .noUi-handle {
            border: 2px solid #dbe666;
            background: var(--forest);
            width: 18px !important;
            height: 18px;
            cursor: pointer;
            border-radius: 0px;
            box-shadow: none;
            top: -8px;
            right: -16px !important;
            transition: background-color 0.3s ease;

            &:before,
            &:after {
              content: none;
            }

            &:hover,
            &.noUi-active {
              background-color: #dbe666;
            }
          }
        }
      }

      &__range-info {
        width: 72px;
        flex-shrink: 0;
        color: var(--white);
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }

      &__search-btn {
        gap: 4px;
        padding: 0 10px;
        span {
          transform: translateX(4px);
          width: 20px;
          height: 20px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23C3D600' d='m15.385 14.088 3.925 3.926-1.296 1.296-3.926-3.925a8.214 8.214 0 0 1-5.148 1.804A8.253 8.253 0 0 1 .69 8.94 8.253 8.253 0 0 1 8.94.69a8.253 8.253 0 0 1 8.25 8.25 8.214 8.214 0 0 1-1.805 5.15Zm-1.84-.68a6.396 6.396 0 0 0 1.811-4.469A6.415 6.415 0 0 0 8.94 2.523a6.415 6.415 0 0 0-6.417 6.416 6.415 6.415 0 0 0 6.417 6.417c1.737 0 3.313-.69 4.468-1.81l.138-.138Z'/%3E%3C/svg%3E");
          transition: 0.3s ease;
        }

        &:hover {
          span {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23255f47' d='m15.385 14.088 3.925 3.926-1.296 1.296-3.926-3.925a8.214 8.214 0 0 1-5.148 1.804A8.253 8.253 0 0 1 .69 8.94 8.253 8.253 0 0 1 8.94.69a8.253 8.253 0 0 1 8.25 8.25 8.214 8.214 0 0 1-1.805 5.15Zm-1.84-.68a6.396 6.396 0 0 0 1.811-4.469A6.415 6.415 0 0 0 8.94 2.523a6.415 6.415 0 0 0-6.417 6.416 6.415 6.415 0 0 0 6.417 6.417c1.737 0 3.313-.69 4.468-1.81l.138-.138Z'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }

  &--news {
    margin-top: ac(120px, 72px, 1024);
    &.hero {
      .cont {
        padding-top: ac(139px, 50px);
        padding-bottom: 50px;
      }
    }
    .hero {
      &__wrapper {
        width: 100%;
      }

      &__title {
        color: var(--Lime-II);

        span {
          color: var(--white);
        }
      }

      &__subtitle {
        display: flex;
        flex-direction: column;
        gap: ac(10px, 5px);
        margin-top: ac(25px, 15px);
        color: var(--white);
        font-size: ac(24px, 18px);
        font-weight: 400;
        line-height: ac(36px, 24px);
      }

      &__search-box {
        margin-top: ac(49px, 25px);
        width: 100%;
        gap: ac(20px, 15px);

        input {
          height: 48px;
          flex: 1 0 ac(544px, 250px);
        }
      }
      &__search-btn {
        flex: 1 0 166px;
        gap: 4px;
        padding: 0 10px;
        span {
          transform: translateX(4px);
          width: 20px;
          height: 20px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23C3D600' d='m15.385 14.088 3.925 3.926-1.296 1.296-3.926-3.925a8.214 8.214 0 0 1-5.148 1.804A8.253 8.253 0 0 1 .69 8.94 8.253 8.253 0 0 1 8.94.69a8.253 8.253 0 0 1 8.25 8.25 8.214 8.214 0 0 1-1.805 5.15Zm-1.84-.68a6.396 6.396 0 0 0 1.811-4.469A6.415 6.415 0 0 0 8.94 2.523a6.415 6.415 0 0 0-6.417 6.416 6.415 6.415 0 0 0 6.417 6.417c1.737 0 3.313-.69 4.468-1.81l.138-.138Z'/%3E%3C/svg%3E");
          transition: 0.3s ease;
        }

        &:hover {
          span {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23255f47' d='m15.385 14.088 3.925 3.926-1.296 1.296-3.926-3.925a8.214 8.214 0 0 1-5.148 1.804A8.253 8.253 0 0 1 .69 8.94 8.253 8.253 0 0 1 8.94.69a8.253 8.253 0 0 1 8.25 8.25 8.214 8.214 0 0 1-1.805 5.15Zm-1.84-.68a6.396 6.396 0 0 0 1.811-4.469A6.415 6.415 0 0 0 8.94 2.523a6.415 6.415 0 0 0-6.417 6.416 6.415 6.415 0 0 0 6.417 6.417c1.737 0 3.313-.69 4.468-1.81l.138-.138Z'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }

  &--tech,
  &--client,
  &--candid {
    margin-top: ac(120px, 72px, 1024);

    &.hero {
      .cont {
        padding-top: 89px;
        padding-bottom: 50px;
      }
    }
    .hero {
      &__img {
        max-height: unset;
        right: 0;
        bottom: 0;
        left: auto;
        height: 100%;
        width: calc(50vw + 202px);

        img {
          object-position: right;
        }
      }

      &__title {
        color: var(--white);

        span {
          color: var(--Lime-II);
        }
      }

      &__subtitle {
        display: flex;
        flex-direction: column;
        gap: ac(10px, 5px);
        margin-top: ac(25px, 15px);
        color: var(--white);
        font-size: ac(24px, 18px);
        font-weight: 400;
        line-height: ac(36px, 24px);
      }

      &__link {
        color: var(--white);

        &:hover {
          color: var(--Lime-II);
        }

        &,
        &:hover {
          &::after {
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='14' fill='none'%3E%3Cpath fill='%23E3E7BA' d='m2.317 14 7-7-7-7L.684 1.633 6.05 7 .684 12.367 2.317 14Z'/%3E%3C/svg%3E")
              center / contain no-repeat;
          }
          &::before {
            background-color: var(--Lime-II);
          }
        }
      }
    }
  }

  &--client {
    .hero__subtitle {
      display: flex;
      flex-direction: column;
      gap: ac(10px, 5px);
      margin-top: ac(25px, 15px);
      color: var(--white);
      font-weight: 400;
      font-size: ac(20px, 16px);
      line-height: ac(30px, 22px);

      span {
        font-size: ac(24px, 18px);
        line-height: ac(36px, 24px);
      }
    }
  }

  &--client,
  &--candid {
    &.hero {
      .cont {
        padding-bottom: ac(166px, 50px);
      }
    }
  }

  &--tech {
    &.hero {
      .cont {
        padding-bottom: 100px;
      }
    }

    .hero__img {
      width: calc(50vw + ac(-84px, 250px));
    }
  }

  &--team {
    &.hero {
      .cont {
        padding-top: 50px;
        padding-bottom: 50px;
        /* height: calc(100vh - ac(120px, 72px));
        max-height: 492px; */
      }
    }
    .hero {
      &__title {
        color: var(--Lime-II);
        font-weight: 700;
        letter-spacing: -0.448px;
      }

      &__subtitle {
        /* margin-top: ac(25px, 15px); */
        color: var(--forest);
        font-size: ac(24px, 18px);
        font-weight: 400;
        line-height: ac(36px, 24px);
      }

      &__link {
        color: var(--forest);

        &:hover {
          color: var(--forest);
        }

        &,
        &:hover {
          &::after {
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='14' fill='none'%3E%3Cpath fill='%23255f47' d='m2.317 14 7-7-7-7L.684 1.633 6.05 7 .684 12.367 2.317 14Z'/%3E%3C/svg%3E")
              center / contain no-repeat;
          }
          &::before {
            background-color: var(--forest);
          }
        }
      }
    }
  }

  &--diver,
  &--sol,
  &--sector {
    margin-top: ac(120px, 72px, 1024);
    position: relative;

    &.hero {
      .cont {
        padding-top: 50px;
        padding-bottom: 50px;
        height: calc(100vh - ac(120px, 72px));

        @mixin max-sm {
          height: auto;
        }
      }
    }

    .hero {
      &__img {
        bottom: unset;
        left: unset;
        margin-top: ac(-32px, 0px);
        margin-right: ac(32px, 0px);
        width: 100%;
        max-width: ac(640px, 327px, 375, 1440);
        aspect-ratio: 3 / 2;
      }

      &__shadow {
        bottom: ac(-32px, -15px);
        right: ac(-32px, -15px);
        background-color: var(--Lime-II);
      }

      &__title {
        color: var(--white);

        span {
          color: var(--Lime-II);
        }
      }

      &__subtitle {
        margin-top: ac(26px, 10px);
        color: var(--white);
        font-size: ac(22px, 16px, 320);
        font-weight: 300;
        line-height: ac(32px, 20px, 320);
      }
    }
  }

  &--banking {
    margin-top: ac(120px, 72px, 1024);
    position: relative;

    &.hero {
      .cont {
        padding-top: 93px;
        padding-bottom: 50px;

        @mixin sm {
          min-height: calc(100vh - ac(120px, 72px));
        }
      }
    }

    .hero {
      &__title {
        color: var(--white);

        span {
          color: var(--Lime-II);
        }
      }

      &__subtitle {
        margin-top: ac(26px, 10px);
        color: var(--white);
        font-size: ac(22px, 16px, 320);
        font-weight: 300;
        line-height: ac(32px, 20px, 320);

        & > * {
          &:not(:first-child) {
            margin-top: ac(18px, 10px);
          }
        }
      }
    }
  }

  &--sol,
  &--sector {
    &.hero {
      .cont {
        padding-top: ac(41px, 50px);
        min-height: 770px;
      }
    }
    .hero {
      &__img {
        margin-top: ac(54px, 0px);

        max-width: ac(476px, 327px, 375, 1440);
        aspect-ratio: 1 / 1;
      }

      &__shadow {
        top: ac(-32px, -15px);
        bottom: unset;
        background-color: var(--Lime-II);
      }

      &__subtitle {
        margin-top: ac(26px, 10px);
        font-size: ac(24px, 18px);
        font-weight: 400;
        line-height: ac(36px, 24px);
      }

      &__link {
        color: var(--white);

        &:hover {
          color: var(--Lime-II);
        }

        &,
        &:hover {
          &::after {
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='14' fill='none'%3E%3Cpath fill='%23E3E7BA' d='m2.317 14 7-7-7-7L.684 1.633 6.05 7 .684 12.367 2.317 14Z'/%3E%3C/svg%3E")
              center / contain no-repeat;
          }
          &::before {
            background-color: var(--Lime-II);
          }
        }
      }
    }
  }

  &--article {
    overflow: hidden;
    background-color: var(--Lime-III);
    margin-top: ac(120px, 72px, 1024);

    &.hero {
      .cont {
        padding-top: ac(130px, 50px);
        padding-bottom: ac(130px, 50px);
        min-height: ac(600px, 500px);
      }
    }

    .hero {
      &__bg {
        width: 100%;
        max-width: ac(1012px, 400px, 320);
        height: 100%;
        z-index: 1;

        @mixin media 490 {
          height: 240px;
        }
      }

      &__title-box {
        z-index: 2;
      }

      &__btn-box {
        @media (max-width: 551px) {
          .btn {
            width: 100% !important;
          }
        }
      }

      &__tag {
        padding: 3px 15px;
        border: 1px solid var(--Gray-III);
        background: var(--white);
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);

        color: var(--Gray-I);
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
      }

      &__title {
        margin-top: 28px;
        color: var(--forest);
      }

      &__btn-box {
        gap: 17px;
        margin-top: ac(40px, 20px);
      }
    }
  }

  &--stories {
    &.hero {
      .cont {
        padding-top: ac(41px, 50px);
        min-height: 770px;
      }
    }

    .hero {
      &__img {
        bottom: unset;
        left: unset;
        margin-top: ac(-32px, 0px);
        margin-right: ac(32px, 0px);
        width: 100%;
        max-width: ac(640px, 327px, 375, 1440);
        aspect-ratio: 3 / 2;
      }

      &__shadow {
        bottom: ac(-32px, -15px);
        right: ac(-32px, -15px);
        background-color: var(--Lime-II);
      }
    }

    .hero {
      &__img {
        margin-top: ac(54px, 0px);

        max-width: ac(476px, 327px, 375, 1440);
        aspect-ratio: 1 / 1;

        @media (max-width: 640px) {
          margin-left: 0 !important;
          max-width: calc(100% - 15px) !important;
          width: 100% !important;
        }
      }

      &__shadow {
        top: ac(-32px, -15px);
        bottom: unset;
        background-color: var(--forest);
      }

      &__subtitle {
        margin-top: ac(26px, 10px);
        font-size: ac(24px, 18px);
        font-weight: 400;
        line-height: ac(36px, 24px);
      }
    }
  }

  &--change {
    overflow: clip;
    margin-top: ac(120px, 72px, 1024);

    &.hero {
      .cont {
        padding-top: ac(150px, 50px);
        padding-bottom: ac(150px, 50px);
        max-height: 780px;
        min-height: 500px;
      }
    }

    .hero {
      &__bg {
        right: 0;
        height: 100%;
      }

      &__img {
        max-height: 781px;
        height: 100%;
        width: 60.2%;
        left: 0;
        top: 0;
        bottom: auto;
      }

      &__wrapper {
        position: relative;
        margin-left: auto;
        padding: ac(80px, 25px) ac(72px, 0px) ac(90px, 60px) ac(72px, 25px);

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;

          width: calc(100% + 100vw);
          height: 100%;
          background: var(--Lime-III);
          box-shadow: 0px 0px 52px 0px rgba(0, 0, 0, 0.25);
          z-index: -1;
        }

        @media (max-width: 640px) {
          padding-left: 0 !important;

          &:before {
            left: -24px;
          }
        }
      }

      &__decor {
        width: ac(673px, 300px);
        height: ac(463px, 194px);
        bottom: ac(-277px, -123px);
        right: ac(-297px, -100px);
      }

      &__subtitle {
        margin-top: ac(25px, 15px);
        font-size: ac(24px, 18px);
        font-weight: 400;
        line-height: ac(36px, 24px);
      }

      &__btn-box {
        margin-top: ac(48px, 20px);
      }
    }
  }

  &--dynamic {
    margin-top: ac(120px, 72px, 1024);

    &.hero {
      .cont {
        padding-top: ac(142px, 50px);
        padding-bottom: ac(142px, 50px);
        max-height: 700px;
        min-height: 500px;
      }
    }

    .hero {
      &__wrapper {
        gap: ac(80px, 25px);
        align-items: center;
      }

      &__title {
        font-weight: 700;
        color: var(--white);

        span {
          color: var(--Lime-II);
        }
      }

      &__subtitle {
        margin-top: 25px;
        color: var(--white);
        font-size: ac(24px, 18px);
        font-weight: 400;
        line-height: ac(36px, 24px);
      }

      &__logo {
        margin-top: ac(-60px, 0px);
        width: ac(476px, 280px, 320);
        height: ac(476px, 280px, 320);
      }
    }
  }

  &--location {
    margin-top: ac(120px, 72px, 1024);

    &.hero {
      .cont {
        padding: 0;
        min-height: 500px;
      }
    }

    .hero {
      &__bg {
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }

      &__nav {
        bottom: 20px;
        left: ac(250px, 175px, 769);
        user-select: none;
      }

      &__slider {
        .swiper-slide {
          .hero__card,
          .hero__col,
          .hero__map {
            transition: 0.3s linear;
          }
          .hero__card {
            opacity: 0;
          }

          .hero__col {
            transform: translateX(-60%);
          }
          .hero__map {
            transform: translateX(60%);
          }

          &-active {
            .hero__card,
            .hero__col,
            .hero__map {
              transition: 0.5s linear;
            }
            .hero__col,
            .hero__map {
              transform: translateX(0%);
            }
            .hero__card {
              opacity: 1;
            }
          }
        }
      }

      &__map {
        &::before {
          content: '';
          position: absolute;
          inset: 0;
          pointer-events: none;
          z-index: 1;

          background: #fff1f2;
          mix-blend-mode: color;

          transition: opacity ease 250ms;
        }

        &:hover {
          iframe {
            filter: grayscale(0);
          }

          &::before {
            opacity: 0;
          }
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          filter: grayscale(1);
          transition: filter 0.3s ease;
        }
      }

      &__col {
        justify-content: center;

        padding: ac(108px, 50px) ac(96px, 15px) ac(96px, 50px) ac(24px, 15px);
      }

      &__subtitle {
        margin-top: ac(24px, 12px);
        color: var(--white);
        font-size: ac(24px, 18px);
        line-height: ac(36px, 26px);
        font-weight: 400;

        p {
          &:not(:last-child) {
            margin-bottom: ac(18px, 12px);
          }
        }
      }

      &__contact-box {
        margin-top: ac(47px, 20px);
        padding-top: ac(40px, 20px);
        border-top: 1px solid #537a5d;
        display: flex;
        flex-wrap: wrap;
        gap: ac(33px, 15px) ac(25px, 10px);
      }

      &__contact {
        display: inline-flex;
        align-items: center;
        gap: ac(16px, 5px);
        color: var(--white);
        font-size: ac(22px, 16px);
        line-height: ac(32px, 20px);
        font-weight: 700;
        transition: 0.3s ease;

        &::before {
          content: '';
          width: 40px;
          height: 40px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;

          flex-shrink: 0;
        }

        &:hover {
          color: #e3e7ba;
        }

        &--address {
          &::before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='none'%3E%3Cpath fill='%23E3E7BA' d='M20 20c.81 0 1.505-.294 2.081-.881A2.915 2.915 0 0 0 22.947 17c0-.825-.288-1.531-.866-2.119-.576-.587-1.27-.881-2.08-.881s-1.504.294-2.081.881A2.915 2.915 0 0 0 17.054 17c0 .825.289 1.531.866 2.119.577.587 1.27.881 2.08.881Zm0 15c-3.953-3.425-6.905-6.606-8.857-9.544C9.19 22.52 8.215 19.8 8.215 17.3c0-3.75 1.185-6.738 3.554-8.963C14.139 6.114 16.882 5 20.001 5c3.118 0 5.862 1.112 8.231 3.338 2.37 2.225 3.554 5.212 3.554 8.962 0 2.5-.976 5.219-2.928 8.156-1.952 2.938-4.904 6.119-8.857 9.544Z'/%3E%3C/svg%3E");
          }
        }

        &--tel {
          &::before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='none'%3E%3Cpath fill='%23E3E7BA' d='M30.534 32c-2.756 0-5.484-.655-8.184-1.966a26.584 26.584 0 0 1-7.233-5.15 26.584 26.584 0 0 1-5.15-7.234C8.654 14.95 8 12.222 8 9.466c0-.421.139-.771.417-1.05C8.695 8.14 9.045 8 9.467 8h4.9c.31 0 .589.106.833.317.244.211.389.483.434.817l.9 4.366c.044.31.038.594-.017.85a1.28 1.28 0 0 1-.35.65l-3.3 3.334a25.392 25.392 0 0 0 3.95 5.1 23.252 23.252 0 0 0 5.05 3.8l3.167-3.2c.2-.245.45-.418.75-.517.3-.1.605-.117.916-.05l4.166.9c.334.066.606.227.817.483.211.256.317.55.317.884v4.8c0 .422-.139.771-.417 1.05-.278.277-.627.416-1.05.416Z'/%3E%3C/svg%3E");
          }
        }

        &--mail {
          &::before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='none'%3E%3Cpath fill='%23E3E7BA' d='M6.75 12.592A3.333 3.333 0 0 1 10 10h20a3.333 3.333 0 0 1 3.25 2.592L20 20.69 6.75 12.592Zm-.084 1.903v11.84l9.672-5.93-9.672-5.91Zm11.268 6.888-10.95 6.712A3.333 3.333 0 0 0 10 30h20a3.334 3.334 0 0 0 3.014-1.907l-10.95-6.711-2.064 1.261-2.065-1.26Zm5.727-.976 9.672 5.928v-11.84l-9.672 5.91v.002Z'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }

  .cont {
    padding-top: ac(123px, 140px);
    padding-bottom: 143px;
  }

  &__corner {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: var(--forest);
    clip-path: polygon(
      100% 0,
      calc(50% - ac(500px, 450px, 1440, 2600)) 0,
      100% 100%
    );
    z-index: -1;
  }

  &__img {
    width: ac(813px, 375px, 375, 1440);

    max-height: 110vh;
    bottom: ac(-80px, -2px);
    left: 50%;
    z-index: -1;

    &.max-w-container {
      max-width: calc(100% - 17px) !important;
      margin-left: 0 !important;
    }
  }

  &__btn-box {
    margin-top: ac(50px, 25px);
    gap: 32px ac(32px, 20px);
  }

  &__link {
    display: flex;
    align-items: center;
    font-weight: 700;
    padding-right: 40px;
    transition: 0.3s ease;

    &::after,
    &::before {
      content: '';
      position: absolute;
      transition: 0.3s ease;
    }

    &::after {
      right: 9px;
      width: 10px;
      height: 14px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='14' fill='none'%3E%3Cpath fill='%23000' d='m2.317 14 7-7-7-7L.684 1.633 6.05 7 .684 12.367 2.317 14Z'/%3E%3C/svg%3E")
        center / contain no-repeat;
      transform: translateY(ac(0px, -4px));
    }

    &::before {
      right: 0;
      bottom: ac(-12px, -3px);
      width: 100%;
      max-width: 32px;
      height: 1px;
      background-color: var(--forest);
      transform: translateY(ac(0px, 5px));
    }

    &:hover {
      color: var(--maroon);

      &::after {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='14' fill='none'%3E%3Cpath fill='%23780F30' d='m2.317 14 7-7-7-7L.684 1.633 6.05 7 .684 12.367 2.317 14Z'/%3E%3C/svg%3E")
          center / contain no-repeat;
      }

      &::before {
        max-width: 100%;
        background-color: var(--maroon);
      }
    }
  }

  @mixin sm {
    &--home {
      .hero__img {
        transform: translateX(calc(-50% + ac(313px, 150px, 640, 1440)));
      }
    }
  }

  @mixin md {
    &--banking {
      .hero__wrapper {
        max-width: ac(644px, 380px);
      }

      .hero__img {
        transform: translateX(calc(-50% + ac(409px, 350px)));
        width: 624px;
        max-height: unset;
        height: 100%;
        bottom: 0;
      }
    }

    &--location {
      .hero__map {
        top: 0;
        right: ac(-85px, 0px, 1270);
        width: ac(696px, 318px);
        z-index: 1;
      }

      .hero__col {
        max-width: ac(660px, 450px);
      }
    }
    &--news {
      .hero__wrapper {
        max-width: ac(730px, 500px);
      }

      &.hero {
        .cont {
          padding-bottom: ac(384px, 200px);
        }
      }
    }
    &--article {
      .hero__wrapper {
        max-width: ac(600px, 400px);
      }

      .hero__bg {
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + ac(458px, 235px)), -50%);
        clip-path: polygon(40% 0, 100% 0%, 100% 100%, 0% 100%);
      }
    }
    &--dynamic {
      .hero__wrapper {
        max-width: unset;
      }
    }

    &--dynamic,
    &--details {
      .hero__title-box {
        max-width: ac(600px, 480px);
        flex-shrink: 0;
      }
    }

    &--about,
    &--join {
      .cont {
        min-height: 100vh;
        min-height: 100svh;
      }
    }

    &--tech,
    &--client,
    &--candid {
      .cont {
        //height: calc(100vh - ac(120px, 72px));
        //height: calc(100svh - ac(120px, 72px));
      }

      .hero__bg {
        clip-path: polygon(
          0 0,
          calc(50vw + ac(302px, 100px)) 0,
          calc(50vw - ac(202px, 100px)) 100%,
          0% 100%
        );
      }
      .hero__wrapper {
        max-width: ac(560px, 327px);
      }

      .hero__decor {
        width: 754px;
        height: 846px;
        bottom: 0px;
        left: 50%;
        transform: translate(calc(-50% - 216px), calc(100% - 243px));
        clip-path: polygon(
          calc(71% - ac(8px, -12px)) 0%,
          calc(100% - ac(0px, 100px)) 0%,
          calc(27% + ac(0px, 258px)) 100%,
          calc(0% + ac(0px, 358px)) 100%
        );
        background-color: var(--Blue-III);
        z-index: 1;
      }
      .hero__decor--sec {
        height: 244px;
        transform: translate(calc(-50% + ac(314px, 330px)), calc(100% - 244px));

        clip-path: polygon(
          0 0,
          calc(30% - ac(3px, 120px)) 0,
          calc(50% - ac(0px, 150px)) 100%,
          calc(21% - ac(0px, 50px)) 100%
        );

        background-color: var(--forest);
      }
    }

    &--tech {
      overflow: clip;

      .cont {
        min-height: 648px;
      }
      .hero__bg {
        clip-path: polygon(
          0 0,
          calc(50vw + ac(370px, 70px)) 0,
          calc(50vw - ac(45px, 90px)) 100%,
          0% 100%
        );
      }

      .hero__wrapper {
        max-width: ac(620px, 327px);
      }

      .hero__decor--sec {
        transform: translate(calc(-50% + ac(466px, 333px)), calc(100% - 207px));
      }

      .hero__decor {
        transform: translate(calc(-50% - ac(63px, 213px)), calc(100% - 207px));
        clip-path: polygon(
          calc(71% - ac(8px, -12px)) 0%,
          calc(100% - ac(0px, 100px)) 0%,
          calc(27% + ac(0px, 258px)) 100%,
          calc(0% + ac(0px, 340px)) 100%
        );
        background-color: var(--white);
      }
      .hero__decor--sec {
        transform: translate(calc(-50% + ac(466px, 333px)), calc(100% - 207px));
        height: 207px;
        clip-path: polygon(
          0 0,
          calc(30% - ac(0px, 120px)) 0,
          calc(50% - ac(0px, 150px)) 100%,
          calc(21% - ac(0px, 50px)) 100%
        );
        background-color: var(--forest);
      }
    }

    &--client {
      &.hero {
        .cont {
          min-height: 780px;
        }
      }

      .hero__decor {
        transform: translate(calc(-50% - ac(198px, 210px)), calc(100% - 243px));
        clip-path: polygon(
          calc(71% - ac(8px, -12px)) 0%,
          calc(100% - ac(0px, 100px)) 0%,
          calc(27% + ac(0px, 258px)) 100%,
          calc(0% + ac(0px, 340px)) 100%
        );
      }
      .hero__decor--sec {
        transform: translate(calc(-50% + ac(328px, 338px)), calc(100% - 244px));

        clip-path: polygon(
          0 0,
          calc(30% - ac(3px, 120px)) 0,
          calc(50% - ac(0px, 150px)) 100%,
          calc(21% - ac(0px, 50px)) 100%
        );
      }
    }

    &--candid {
      &.hero {
        .cont {
          min-height: 860px;
          padding-bottom: ac(170px, 80px);
        }
      }
      .hero__decor {
        background-color: var(--Lime-II);
      }

      .hero__decor--sec {
        background-color: var(--forest);
      }

      .hero__img {
        img {
          object-position: ac(-45px, -170px);
        }
      }
    }
    &--team {
      .hero__wrapper {
        max-width: ac(643px, 327px);
      }
    }

    &--change {
      .hero__wrapper {
        max-width: ac(809px, 527px);
      }

      & + .work {
        margin-top: ac(96px, 48px);
      }
    }

    &--sol,
    &--sector {
      .hero__wrapper {
        max-width: ac(600px, 327px);
      }

      .hero__img {
        margin-left: 35px !important;
      }
    }
    &--diver {
      .hero__wrapper {
        max-width: ac(496px, 327px);
      }
    }
    &__wrapper {
      max-width: ac(658px, 327px);
    }
  }

  @mixin xxl {
    &--article {
      &.hero .cont {
        min-height: ac(1000px, 800px, 1920, 2560);
      }

      .hero__bg {
        max-width: ac(1700px, 1200px, 1920, 2560);
      }
    }
    &--banking {
      .hero__img {
        transform: translateX(calc(-50% + ac(800px, 450px, 1441, 2560)));
        width: 50vw;
      }
    }
    &--location {
      .hero__col {
        position: static;
      }
      .hero__map {
        left: 50%;
        transform: translate(calc(-50% + 370px));
      }
    }

    &--candid {
      .hero__img {
        img {
          object-position: 0 top;
        }
      }

      .hero__decor {
        clip-path: polygon(calc(73% - 22px) 0%, 97% 0%, 27% 100%, 0% 100%);
      }
      .hero__decor--sec {
        transform: translate(calc(-50% + 310px), calc(100% - 244px));

        clip-path: polygon(
          0 0,
          calc(27% - ac(0px, 96px)) 0,
          calc(48% - ac(0px, 125px)) 100%,
          calc(21% - ac(0px, 50px)) 100%
        );
      }
    }
    &--home {
      .hero__img {
        bottom: -100px;
      }
    }
  }

  @media (max-width: 1199px) {
    &,
    &--hero {
      .hero__corner {
        clip-path: polygon(
          100% 0,
          calc(50% + ac(300px, -70px, 375, 1199)) 0,
          100% calc(0% + ac(230px, 165px, 375, 1199))
        );
      }
    }
  }

  @mixin max-md {
    &--sol,
    &--sector {
      .hero__wrapper {
        align-items: flex-start;
        margin-bottom: 25px;
      }

      .hero__title,
      .hero__subtitle {
        text-align: left;
      }

      .hero__btn-box {
        justify-content: flex-start;
      }
    }

    &--article {
      .hero__bg {
        position: static;
      }

      .hero__wrapper {
        align-items: center;
        margin: 0 auto;
        gap: 25px;
      }

      .hero__title {
        text-align: left;
        width: 100%;
      }

      .hero__tag-box,
      .hero__btn-box {
        justify-content: center;
      }
    }

    &--dynamic {
      .hero__wrapper {
        flex-direction: column-reverse;
        align-items: flex-start;
      }

      .hero__logo {
        margin-bottom: -30px;
        margin-left: -39px;
      }

      .hero__title-box {
        max-width: 500px;
        margin: 0 auto;

        & > * {
          text-align: left;
        }
      }

      .hero__title {
        text-align: left;
      }

      .hero__subtitle {
        text-align: left;
      }
    }

    &--banking {
      &.hero {
        .cont {
          padding-top: 0;
          justify-content: flex-start;
        }
      }
      .hero__wrapper {
        align-items: flex-start;
        gap: 15px;
      }

      .hero__title,
      .hero__subtitle {
        text-align: left;
      }

      .hero__img {
        margin-top: auto;
        margin-bottom: 20px;
        margin-left: -24px;
        width: calc(100% + 48px);
        height: 100%;
        max-height: ac(400px, 250px, 320, 768);
        overflow: hidden;

        /*img {
          object-position: 0px ac(-300px, -120px, 320, 768);
        }*/

        img {
          max-height: ac(400px, 250px, 320, 768);
          object-position: 50% 40%;
        }
      }
    }

    &--tech,
    &--client,
    &--candid {
      display: flex;
      flex-direction: column-reverse;

      &.hero {
        .cont {
          padding-top: 65px;
          padding-bottom: 49px;
        }
      }
      .hero__bg {
        height: calc(100% - ac(350px, 250px, 375, 768));
      }

      .hero__decor {
        width: 377px;
        height: 423px;
        bottom: 122px;
        left: 50%;
        transform: translate(calc(-50% - 175px), 100%);
        clip-path: polygon(72% 0%, 99% 0%, 25% 100%, 0% 100%);

        background-color: var(--Lime-II);
        z-index: 1;
      }
      .hero__decor--sec {
        width: 192px;
        height: 122px;
        transform: translate(calc(-50% + 4px), 100%);

        clip-path: polygon(0 0, 54% 0, 95% 100%, 41% 100%);

        background-color: var(--forest);
      }

      .hero__subtitle {
        font-size: 16px;
      }
      .hero__btn-box {
        gap: 32px 16px;
      }
      .hero__img {
        position: relative;
        width: 100%;
        height: ac(400px, 250px, 375, 768);
        z-index: 1;

        img {
          object-position: top;
        }
      }
    }

    &--tech {
      .hero__decor {
        background-color: var(--white);
      }
      .hero__decor--sec {
        background-color: var(--forest);
      }
    }
    &--client {
      .hero__decor {
        background-color: var(--Blue-III);
      }
      .hero__decor--sec {
        background-color: var(--forest);
      }
    }

    &--location {
      .hero__nav {
        left: auto;
        top: 20px;
        right: 20px;
      }
    }
  }

  @mixin max-sm {
    &--home {
      .hero__img {
        width: 376px;
        height: 500px;
        transform: translateX(calc(-50% - 25px));
      }
    }

    &--details {
      .btn {
        width: 100%;
      }
    }
  }

  @mixin max-xs {
    &,
    &--home {
      .hero__corner {
        clip-path: polygon(100% 0, calc(50% - 60px) 0, 100% calc(0% + 160px));
      }
    }
  }
}
