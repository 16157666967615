.roles {
  margin-top: ac(48px, var(--mmt));

  &__title {
    margin-top: ac(19px, 9px);
  }

  &__wrapper {
    margin-top: ac(48px, 24px);
    padding-top: ac(48px, 24px);
    gap: ac(64px, 25px);
    border-top: 1px solid var(--Gray-III);
  }

  &__col {
    gap: ac(40px, 20px);
  }

  &__icon {
    width: ac(72px, 58px);
    height: ac(72px, 58px);
    margin-right: 8px;
  }

  &__name {
    flex-shrink: 0;
    margin-right: ac(16px, 10px);
    color: var(--forest);
    font-size: ac(36px, 24px);
    line-height: ac(48px, 32px);
    font-weight: 700;
    letter-spacing: -0.09px;
  }

  &__sep {
    margin-top: 4px;
    width: 100%;
    height: 1px;
    background-color: var(--Gray-III);
  }

  &__scroll {
    margin-top: ac(16px, 10px);
    width: 100%;
    max-height: calc(ac(32px, 24px) * 6);

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__descr {
    padding-right: 10px;
    font-size: ac(22px, 18px);
    line-height: ac(32px, 24px);
    font-weight: 700;
    color: var(--forest);
  }

  &__row {
    &--blue {
      .roles__name,
      .roles__descr {
        color: var(--navy);
      }
    }
    &--red {
      .roles__name,
      .roles__descr {
        color: var(--maroon);
      }
    }
  }

  @mixin md {
    &__col {
      max-width: ac(654px, 350px);
    }

    &__img--mob {
      display: none;
    }
  }

  @mixin max-md {
    &__img {
      max-width: 500px;
      height: ac(400px, 350px, 320, 768);
      margin: 0 auto 40px;
    }

    &__img--desk {
      display: none;
    }
  }
}
