.who-we {
  .cont {
    max-width: 1328px;
  }

  &__wrapper {
    box-shadow: 0px 0px 64px 0px rgba(0, 0, 0, 0.12),
      0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 2px 0px rgba(0, 0, 0, 0.24);
  }

  &__img {
    width: 100%;
    z-index: -1;

    img {
      object-position: top;
    }

    @mixin max-sm {
      display: none;
    }
  }

  &__text-box {
    background-color: var(--white);
    padding: ac(78px, 26px) ac(64px, 24px) ac(78px, 32px) ac(80px, 24px);
  }

  &__suptitle {
    margin-top: ac(32px, 0px);
  }

  &__title {
    margin-top: ac(24px, 8px);
  }

  &__subtitle {
    margin-top: 16px;
    max-height: calc(ac(32px, 26px) * 6);
    @mixin max-line-length 6;
  }

  &__btn {
    margin-top: ac(40px, 18px);
  }

  @mixin md {
    &__img {
      flex-shrink: 0;
      max-width: ac(664px, 340px);
    }
  }

  @mixin max-md {
    &__wrapper {
      flex-direction: column-reverse;
    }

    &__img {
      height: ac(320px, 262px, 375, 768);
    }
  }

  @mixin max-sm {
    margin-top: -453px;
  }
}
