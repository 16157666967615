.solve {
  margin-top: ac(96px, var(--mmt));

  &__title {
    padding-bottom: ac(39px, 20px);
    border-bottom: 1px solid var(--Gray-III);
  }

  &__card-box {
    margin-top: ac(71px, 20px);
    gap: ac(24px, 20px);
  }

  &__card {
    width: 100%;
    overflow: hidden;

    &--red {
      .solve {
        &__num {
          color: var(--maroon);

          &::before {
            background-color: var(--Salmon-I);
          }
        }

        &__name {
          color: var(--maroon);
        }
      }
    }

    &--green {
      .solve {
        &__num {
          color: var(--forest);

          &::before {
            background-color: var(--Lime-I);
          }
        }

        &__name {
          color: var(--forest);
        }
      }
    }
  }

  &__num {
    position: relative;
    color: var(--navy);
    font-size: ac(56px, 32px);
    font-weight: 300;
    line-height: ac(68px, 38px);
    letter-spacing: -0.28px;
    padding-bottom: ac(10px, 5px);

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 64px;
      height: 2px;
      background-color: var(--Blue-I);
    }
  }

  &__name {
    margin-top: ac(31px, 15px);
    color: var(--navy);
    text-align: center;
    font-size: ac(28px, 22px);
    font-weight: 700;
    line-height: ac(36px, 28px);
    letter-spacing: -0.07px;
  }

  &__scroll {
    margin-top: ac(16px, 10px);
    width: 100%;
    max-height: calc(28px * 6);

    .simplebar-scrollbar::before {
      background-color: var(--black);
    }

    .simplebar-track {
      right: -10px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__descr {
    padding: 0 ac(24px, 12px);
    color: var(--Gray-I);
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }
}
