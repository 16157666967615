.pagination {
    margin: ac(64px, 30px) auto 0;

    &__btn,
    &__num {
        width: ac(48px, 38px);
        height: ac(48px, 38px);
        border: 1px solid var(--white);
        user-select: none;

        transition: 0.3s ease;
        cursor: pointer;

        &.is-disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &__num {
        color: var(--Gray-I);
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;

        &.is-active,
        &--dots {
            pointer-events: none;
        }

        &--dots {
            border: 0;
        }

        &.is-active,
        &:hover {
            background-color: #fafbf4;
            color: var(--forest);
            border-color: var(--Lime-I);
        }
    }

    &__btn {
        &--prev {
            margin-right: ac(8px, 5px);

            .pagination__icon {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='16' fill='none'%3E%3Cpath fill='%23C3D600' d='M7.617 15.614V.383L.002 7.998l7.615 7.616Z'/%3E%3C/svg%3E");
            }
        }

        &--next {
            margin-left: ac(8px, 5px);

            .pagination__icon {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='16' fill='none'%3E%3Cpath fill='%23C3D600' d='M0 15.614V.383l7.615 7.615L0 15.614Z'/%3E%3C/svg%3E");
            }
        }

        &:hover {
            border-color: var(--Lime-I);
            background-color: #fafbf4;

            .pagination__btn--prev {
                .pagination__icon {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='16' fill='none'%3E%3Cpath fill='%23255f47' d='M7.617 15.614V.383L.002 7.998l7.615 7.616Z'/%3E%3C/svg%3E");
                }
            }

            .pagination__btn--next {
                .pagination__icon {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='16' fill='none'%3E%3Cpath fill='%23255f47' d='M0 15.614V.383l7.615 7.615L0 15.614Z'/%3E%3C/svg%3E");
                }
            }
        }
    }

    &__icon {
        width: 8px;
        height: 16px;
        background-position: center;
        background-repeat: repeat;
        background-size: contain;
        transition: 0.3s ease;
    }
}
