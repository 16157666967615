.testimonials {
  margin-top: ac(100px, var(--mmt));

  & + .news {
    margin-top: ac(96px, var(--mmt));
  }

  &--client {
    margin-top: ac(96px, var(--mmt));
  }

  &--sol {
    margin-top: ac(142px, var(--mmt));
  }

  &--banking {
    margin-top: ac(162px, var(--mmt));
  }

  &__title {
    margin-top: ac(20px, 5px);
  }

  &__slider-box {
    margin: ac(64px, 32px) auto 0;
    max-width: ac(728px, 510px, 375, 1440);
  }

  &__slide {
    &:nth-child(3n + 1) {
      .testimonials__card {
        background: var(--Blue-III);

        .testimonials__sep {
          background-color: var(--Blue-I);
        }

        .simplebar-scrollbar::before {
          background-color: var(--navy);
        }

        .simplebar-track {
          background-color: rgba(20, 52, 102, 0.2);
        }
      }
    }
    &:nth-child(3n + 2) {
      .testimonials__card {
        background: var(--Lime-III);

        .testimonials__sep {
          background-color: var(--forest);
        }

        .simplebar-scrollbar::before {
          background-color: var(--forest);
        }

        .simplebar-track {
          background-color: rgba(37, 95, 71, 0.2);
        }
      }
    }
    &:nth-child(3n + 3) {
      .testimonials__card {
        background: var(--Salmon-III);

        .testimonials__sep {
          background-color: var(--maroon);
        }

        .simplebar-scrollbar::before {
          background-color: var(--maroon);
        }

        .simplebar-track {
          background-color: rgba(120, 15, 48, 0.2);
        }
      }
    }
  }

  &__card {
    padding: ac(64px, 20px) ac(56px, 20px) ac(64px, 24px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__sep {
    width: 56px;
    height: 3px;
    margin: 0 auto ac(32px, 16px);
    opacity: 0.5;
  }

  &__simplebar {
    width: calc(100% + 10px);
    max-height: calc(ac(32px, 24px) * 10);
    margin-bottom: ac(32px, 16px);
  }

  &__descr {
    padding-right: 10px;
    font-size: ac(22px, 16px);
    line-height: ac(32px, 24px);
  }

  &__name {
    margin-top: auto;
    margin-bottom: 8px;

    font-size: ac(28px, 22px);
    line-height: ac(36px, 28px);
    font-weight: 700;
    letter-spacing: -0.07px;
  }

  &__pos {
    font-size: ac(22px, 16px);
    font-weight: 700;
    line-height: 32px;
    color: var(--Gray-I);
  }

  &__subtitle {
    max-height: calc(ac(32px, 26px) * 6);
    @mixin max-line-length 6;
  }

  .slider-btn {
    margin-top: ac(49px, 32px);
    width: 48px;
    height: 48px;
  }

  @mixin md {
    &__descr {
      font-weight: 300;
    }

    &__title-box {
      margin-right: ac(264px, 20px);
      flex-shrink: 0;
      width: 100%;
      max-width: ac(400px, 300px);
    }
  }

  @mixin max-md {
    margin-top: 60px;

    &__name {
      letter-spacing: -0.055px;
    }

    &__subtitle {
      margin-top: 16px;
    }
  }
}
